import React, {ChangeEvent, FormEvent} from 'react';
import {Form} from 'react-bootstrap'
import {RouteComponentProps, withRouter} from 'react-router-dom';

interface ISearchFormHPProps extends RouteComponentProps {
    searchQuery: string,

    setSearchQuery(q: string): void
}

class SearchFormHP extends React.PureComponent<ISearchFormHPProps> {
    constructor(props: any) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public render() {

        return (
            <Form className="form-search" onSubmit={this.handleSubmit}>
                <Form.Control className="border-0 w-100" type="search" placeholder="Enter job title or skill"
                              value={this.props.searchQuery} onChange={this.handleChange}/>
            </Form>
        )
    }

    protected handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        let history = this.props.history;
        if (window.location.pathname !== '/vacancies') {
            history.push(`/vacancies?query=${encodeURIComponent(this.props.searchQuery)}`);
        }
    }

    protected handleChange(e: ChangeEvent<HTMLInputElement>) {
        this.props.setSearchQuery(e.target.value);
    }
}

export default withRouter(SearchFormHP);
