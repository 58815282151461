import './header.scss';
import React from 'react';
import {Link} from 'react-router-dom';
import {Col, Image, Nav, Navbar, NavDropdown, Row, Dropdown} from 'react-bootstrap';
import {IDepartment} from '../../../shared/types';
import links from '../../mocks/links';
import Menu from '../../../shared/components.shared/components/menu/menu';
import SearchFormHP from "../search-form-hp/search-form-hp";
import {ThemeContext} from "../../../shared/components.shared/components/providers/theme.context";
import SearchForm from "../../../cz/components/search-form/search-form";

interface IDepartmensGroup {
    header: string;
    list: IDepartment[]
}

interface IHeaderProps {
    transparentMenu: boolean
}

interface IMenuToggleState {
    hideMenu: boolean;
    changeBackground: boolean;
    background: string;
    hideSubMenu: boolean;
}

class Header extends React.Component<IHeaderProps, IMenuToggleState> {
    constructor(props: any) {
        super(props);
        this.state = {
            hideSubMenu: false,
            hideMenu: false,
            changeBackground: false,
            background: '',
        };
        this.handleClick = this.handleClick.bind(this);
        this.hideCollapsedMenu = this.hideCollapsedMenu.bind(this);
        this.hideCollapsedSubMenu = this.hideCollapsedSubMenu.bind(this);
        this.changeToDarkOnScroll = this.changeToDarkOnScroll.bind(this);
    }

    handleClick() {
        this.setState(state => ({
            changeBackground: !state.changeBackground,
        }))
    }

    hideCollapsedMenu() {
        this.setState(state => ({
            hideMenu: !state.hideMenu
        }))
    }

    hideCollapsedSubMenu() {
        this.setState(state => ({
            hideSubMenu: !state.hideSubMenu
        }))
    }

    changeToDarkOnScroll(transparentMenu = this.props.transparentMenu): void {
        if (window.scrollY > 0) {
            this.setState({background: (transparentMenu && '#004550') || '#fff'});
        } else {
            this.setState({background: 'transparent'})
        }
    }

    componentWillReceiveProps(nextProps: Readonly<IHeaderProps>, nextContext: any): void {
        if (this.props.transparentMenu !== nextProps.transparentMenu) {
            this.changeToDarkOnScroll(nextProps.transparentMenu);
        }
    }

    componentDidMount(): void {
        window.addEventListener(`scroll`, () => this.changeToDarkOnScroll());
    }

    render() {
        let logoPath = `//img.veeam.com/careers/logo/veeam/logo.svg`;
        let menuTransparencyClass = (this.props.transparentMenu && 'bg-transparent') || '';
        let buttonBackgroundChange = (this.props.transparentMenu && 'btn btn-apply btn-success') || 'btn btn-apply btn-success';
        return (
            <header className={menuTransparencyClass}>
                <div className="header header-desktop d-none d-lg-block border-0"
                    style={{backgroundColor: this.state.background}}>
                    <div className="container-fluid">
                        <Row>
                            <Col>
                                <Navbar className= "justify-content-between">
                                    <Navbar className="Collapse">
                                        <Nav>
                                            <Link to="/"><Image className="img-logo" src={logoPath} alt={'Veeeam logo'}/></Link>
                                        </Nav>
                                    </Navbar>
                                    <Nav>
                                        {/*
                                         // @ts-ignore */}
                                        <NavDropdown className="dropdown-search" title="" id="basic-nav-dropdown" expand={!this.state.hideSubMenu}>
                                            <div className="container px-0 px-md-4">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <ThemeContext.Consumer>
                                                            {
                                                                (theme) =>
                                                                    <SearchFormHP searchQuery={theme.searchQuery}
                                                                                  setSearchQuery={theme.setSearchQuery}/>
                                                            }
                                                        </ThemeContext.Consumer>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavDropdown>
                                        <Nav.Link as={Link} to={"/about"}><span>About us</span></Nav.Link>
                                        <Nav.Link as={Link} to={"/life"}><span>Life at veeam</span></Nav.Link>
                                        <Nav.Link as={Link} to={"/diversity-and-inclusion"}><span>Diversity & Inclusion</span></Nav.Link>
                                        <Nav.Link as={Link} to={"/opportunities"}><span>Areas of interest</span></Nav.Link>
                                        <Nav.Link as={Link} to={"/students-and-graduates"}><span>Students & Graduates</span></Nav.Link>
                                        <Nav.Link as={Link} to={"/vacancies"}><button
                                            className={buttonBackgroundChange}>View jobs</button></Nav.Link>
                                    </Nav>
                                </Navbar>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="header header-mobile d-block d-lg-none">
                    <div className="container-fluid">
                        <Row>
                            <Col>
                                <Navbar className="d-flex" collapseOnSelect expand="lg" expanded={this.state.hideMenu}>
                                    <Link to="/"><Image className="mr-3 img-logo"
                                                        src={logoPath} alt={'Veeeam logo'}/></Link>
                                    <Nav className="flex-row align-items-center justify-content-between">
                                        {/*
                                         // @ts-ignore */}
                                        <NavDropdown className="dropdown-search" title="" id="basic-nav-dropdown" expand={!this.state.hideSubMenu}>
                                            <div className="container px-0 px-md-4">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <ThemeContext.Consumer>
                                                            {
                                                                (theme) =>
                                                                    <SearchFormHP searchQuery={theme.searchQuery}
                                                                                  setSearchQuery={theme.setSearchQuery}/>
                                                            }
                                                        </ThemeContext.Consumer>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavDropdown>
                                        <Navbar.Toggle aria-controls="responsive-navbar-nav"
                                                       className="navbar-nav pr-0" onClick={this.hideCollapsedMenu}/>
                                        <Navbar.Collapse id="responsive-navbar-nav" className="navbar-collapse-menu">
                                            <Nav>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Nav.Link as={Link} to={"/about"} onClick={this.hideCollapsedMenu}><span>About us</span></Nav.Link>
                                                            <Nav.Link as={Link} to={"/life"} onClick={this.hideCollapsedMenu}><span>Life at veeam</span></Nav.Link>
                                                            <Nav.Link as={Link} to={"/diversity-and-inclusion"} onClick={this.hideCollapsedMenu}><span>Diversity & Inclusion</span></Nav.Link>
                                                            <Nav.Link as={Link} to={"/opportunities"} onClick={this.hideCollapsedMenu}><span>Areas of interest</span></Nav.Link>
                                                            <Nav.Link as={Link} to={"/students-and-graduates"} onClick={this.hideCollapsedMenu}><span>Students & Graduates</span></Nav.Link>
                                                            <Nav.Link as={Link} to={"/vacancies"} onClick={this.hideCollapsedMenu}><button
                                                                className={buttonBackgroundChange}>View jobs</button></Nav.Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav>
                                        </Navbar.Collapse>
                                    </Nav>
                                </Navbar>
                            </Col>
                        </Row>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
