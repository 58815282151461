import React from 'react';



class ShuffleVideo extends React.Component {

    componentWillMount() {
        const script1 = document.createElement("script");
        const script2 = document.createElement("script");

        script1.src = "//fast.wistia.com/embed/medias/rrew8llcui.jsonp";
        script1.async = true;

        script2.src = "//fast.wistia.com/assets/external/E-v1.js";
        script2.async = true;

        document.body.appendChild(script1);
        document.body.appendChild(script2);
    }

    render() {


        return (
            <div className="card card-banner card-homepage card-homepage--video bg-dark wistia_embed wistia_async_rrew8llcui popover=true  popoverContent=link">
                    <div className="card-header">
                        <div className=""
                             style={{display:'inline', position: 'relative'}}>
                            <span className="d-inline-flex">
                                <span className="sprites bg-play_success"></span>
                                <span className="text-secondary btn btn-link">Watch the video</span>
                            </span>
                        </div>
                    </div>
                    <div className="card-body">
                        <span>
                            <h3 className="display-3 mt-0">Conversations <br/><span className="text-secondary">from the heart</span></h3>
                            <p className="mb-0 mt-4 w-65">Diversity brings different views and thinkers to the table.
                                See how Belinda Jurisic, Vice President of Channel Management and Strategy, has experienced this at Veeam.</p>
                        </span>
                    </div>
                </div>

        )
    }
}

export default ShuffleVideo;
