const blocks = [
    {
        img: '//img.veeam.com/careers/global/employees/grows/veronica_vasilescu.png',
        name: 'Veronica Vasilescu',
        title: 'Director, Renewals Specialist',
        quote: 'Coming here was a fresh start with a great vibe',
        link: '/grows/veronica',
        banner: false
    },
    {
        img: '//img.veeam.com/careers/global/employees/grows/rick_johnson.png',
        name: 'Rick Johnson',
        title: 'Senior Director, Cloud & Service Providers ',
        quote: 'You have to see your career as navigating traffic - you can join the fast lane or stay where you’re happy with the speed',
        link: '/grows/rick',
        banner: false
    },
    {
        img: '//img.veeam.com/careers/global/apply/hand_3.png',
        imgBottom: '//img.veeam.com/careers/global/apply/hand_1.png',
        name: '',
        title: 'Join Veeam Talent Community',
        quote: '',
        link: '//flows.beamery.eu/veeamsoftware/talentcommunity2',
        banner: true
    },
    {
        img: '',
        imgBottom: '//img.veeam.com/careers/global/apply/russia.png',
        name: '',
        title: '<span class="text-white">Find out more about our</span> <span class="text-secondary">Russian locations</span>',
        quote: '',
        link: '//careers.veeam.ru/',
        banner: true,
        localBanner: true
    },
    {
        img: '',
        imgBottom: '//img.veeam.com/careers/global/apply/czech.png',
        name: '',
        title: '<span class="text-white">Find out more about our</span><br/> <span class="text-secondary">Czech Republic</span> <span class="text-white">Technology Hub</span>',
        quote: '',
        link: '//cz.careers.veeam.com/',
        banner: true,
        localBanner: true
    },
    {
        img: '',
        imgBottom: '//img.veeam.com/careers/global/apply/hand_1.png',
        name: '',
        title: '<span>Technical<br> Customer<br> Support team</span>',
        quote: '',
        link: '//tech.veeam.com/?utm_source=global+careers&utm_medium=banner&utm_campaign=tcs',
        banner: true,
        externalBanner: true
    },
    {
        img: '',
        imgBottom: '//img.veeam.com/careers/global/apply/hand_1.png',
        name: '',
        title: '<strong>Learn more<br/> about Customer<br/> Success team</strong>',
        quote: '',
        link: '//pages.beamery.eu/veeamsoftware/page/about-customer-success',
        banner: true,
        opportunities: true
    },
];


export default blocks;
