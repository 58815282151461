const slider =  [
    {
        tag: '<span class="icon-search_green"></span><span class="text-secondary">View Jobs</span>',
        title: '<h3 class="display-1 w-75"><span class="text-warning">Click here</span> <br/><span class="text-white">to view the <br/>jobs available <br/>at Veeam</span></h3>',
        subtitle: '',
        link: '/vacancies',
        type: 'vacancies',
    },
    {
        tag: '<span class="sprites bg-whitepaper_white"></span><span class="text-white">Veeam Grows</span>',
        title: '<h4 class="text-white mt-2 card-homepage-title">Last year,</h4><h3 class="display-1 text-white">we\'ve helped over 2,000 people</h3> <h4 class="card-homepage-title text-white">to accelerate their growth journey</h4>',
        subtitle: '',
        link: '/life/#grows',
        type: 'grows',
    },
    {
        tag: '<span class="sprites bg-whitepaper_white"></span><span class="text-white">life at Veeam</span>',
        title: '<h3 class="display-1 mt-5 text-dark">Humble, Hungry & Smart: <span class="text-white pl-1">Data protection’s most avid professionals</span></h3>',
        subtitle: '',
        link: '/life/#ourVeemers',
        type: 'cares',
        widget: 'slider'
    },
    {
        tag: '<span class="sprites bg-whitepaper_success"></span><span class="text-success">About us</span>',
        title: '<h3 class="display-1 text-dark my-0">Officially the highest rated products. <span class="text-success">Fact.</span></h3>',
        subtitle: '',
        link: '/about/#why-our-product',
        type: 'values',
        widget: 'slider'
    }
];

export default slider;
