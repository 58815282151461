import React from 'react';
import {Nav, Tab} from 'react-bootstrap';

interface IAwardsEmployeeProps {
    awards: any;
}

class AwardsEmployee extends React.Component<IAwardsEmployeeProps> {

    public render () {
        let awards = this.props.awards;
        return (
            <div>
                <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <Nav className="flex-column">
                                {awards&&awards.map((award:any, index:number) =>
                                    <Nav.Item className="text-left border-bottom py-4">
                                        <Nav.Link eventKey={index}
                                                  className="btn btn-link btn-link--fake mb-0">{award.award}</Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav>
                        </div>
                        <div className="d-none col-lg-4 d-lg-flex flex-column justify-content-center">
                            <Tab.Content>
                                {awards&&awards.map((award:any, index:number) =>
                                    <Tab.Pane eventKey={index}>
                                        <img className="img-fluid" src={award.img} alt="" width={200}/>
                                    </Tab.Pane>
                                )}
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        )
    }
}

export default AwardsEmployee;
