import React from 'react';
import {HashLink} from 'react-router-hash-link';

interface IShuffleAboutUsProps {
    details: {
        tag: string,
        title: any,
        subtitle?: any,
        link: string,
        type: string
    }
}

class ShuffleAboutUs extends React.Component<IShuffleAboutUsProps> {

    render() {
        let details = this.props.details;
        let bgColor = details.type === 'award_01' ? 'bg-dark card-homepage--award-01' :
        details.type === 'award_02' ? 'bg-dark card-homepage--award-02' :
        details.type === 'growth' ? 'bg-info card-homepage--growth' :
        details.type === 'competing' ? 'bg-info card-homepage--competing' :
        details.type === 'success' ? 'bg-info card-homepage--success' :
        details.type === 'champions' ? 'bg-info card-homepage--champions' :
        details.type === 'community' ? 'bg-dark card-homepage--community' :
        details.type === 'social' ? 'bg-dark card-homepage--social' :
        details.type === 'external' ? 'bg-green card-homepage card-homepage--external' : '';


        return (
            <HashLink target={details.type === 'social' || details.type === 'community' || details.type === 'external' ? '_blank' : ''}
                      smooth to={details.link}
                      className={`card card-no-hover card-banner card-homepage ${bgColor}`}>
                <div className="card-header" dangerouslySetInnerHTML={{__html: details.tag}}/>
                <div className="card-body">
                    <span dangerouslySetInnerHTML={{__html: details.title}}/>
                    <span dangerouslySetInnerHTML={{__html: details.subtitle}}/>
                    {
                        details.type === 'external' ?
                        <button type="button" className="btn btn-secondary mt-5">Learn more</button> : ''
                    }
                </div>
            </HashLink>
        )
    }
}

export default ShuffleAboutUs;
