import React from 'react';
import {Link} from 'react-router-dom';
import {Container, Navbar} from 'react-bootstrap';
import {IUserLocation} from "../../types";
import {ReactComponent} from "*.svg";

interface IFooterLocationProps {
    userLocation: IUserLocation
}


class Footer extends React.Component<IFooterLocationProps> {
    render() {
        let userLocation = this.props.userLocation;
        let userLocationRus = userLocation.countryCode === "RUS";
        // @ts-ignore
        let rus = userLocation.original&&userLocation.original.hasOwnProperty('countryCode')&&userLocation.original.countryCode === 'RUS';

        return (
            <footer className="footer">
                <Container className="container container-footer d-flex flex-column flex-md-row col-md-row">
                    { userLocationRus || rus ?
                        <Navbar className="justify-content-start">
                            <Link className=" sprites bg-linkedin" target="_blank"
                            to="//www.linkedin.com/company/veeam-software?ad=footer"></Link>
                            <Link className=" sprites bg-youtube" target="_blank"
                            to="//www.youtube.com/user/YouVeeam?ad=footer"></Link>
                            <Link className="sprites bg-twitter" target="_blank" to="//twitter.com/veeam?ad=footer"></Link>
                        </Navbar>
                        :
                        <Navbar className="justify-content-start">
                            <Link className="sprites bg-facebook" target="_blank"
                                  to="//www.facebook.com/veeamcareers?ad=footer"></Link>
                            <Link className=" sprites bg-instagram" target="_blank"
                                  to="//www.instagram.com/veeamcareers/?ad=footer"></Link>
                            <Link className=" sprites bg-linkedin" target="_blank"
                            to="//www.linkedin.com/company/veeam-software?ad=footer"></Link>
                            <Link className=" sprites bg-youtube" target="_blank"
                            to="//www.youtube.com/user/YouVeeam?ad=footer"></Link>
                            <Link className="sprites bg-twitter" target="_blank" to="//twitter.com/veeam?ad=footer"></Link>
                        </Navbar>
                    }
                    <span className="text-center">
                    <span className="footer__link">©{(new Date()).getFullYear()} Veeam® Software</span>
                    <a className="footer__link text-font" href="//www.veeam.com/eula.html">EULA</a>
                    <a className="footer__link text-font" href="//www.veeam.com/privacy-policy.html">Privacy Policy & Cookies </a>
                   </span>
                </Container>
            </footer>
        );
    }

}

export default Footer;
