const ahitMob = [
    {
        img: '//img.veeam.com/careers/global/page_di/ahit/edna.png',
        name: 'Edna Johnson',
        title: 'Senior Manager, Inside Sales',
        quote: 'African Heritage in IT (AHIT) contributes to a diverse, inclusive workplace and is aligned with Veeam’s mission, values, and D&I strategy. As Co-Lead, I am proud and excited about how the group embodies Veeam’s wider mission, “we truly live and breathe our values here at Veeam.” ' +
            'It\’s this commitment to our values that drives me forward',
        link: '',
        type: 'ahit'
    },
    {
        img: '//img.veeam.com/careers/global/page_di/ahit/rick.png',
        name: 'Rick Johnson',
        title: 'Sr. Director Service Providers',
        quote: 'My personal motivation as a leader within AHIT is be involved, interact and contribute beyond my day to day activities. ' +
            'African Heritage in IT has provided that opportunity, along with the opportunity to work closer with amazing people throughout the organization. ' +
            'ERG\’s are a key part of Veeam’s organizational transformation, ' +
            'thus it\’s a position of honor to move the company\’s goals forward, by supporting our employees through AHIT.',
        link: '',
        type: 'ahit'
    }
];

export default ahitMob;
