import React from 'react';
import {Nav, Row, Tab} from "react-bootstrap";
import CarouselComponent from "../../components/carousel/carousel";
import wig from "../../mocks/mocks-wig";
import ahit from "../../mocks/mocks-ahit";
import vi from "../../mocks/mocks-vi";
import employeeAwards from "../../mocks/mocks-employee-awards";
import AwardsEmployee from "../../components/awards-employee/awards-employee";
import wigMob from "../../mocks/mocks-wig-mob";
import ahitMob from "../../mocks/mocks-ahit-mob";
import viMob from "../../mocks/mocks-vi-mob";
import diVeeamNav from "../../mocks/mock-diveeam-nav";
import {Link} from "react-router-dom";
import './diveeam.scss'
import {TEXT_BLOCKS} from "./DiversityInclusion.const";
interface IShowContentState {
    isVisible: boolean;
    isActiveStories: number;
    isActiveExperience: number;
}

class DiVeeam extends React.Component<{}, IShowContentState> {
    constructor(props: any) {
        super(props);

        this.state = {
            isVisible: false,
            isActiveStories: 1,
            isActiveExperience: 1
        };
    }

    componentWillMount() {
        if (window.location.hash === '#cares') {

            this.setState({
                isActiveStories: 2
            })
        } else {
            this.setState({
                isActiveStories: 1
            })
        }

        const script1 = document.createElement("script");
        const script2 = document.createElement("script");

        script1.src = "//fast.wistia.com/embed/medias/rfn07uz92w.jsonp";
        script1.async = true;

        script2.src = "//fast.wistia.com/assets/external/E-v1.js";
        script2.async = true;

        document.body.appendChild(script1);
        document.body.appendChild(script2);
    }

    public render() {
        return (
            <React.Fragment>
                <div className="bg-partial bg-partial--green bg-partial--pattern">
                    <div className="container banner-spacer">
                        <div className="row">
                            <div
                                className="d-none d-md-flex flex-column justify-content-center col-md-3 col-lg-5 col-12">
                            </div>
                            <div
                                className="offset-md-1 offset-lg-0 col-md-8 col-lg-5 col-12 d-flex flex-column justify-content-between">
                                <h1 className="display-1 mt-0">Distinctly, Inclusively Veeam</h1>
                                <p>Every Veeamer is amazing. Each of us brings a set of unique talents, backgrounds,
                                    and experiences to the company. And we’re all willing to embrace one other’s
                                    differences, too. It’s what makes us so Distinctly Veeam.</p>
                                <p>We live and work by our strong values, and we're happy to admit that we're our own
                                    biggest supporters.</p>
                                <p>And we aren't afraid to listen to other points of view. We want to combine our
                                    strengths and build a workplace where everyone feels safe and appreciated.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container container-spacer">
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <h1 className="display-1">Together we are Distinctly Veeam </h1>
                            <div className="d-flex d-xl-none wistia_embed wistia_async_rfn07uz92w videoFoam=true"/>
                            <div className="d-none d-xl-block wistia_embed wistia_async_rfn07uz92w"
                                 style={{height: "500px", width: "880px"}}/>
                        </div>
                    </div>
                </div>
                <div className="bg-light">
                    <div className="container container-spacer">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <img src="//img.veeam.com/careers/global/page_di/banner_one_veeam.png" alt=""
                                     className="img-fluid"/>
                            </div>
                            <div className="col-12 col-md-8">
                                <h2 className="display-2 mt-0">One Veeam Team</h2>
                                <p>We’re committed to channelling the power of our differences to build a united
                                    corporate culture.
                                    We call it our One Veeam Team mindset – and it’s unlike any cultural mindset we’ve
                                    encountered within any other company.
                                    It sparks Veeamazing moments every day, creates Veeamazing productivity levels, and
                                    leads to Veeamazing strides forward in people’s careers.</p>
                                <p>Because when our different backgrounds, experiences, strengths, ideas, and opinions
                                    come together, we are stronger.
                                    The things that make us unique as individuals make us richer as a team – they make
                                    us distinctly Veeam.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    <div className="container container-spacer">
                        <div className="row">
                            <div className="col-12 col-md-5">
                                <h2 className="display-2 mt-0">Women at Veeam</h2>
                                <h6 className="display-4">Lead & Inspire</h6>
                                <p className="slogan"> The Women in Green Employee Resource Group is committed to closing the gender gap of women in tech by connecting women, celebrating their achievements, and providing career growth opportunities.</p>
                                <div className="d-none d-md-block">
                                    <Link to="/women-at-veeam"
                                          className="btn btn-primary">Learn more</Link>
                                </div>
                            </div>
                            <div className="col-12 col-md-7">
                                <img src="//img.veeam.com/careers/global/page_di/small-new-women-green.png" alt="women-in-green-new"
                                     className="img-fluid"/>
                            </div>
                        </div>
                        <div className="row d-block d-md-none justify-content-center">
                            <div className="col-md-4 mx-auto">
                                <Link to="/women-at-veeam"
                                      className="btn btn-primary mr-lg-5 mt-4" >Learn more</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-dark">
                    <img src="//img.veeam.com/careers/global/page_di/pattern_empl_bnr_left_transparent.svg" alt=""
                         className="img-fuild bg-pattern_empl_bnr_left"/>
                    <div className="container container-spacer">
                        <div className="row">
                            <div className="offset-lg-2 col-md-8 col-9">
                                <h3 className="display-1">Employee Resource Groups (ERGs)</h3>
                                <p>ERGs are voluntary, employee-led groups that focus on a shared dimension of diversity
                                    or an interest in fostering diversity and inclusion. ERGs contribute to the success
                                    of our One Veeam Team mindset by helping us to strengthen communities, further our
                                    sense of belonging, and realize our diversity and inclusion (DE&I) goals.</p>
                                <p className="mb-0">We currently have three ERGs at Veeam, which are open to all
                                    employees. They’re a Veeamazing way for everyone, at every level of the company, to
                                    expand their network, participate in programs, and get involved in DE&I.</p>
                            </div>
                        </div>
                    </div>
                    <img src="//img.veeam.com/careers/global/page_di/pattern_empl_bnr_right_transparent.svg" alt=""
                         className="img-fuild bg-pattern_empl_bnr_right"/>
                </div>
                <div className="container container-spacer pb-0">
                    <div className="row">
                        <div className="col-12 block-spacer">
                            <Tab.Container id="tabs-employees" defaultActiveKey={this.state.isActiveStories}>
                                <Row>
                                    <div className="col-12 col-md-4">
                                        <Nav className="card card-scroll border-0 mb-0 flex-row flex-md-column flex-nowrap nav">
                                            {diVeeamNav&&diVeeamNav.map((item) =>
                                            <Nav.Item className="text-right nav-item--z-index mb-4">
                                                    <Nav.Link className="justify-content-end" eventKey={item.index}>
                                                        {item.nav.split(' ').map((navItem, index:number) =>
                                                            <span className="pl-2" key={`life-nav-item-${index}`}>{navItem}</span>
                                                        )}
                                                    </Nav.Link>
                                            </Nav.Item>
                                            )}
                                        </Nav>
                                    </div>
                                    <div className="col-12 col-md-8">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="1">
                                                <p>Women in Green (WIG) is an organization within Veeam Software that’s led by both women and men who are devoted to the investment of time,
                                                    resources and networking opportunities for our employees.</p>

                                                <p><strong>Our mission</strong></p>
                                                <p>Our mission is to connect women of all stages in their career and assist in their development by fostering an environment that
                                                    facilitates career growth and recognizes professional achievements. We will continually lead with Veeam’s Core Values to advance,
                                                    cultivate and preserve our culture of inclusion and belonging.</p>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="2">
                                                <p>AHIT is a North American ERG that focuses on people of African heritage and people of color. We bring together a diverse group of allies of all ethnic backgrounds.</p>
                                                <p><strong>Our mission</strong></p>
                                                <p>Our mission is to support employees of African heritage, to provide a platform for development,
                                                    to increase cultural awareness within Veeam, and to empower the communities in which we live and work.</p>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="3">
                                                <p><strong>Our mission</strong></p>
                                                <p>Veeam Inclusion exists to encourage Veeamers to ‘be yourself and be proud of who you are’.
                                                    We are here to promote a supportive, respectful, and inclusive culture that allows every Veeamer to thrive, succeed and fuel business results.</p>
                                                <p></p>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </Row>
                                <Row className="block-spacer">
                                    <div className="col-12">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="1">
                                                <CarouselComponent carouselItemDesk={wig}
                                                                   carouselItemMob={wigMob}/>
                                                <div className="row justify-content-center">
                                                    <div className="col-10 block-spacer">
                                                        <div className="card border-0 flex-column card-banner__learn">
                                                            <div className="card-body">
                                                            </div>
                                                            <div className="card-footer d-lg-none justify-content-end align-self-end p-0 p-md-5">
                                                                <a href="//go.veeam.com/women-in-green.html" type="button"
                                                                        className="btn btn-placeholder btn-sm" target="_blank">Learn more</a>
                                                            </div>
                                                            <div className="card-footer d-none d-lg-flex justify-content-end">
                                                                <a href="//go.veeam.com/women-in-green.html" type="button"
                                                                   className="btn btn-placeholder mt-5" target="_blank">Learn more</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="2">
                                                <CarouselComponent carouselItemDesk={ahit}
                                                                   carouselItemMob={ahitMob}/>
                                                <div className="row justify-content-center">
                                                    <div className="col-10 block-spacer">
                                                        <img className="img-fluid" src="//img.veeam.com/careers/global/page_di/banners/banner_ahit.png" alt=""/>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="3">
                                                <CarouselComponent carouselItemDesk={vi}
                                                                   carouselItemMob={viMob}/>
                                                <div className="row justify-content-center">
                                                    <div className="col-10 block-spacer">
                                                        <img className="img-fluid" src="//img.veeam.com/careers/global/page_di/banners/banner_vi.png" alt=""/>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </Row>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
                <div className="bg-partial bg-partial--light">
                    <div className="container container-spacer">
                        <div className="row block-spacer mt-0">
                            <div className="col-12 col-xl-3 d-md-row d-flex flex-column justify-content-center">
                                <div className="header-with-image">
                                    <h2 className="h2">Employer Awards</h2>
                                    <img src="//img.veeam.com/careers/global/img/about/awards.png" alt=""
                                         className="img-fluid "/>
                                </div>
                                <p>We’re committed to making Veeam a diverse and inspiring place to work for all of our employees — it’s how we deliver outstanding solutions and keep clients happy. </p>
                            </div>
                            <div className="col-12  col-xl-7 offset-xl-1">
                                <AwardsEmployee awards={employeeAwards}/>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default DiVeeam;
