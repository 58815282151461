import React from 'react'
import {IVacancy} from '../../../shared/types';
import {Link} from 'react-router-dom';

interface IVacancyButton {
    vacancy: IVacancy,
    param: string
}

class ApplyButton extends React.Component<IVacancyButton> {
    public render() {
        let vacancy = this.props.vacancy;
        let param = this.props.param;

        return (
            <Link to={{pathname:`${vacancy.applyUrl}?${param}`}} className="btn btn-success btn-cta mr-lg-4" target="_blank">Apply for a job</Link>
        )
    }
}

export default ApplyButton;
