import React, {useState} from 'react';

interface ISagQuestionProps {
  question: string,
  answer: string
}
const SagQuestion = ({question, answer}: ISagQuestionProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return <div className="row mb-4">
          <span role="button" className="col-2 d-flex justify-content-end" onClick={() => setIsOpen(!isOpen)}>
            <img src={`//img.veeam.com/careers/global/students-graduates/${isOpen ? 'minus' : 'plus'}.svg`} alt="" height="30px" width="30px"/></span>
    <div className="col-10">
      <p role="button" onClick={() => setIsOpen(!isOpen)}><b className="mb-2 question">{question}</b></p>
      {
        isOpen && <span className={"answer"}>
                {answer}
              </span>
      }

    </div>
  </div>
};

export default SagQuestion;