import React, {useContext} from 'react';
import ProgramView from "./ProgramView";
import {TEXT_BLOCKS} from "../StudentsAndGraduates.const";
import {VacanciesContext} from "../../../../shared/components.shared/components/providers/vacancies.context";
import {VacancyProgram} from "../../../types";

const PROGRAM_TEXT = {
  title: `Veeam Graduate
Program`,
  description: `Veeam’s Graduate Program is specific to students who join full-time after graduating or recently graduating within one year. We understand how stressful this new phase of life can be, which is why we created a way to supplement your new career. It aids in strengthening your personal and professional skills while starting full time at Veeam. In our program you will be part of a community of new graduates around the globe who will grow and develop alongside you. You will have the opportunity to work and learn from the industry’s best sales professionals, technical experts, and engineers.\n
Throughout the one-year program, you will benefit from professional development sessions, networking, mentoring, and social events. Sales professionals will receive extensive Sales Acceleration training to equip you with the tools needed to fast-track your career. Start your future today with Veeam’s Graduate Program!`
}
const GraduateProgram = () => {
  const vacancies = useContext(VacanciesContext)

  return (
    <ProgramView text={PROGRAM_TEXT} image={TEXT_BLOCKS.programs[1].imgBig} vacancyList={vacancies.data.filter((vacancy) => vacancy.program === VacancyProgram.GRADUATE)}/>
  );
};

export default GraduateProgram;