import React, {useContext} from 'react';
import ProgramView from "./ProgramView";
import {TEXT_BLOCKS} from "../StudentsAndGraduates.const";
import {VacanciesContext} from "../../../../shared/components.shared/components/providers/vacancies.context";
import {VacancyProgram} from "../../../types";

const PROGRAM_TEXT = {
  title: `Veeam Internship
Program (VIP)`,
  description: `The Veeam Internship Program will make you feel like a VIP! We look for students eager to gain real-life work experience in a fast paced, exciting, and professional environment with the opportunity to secure a full-time position once they complete their studies.\n
As a Veeam intern, you will take part in Sales Acceleration training that will allow you learn about our products and set you up for success in your role. You will be a part of a formalized internship program that includes professional development sessions, guest speakers, interactive learning sessions, networking, and social events.\n
The Veeam Internship Programs take place during summer break and are between 6-12 weeks, depending on your region. We offer internships in: US and Bucharest.`
}
const InternshipProgram = () => {

  const vacancies = useContext(VacanciesContext)

  return <ProgramView text={PROGRAM_TEXT} image={TEXT_BLOCKS.programs[0].imgBig} vacancyList={vacancies.data.filter((vacancy) => vacancy.program === VacancyProgram.INTERNSHIP)}/>

};

export default InternshipProgram;