const cares = [
    [

        {
            img: '//img.veeam.com/careers/global/employees/cares/bert_van_meel.png',
            name: 'Bert van Meel',
            title: 'Senior Territory Manager',
            quote: 'It\'s humbling seeing the children enjoying things I\'ve always taken for granted',
            link: '/cares/bert'
        },
        {
            img: '//img.veeam.com/careers/global/employees/cares/adriana_carrano.png',
            name: 'Adriana Carrano',
            title: 'HR Operations Project Manager',
            quote: 'Veeam Cares is about friendship, support and new opportunities',
            link: '/cares/adriana'
        },

    ],
    [
        {
            img: '//img.veeam.com/careers/global/employees/cares/cristina_popescu.png',
            name: 'Cristina Popescu',
            title: 'Office Specialist',
            quote: 'How you think and feel matters - that\'s what makes Veeam a great place to work',
            link: '/cares/cristina'
        },
        {
            img: '//img.veeam.com/careers/global/employees/cares/vicky_tan.png',
            name: 'Vicky Tan',
            title: 'Regional Marketing Manager',
            quote: 'We are blessed to be a blessing to others',
            link: '/cares/vicky'
        }
    ],
    [
        {
            img: '//img.veeam.com/careers/global/employees/cares/johnatan_stockwell.png',
            name: 'Jonathan Stockwell',
            title: 'Customer Success Representative',
            quote: 'When everyone cares about the welfare of others, it brings people care closer together',
            link: '/cares/jonathan'
        }
    ]
];

export default cares
