import React from 'react';
import {Nav, Tab} from 'react-bootstrap';
import {opportunityContent, opportunityNav} from '../../mocks/mocks-opportunities';
import AccordionComponent from '../../components/accordion/accordion';
import learnMore from '../../../cz/mocks/learn-more';
import VacanciesList from '../../components/vacancies-list/vacancies-list';
import VideoPlayer from '../../components/video/video';
import {Link, RouteComponentProps} from 'react-router-dom';
import {pickPeopleContent, pickPeopleNav} from '../../mocks/mock-pick-people';
import PickPeople from '../../components/pick-people/pick-people';
import CarouselImpression from '../../components/carousel-impression/carousel-impression';
import qs from 'qs';
import {ISelectItem} from '../../../shared/types';
import Scrollspy from 'react-scrollspy';
import pageMenu from '../../mocks/mocks-page-menu';

interface IVacanciesState {
    filter: {
        [key: string]: string | undefined | ISelectItem[] | number;
        query?: string;
    },
    isEmptyVacanciesList: boolean;
}


class Opportunities extends React.Component<RouteComponentProps, IVacanciesState> {
    constructor(props: any) {
        super(props);
        let queryParams = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true
        });

        this.state = {
            filter: {
                query: queryParams.query || '',
                department: queryParams.department || '',
                // @ts-ignore
                userLocation: ''
            },
            isEmptyVacanciesList: false,
        };
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleEmptyVacanciesList = this.handleEmptyVacanciesList.bind(this);
    }

    protected handleFilterChange(filterName: string, filterValue: any): void {
        this.setState((prevState: IVacanciesState) => {
            return {
                filter: {
                    ...prevState.filter,
                    [filterName]: filterValue
                }
            }
        });
    }


    protected handleEmptyVacanciesList(isEmpty: boolean) {
        if (this.state.isEmptyVacanciesList !== isEmpty) {
            this.setState(() => {
                return {
                    isEmptyVacanciesList: isEmpty
                }
            });
        }
    }


    public render() {

        return (
            <React.Fragment>
                <div className="page-menu-wrapper">
                    <div className="page-menu">
                        <Scrollspy items={pageMenu[1]} currentClassName="is-active">
                            <li><a href="#areas-to-join">Areas to join</a></li>
                            <li><a href="#live-roles">Live roles</a></li>
                            <li><a href="#we-look">We are looking for</a></li>
                            <li><a href="#become-veeamer">Become a Veeamer</a></li>
                        </Scrollspy>
                    </div>
                </div>
                <div className="bg-partial bg-partial--dark bg-partial--pattern">
                    <div className="container banner-spacer">
                        <div className="row">
                            <div
                                className="d-none d-md-flex flex-column justify-content-center col-md-3 col-lg-4 col-12">
                                <img src="//img.veeam.com/careers/global/pattersn-opportinities/banner-image-left.png"
                                     alt="" className="img-fluid img-banner--absolute"/>
                            </div>
                            <div
                                className="offset-md-1 offset-lg-0 col-md-7 col-lg-8 col-12 d-flex flex-column justify-content-between">
                                <h1 className="display-1 mt-0">Veeamazing opportunities</h1>
                                <p>Far from your average business, Veeam motivates people to craft their ideal careers.
                                    We do things our own way, and every one of our teams has its own credentials and
                                    track-record that makes it special. </p>
                                <p className="mb-2">You just have to ask yourself:</p>
                                <h4 className="display-4 mb-0 w-80 text-success-light">Which kind of career really
                                    stokes my passion?</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container container-spacer" id="areas-to-join">
                    <h1 className="display-1">Areas to join</h1>
                    <div className="tabs-wrapper">
                        <Tab.Container id="tabs" defaultActiveKey="0">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <Nav className="card card-scroll border-0 mb-0 flex-row flex-md-column flex-nowrap">
                                        {opportunityNav.map((nav, index: number) =>
                                            <Nav.Item key={`opp-nav-${index}`}>
                                                <Nav.Link eventKey={nav.index} className="justify-content-end">
                                                    {nav.nav.split(' ').map((navItem, index: number) => <span
                                                        className="pl-2" key={`opp-nav-item-${index}`}>{navItem}</span>)}
                                                </Nav.Link>
                                            </Nav.Item>
                                        )}
                                    </Nav>
                                </div>
                                <div className="col-12 col-md-8">
                                    <Tab.Content>
                                        {opportunityContent.map((content, index:number) =>
                                            <Tab.Pane eventKey={content.index} key={`opp=tab-pane-${index}`}>
                                                <span className="d-block position-relative" dangerouslySetInnerHTML={{__html: content.content}}/>
                                                {content.contentAccordion.map((item, index:number) =>
                                                    item && <AccordionComponent accordion={item} key={`opp-tab-pane-item-${index}`}/>
                                                )}
                                            </Tab.Pane>
                                        )}
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
                {
                    this.state.isEmptyVacanciesList &&
                    <React.Fragment>
                        <div className="bg-light position-relative py-0">
                            <div className="container container-spacer pt-0 py-5">
                                <h3 className="my-0">Unfortunately, your search returned no results</h3>
                            </div>
                        </div>
                    </React.Fragment>

                }
                <div className="bg-light d-none">
                    <div className="container container-spacer">
                        <div className="row">
                            <div className="col-12 col-xl-3">
                                <h2 className="display-2 mb-xl-0">Life in the offices</h2>
                            </div>
                            <div className="col-12 col-xl-9">
                                <div className="bg-with-video bg-with-video--relative">
                                    <VideoPlayer/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container container-spacer-lg" id="we-look">
                    <Tab.Container id="tabs" defaultActiveKey="1">
                        <div className="row d-flex flex-column-reverse flex-md-row">
                            <div className="col-12 col-md-8 col-lg-9">
                                <Tab.Content>
                                    <Tab.Pane eventKey={pickPeopleContent[0].index}>
                                        <p>{pickPeopleContent[0].content}</p>
                                        <div className="card-deck block-spacer">
                                            {pickPeopleContent[0].steps && pickPeopleContent[0].steps.map((item, index:number) =>
                                                item && <PickPeople people={item} key={`pick-people-${index}`}/>
                                            )}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={pickPeopleContent[1].index}>
                                        <CarouselImpression/>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                            <div className="col-12 col-md-4 col-lg-3">
                                <Nav className="card card-scroll border-0 mb-0 flex-row flex-md-column flex-nowrap">
                                    {pickPeopleNav.map((nav, index:number) =>
                                        <Nav.Item key={`pick-people-nav-${index}`}>
                                            <Nav.Link eventKey={nav.index}>
                                                {nav.nav.split(' ').map((navItem, index:number) => <span
                                                    className="pl-2" key={`pick-people-item-${index}`}>{navItem}</span>)}
                                            </Nav.Link>
                                        </Nav.Item>
                                    )}
                                </Nav>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
                <div className="bg-light">
                    <div className="container container-spacer">
                        <h2>Become a Veeamer</h2>
                        <div className="row mb-5 mb-md-0" id="become-veeamer">
                            <div
                                className="col-12 d-flex flex-column flex-md-row justify-content-md-between align-items-start">
                                <h3 className="mt-0">Explore our opportunities</h3>
                                <Link to={"/vacancies"} className="btn btn-link btn-link__cta">See all</Link>
                            </div>
                        </div>
                        <div className="card-deck card-deck-vacancies">
                            <VacanciesList filter={this.state.filter} limit={4} learnMoreData={learnMore}
                                           searchBlock={false}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

// @ts-ignore
export default Opportunities
