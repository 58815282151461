import React from 'react';
import {IVacancy} from '../../../types';

export type IVacanciesContext = {
    data: IVacancy[],
    isLoading: boolean,
    isCompleted: boolean,
    error: null | string
};

export const VacanciesContext = React.createContext<IVacanciesContext>({
    data: [],
    isLoading: false,
    error: null,
    isCompleted: false
});
