const pickPeopleNav = [
    {
        nav: 'How do we pick our people?',
        index: 1
    },
    {
        nav: 'How to make a great impression on us',
        index: 2
    },
];

const pickPeopleContent = [
    {
        content: 'We look for promising prospects who will fit our Veeamazing culture. ' +
            'To be certain we’ll feed each other’s success, we deliver an interview and selection process that looks something like this',
        steps: [
            {
                number: '1.',
                count: 'first',
                arrow: '//img.veeam.com/careers/global/icons/arrow_one.png',
                title: 'Getting to know you',
                body: 'Our Talent Acquisition Partners will call you and kickstart the process with a relatively quick and informal chat about your career goals.'
            },
            {
                img: '//img.veeam.com/careers/global/pickpeople/loop.png',
            },
            {
                number: '2.',
                count: 'second',
                arrow: '//img.veeam.com/careers/global/icons/arrow_second.png',
                title: 'Assessing your background',
                body: 'If you’re applying for a sales role, you’ll take a 20 to 30-minute online assessment to gauge your experience. For any other job role, we’ll set up a conversation with your hiring manager, so you can work out if you’ll do great things together!'
            },
            {
                number: '3.',
                count: 'third',
                arrow: '//img.veeam.com/careers/global/icons/arrow_third.png',
                title: 'Interview time',
                body: 'This is your time to shine. We’ll usually meet via video call or in-person and have a deeper conversation about where we can take your career, while you impress us with your vision for how you intend to step up in the role.'
            },
            {
                number: '5.',
                count: 'fifth',
                title: 'Ongoing support',
                body: 'We won’t leave to you find your own way. We’ll work with you through your notice period, answer all your questions and provide a clear onboarding process from your first day until you’ve settled into the business.'
            },
            {
                number: '4.',
                count: 'fourth',
                arrow: '//img.veeam.com/careers/global/icons/arrow_fourth.png',
                title: 'Welcome to Veeam!',
                body: 'If you’ve ticked all of our boxes and you like what we’ve had to say, we’ll extend you an offer to join the world’s leading data management company. '
            }

        ],
        index: 1
    },
    {
        slide: [
            [
                {
                    img: '',
                    title: 'Do your research',
                    body: 'Don’t just read the first page of the website. That’s a rookie error. Check out our press releases on the corporate site, uncover recent news surrounding our growth or leadership team, and dig a little deeper into our company values. We love people who appreciate our culture.'
                },
                {
                    img: '//img.veeam.com/careers/global/pickpeople/interview.png',
                    title: '',
                    body: ''
                },
                {
                    img: '',
                    title: 'Check us out on LinkedIn',
                    body: 'If you have the names of the managers you’ll be interviewing with, look them up on LinkedIn and find out if you have anything in common. We really don’t mind that level of initiative!'
                },
                {
                    img: '',
                    title: 'Ask great questions',
                    body: 'We embrace talkers. There should be a feeling that you’re interviewing us too, so don’t keep quiet. We may say “ask great questions”, but there’s no such thing as a foolish question either. If you don’t know something, ask. Demonstrate a burning desire to learn and be better.'
                },

            ],
            [

                {
                    title: 'Dress for success',
                    body: 'First impressions matter. Whether your interview is in-person or online via video, show us you’re a professional by being dressed like one. '
                },
                {
                    img: '//img.veeam.com/careers/global/pickpeople/interview.png'
                },
                {
                    title: 'Be prepared',
                    body: 'Better to be overprepared than underprepared, that’s our motto. Bring enough hard copies of your resume for each manager interviewing you. Bring a pen and a notepad. Try to imagine every possible scenario and make sure you have the answers.'
                },
                {
                    title: 'Get your story straight',
                    body: 'Look at the job description and identify the aspects of your experience, qualifications and ambition that matches it most strongly. Think of where you’ve performed brilliantly in previous roles, and remember, it’s your job to make us see why that’s relevant. '
                },
            ],
            [
                {
                    title: 'Ask for the job',
                    body: 'Tell us why you want the job! And why we should give it to you. It all boils down to passion. If we think you genuinely want to be a Veeamer, that will work massively in your favour. So don’t be afraid to speak from the heart.'
                },
                {
                    img: '//img.veeam.com/careers/global/pickpeople/interview.png'
                },
                {
                    title: 'Strong follow up',
                    body: 'Grab the contact information of everyone you meet. If you think of any questions you forgot to ask, or even left out a story you wish you told, email us within the next 24hours. We’re happy to consider it and we’ll know that you’re still thinking about us.'
                },
                {
                    title: 'Practice makes perfect',
                    body: 'If you’re unsure about your interview technique, practice with a friend or mentor. You may not think it works at first, but uttering your story out loud beforehand means it’s fresh in your mind – and comes out in the right order on the day of the interview.',
                },
            ],
        ],
        index: 2
    }

];

export {pickPeopleNav, pickPeopleContent};
