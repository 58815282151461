const shuffledItems = [
    {
        widget: 'slider',
    },
    {
        widget: 'gridSettings',
    },
    {
        tag: '<span class="sprites bg-whitepaper_success"></span><span class="text-success">life at Veeam</span>',
        title: '<h3 class="display-3 text-dark mb-3 w-75">Accolades, ambition, innovation & a Veeamazing culture: </h3>',
        subtitle: '<h4 class="text-success my-0 w-50">This is why 91% of our people consider us a ‘great place to work’</h4>',
        link: '/life/#what-it-means',
        type: 'culture',
        widget: 'lifeAtVeeam'
    },
    {
        tag: '<span class="sprites bg-whitepaper_green"></span><span class="text-secondary">Awards</span>',
        title: '<h3 class="display-3">Our awards tell the <span class="text-secondary">story of our success</span></h3>',
        subtitle: '',
        link: '/about/#awards',
        type: 'award_02',
        widget: 'aboutUs'
    },
    {
        tag: '<span class="sprites bg-whitepaper_white"></span><span>About us</span>',
        title: '<h3 class="display-3 mt-0">Join our instagram account!</h3>',
        subtitle: '',
        link: '//www.instagram.com/veeamcareers/',
        type: 'social',
        widget: 'aboutUs'
    },
    {
        widget: 'video'
    },
    {
        tag: '<span class="sprites bg-whitepaper_white"></span> <span class="text-white">Veeam Cares</span>',
        title: 'We care',
        subtitle: 'Thousands of hours spent transforming communities',
        employee: 'olga',
        link: '/life/#cares',
        widget: 'peopleStories'
    },
    {
        tag: '<span class="sprites bg-whitepaper_success"></span><span class="text-success">About us</span>',
        title: '<h3 class="display-3 mt-0 mb-3"><span class="text-success">Our growth story</span></h3>',
        subtitle: '<h4 class="text-dark w-65 my-0 mb-2">From 8 people with a laptop to 5,000+ thought leaders worldwide</h4>',
        link: '/about',
        type: 'growth',
        widget: 'aboutUs'
    },
    {
        tag: '<span class="sprites bg-whitepaper_success"></span><span class="text-success">About us</span>',
        title: '<h3 class="display-3"><span class="text-success">Competing</span> <span class="text-dark">on every global frontier</span></h3>',
        subtitle: '',
        link: '/about',
        type: 'competing',
        widget: 'aboutUs'
    },
    {
        tag: '<span class="sprites bg-whitepaper_success"></span><span class="text-success">About us</span>',
        title: '<h3 class="display-3 mb-1"><span class="text-dark">Incredible billion dollar success — </span><span class="text-success">and growing!</span> </h3>',
        subtitle: '',
        link: '/about',
        type: 'success',
        widget: 'aboutUs'
    },
    {
        tag: '<span class="sprites bg-whitepaper_success"></span><span class="text-success">About us</span>',
        title: '<h3 class="display-3 mb-1"><span class="text-success">Our goal: </span> <span class="text-dark">Become the world champion of data protection</span></h3>',
        subtitle: '',
        link: '/about',
        type: 'champions',
        widget: 'aboutUs'
    },
    {
        tag: '<span class="sprites bg-whitepaper_success"></span><span class="text-success">life at Veeam</span>',
        title: '<h3 class="display-3 text-dark mb-2">Nearly 2,100 online courses completed last year —</h3>',
        subtitle: '<h4 class="text-success mb-0 mt-4">go further, achieve more </h4>',
        link: '/life/#experience',
        type: 'courses',
        widget: 'lifeAtVeeam'
    },
    {
        tag: '<span class="sprites bg-whitepaper_white"></span><span class="text-white">life at Veeam</span>',
        title: '<h4 class="text-dark mb-2 mt-0">With events centered around connection, collaboration and celebration,</h4>',
        subtitle: '<h3 class="display-3 mb-0">you can be certain that veeam vibes are the best vibes </h3>',
        link: '/life/#global-focus',
        type: 'vibes',
        widget: 'lifeAtVeeam'
    },
    {
        tag: '<span class="sprites bg-whitepaper_white"></span><span class="text-white">life at Veeam</span>',
        title: '<h3 class="display-3 text-dark">Simply smart-talking, sales-driving heartfelt innovators: <span class="text-white">Our values</span></h3>',
        subtitle: '',
        link: '/life/#veeam-values',
        type: 'values',
        widget: 'lifeAtVeeam'
    },
    {
        tag: '<span class="sprites bg-whitepaper_white"></span><span class="text-white">life at Veeam</span>',
        title: '<h3 class="display-3 text-white">Technical<br> Customer<br> Support team</h3>',
        subtitle: '',
        link: '//tech.veeam.com/?utm_source=global+careers&utm_medium=banner&utm_campaign=tcs',
        type: 'external',
        widget: 'aboutUs'
    },
    {
        tag: '<span class="sprites bg-whitepaper_success"></span><span class="text-success">life at Veeam</span>',
        title: '<h3 class="display-3 mb-1"> <span class="text-white">Distinctly,<br> Inclusively Veeam</span></h3>',
        subtitle: '',
        link: '/diversity-and-inclusion',
        type: 'diveeam',
        widget: 'lifeAtVeeam'
    }
];

export default shuffledItems;
