const wigMob = [
    {
        img: '//img.veeam.com/careers/global/page_di/wig/claire.png',
        name: 'Claire Ingwersen',
        title: 'Senior Manager, Strategic Initiatives ',
        quote: 'The Women in Green program at Veeam brings a global network of people together, both women and men, who share a passion for growing female leaders. As the Communications and Branding lead for WIG, I have had the opportunity to collaborate with some dynamic individuals in the pursuit of the WIG mission. I had the good fortune to go through the WIG mentorship program, and am honored to help craft programs designed to help others achieve their professional goals.',
        link: '',
        type: 'wig'
    },
    {
        img: '//img.veeam.com/careers/global/page_di/wig/daphne.png',
        name: 'Daphne Chua',
        title: 'Field Marketing Manager, SEAK region',
        quote: 'As a new member of WIG, the support and connection from the team covers personal and career topics, such as the importance of uplifting and supporting each other, building team cohesion and inclusion via good communications and trust.. ' +
            'I have the opportunity to participate, ' +
            'feel the strength, support and passion from the women at Veeam. I look forward to more conversations and events around these topics I\'ll in the future.',
        link: '',
        type: 'wig'
    },
    {
        img: '//img.veeam.com/careers/global/page_di/wig/shanta.png',
        name: 'Shanta Francis',
        title: 'Territory Manager, VCSP- Gulf Shores',
        quote: 'As a past member of the Women in Green Global Events committee, I had the pleasure to work with a diverse group of women. What a wonderful experience it was, where we were able to collaborate and foster an environment of creativity. Our events provided informative, timely and inspiring topics to all Veeam employees across the globe. ' +
            'I am truly grateful for my time served and would recommend WIG to all persons looking to uplift the Veeam community.',
        link: '',
        type: 'wig'
    }
];

export default wigMob;
