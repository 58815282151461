import React from 'react';
import {Link} from "react-router-dom";

interface IProgramCard {
  program: {
    title: string,
    description: string,
    points: string[],
    button: string
    img: string
  }
  isReversed?: boolean
}
const ProgramCard = ({program, isReversed = false}: IProgramCard) => {
  return (
    <div className={`bg-blue-custom row d-flex flex-md-row${isReversed ? '-reverse': ''} p-4`}>
      <div className='col-12 col-md-5' style={{height: "390px"}}>
          <div style={{
              position: 'relative',
              margin: '0 auto',
              background: `url(${program.img}) no-repeat center center`,
              backgroundSize: 'cover',
              width: '100%',
              height: '100%',
              borderRadius: '10px'
            }}
          />
      </div>
      <div className='col-12 col-md-7 col d-flex program-description justify-content-center align-items-center'>
        <div className='col-12 col-m-10 d-flex flex-column'>
          <span className="display-4 mb-4 mt-4 mt-md-0"><b>{program.title}</b></span>
          <span>{program.description}</span>
          <ul style={{columns: 2}} className="mt-4">{program.points.map((point) => <li key={point} className={'mb-2'}><b>{point}</b></li>)}</ul>
          <div className="col-8 col-lg-6 p-0 mx-auto mx-md-0">
            <Link to={program.button}
                  className="btn btn-secondary my-4">Find out more</Link>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ProgramCard;