export const COMMITMENTS = [
  [
    {
      title:"Continue to enhance our gender equality policies",
      image:"//img.veeam.com/careers/global/gender-site/equality.svg",
      alt:"icon-gender"
    },
    {
      title:"Provide women with the tools to empower them to succeed",
      image:"//img.veeam.com/careers/global/gender-site/women1.svg",
      alt:"icon-women"
    }
  ],
  [
    {
      title:"Continue working to better recruit and hire",
      image:"//img.veeam.com/careers/global/gender-site/hire.svg",
      alt:"icon-hire"
    },
    {
      title:"Invest in a culture where all women feel valued, heard, supported, and included",
      image:"//img.veeam.com/careers/global/gender-site/invest.svg",
      alt:"icon-invest"
    }
  ],
]



export const TEXT_BLOCKS = {
  banner: {
    title: "Women At Veeam"
  },
  commitment: {
    title: "Our Commitment to Equality"
  },
  empowering: {
    title: "Empowering",
    subtitle: "Women in Tech",
    firstP: "One of our proudest and established programs to support and drive more women into tech is our women’s employee resource group — Women in Green.",
    secondP: "This community is a strategic diversity initiative that uplifts women and supports career growth at all levels.",
    button: "Learn More"
  },
  inspirational: {
    title: "Inspirational Voices",
    subtitle: "These women are leading, elevating, and making a change."
  },
  career: {
    title: "We are working to eliminate gender bias in the workplace",
    motivation: "Progress is impossible without change. One of our highest priorities is to continue supporting women in the workplace. We aim to inspire and accelerate gender equality and celebrate our females along the way. Because we can’t achieve our goals unless everyone has a seat at the table and is contributing.",
    button: "Find a Career"
  },
  awards: {
    title: "Employer Awards",
    slogan: "We’re committed to making Veeam a diverse and inspiring place to work for all of our employees — it’s how we deliver outstanding solutions and keep clients happy. "
  }
}
