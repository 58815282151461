const values = [
    [
        {
            img: '//img.veeam.com/careers/global/values/sells.svg',
            values: '<ul>' +
                '<li>We do everything possible to win a deal, no matter what our role.</li>' +
                '<li>We make it our business to know what’s important to our customers.</li>' +
                '<li>We know our value and what sets us apart from the competition.</li>' +
                '</ul>'
        },
        {
            img: '//img.veeam.com/careers/global/values/innovate.svg',
            values: '<ul>' +
                '<li>We produce quickly and perfect through iteration.</li>' +
                '<li>We don’t suffer from ‘analysis paralysis’ but have a bias towards action.</li>' +
                '<li>We trust our commonsense and follow our best judgment.\n</li>' +
                '</ul>'
        },
        {
            img: '//img.veeam.com/careers/global/values/keep-simple.svg',
            values: '<ul>' +
                '<li>Complex is easy.</li>' +
                '<li>Simple is difficult.</li>' +
                '<li>Keep it simple.</li>' +
                '</ul>'
        },
    ],
    [
        {
            img: '//img.veeam.com/careers/global/values/conversation.svg',
            values: '<ul>' +
                '<li>We’re genuine and real.</li>' +
                '<li>We don’t exaggerate — our products and our people are really that good.\n</li>' +
                '<li>We are inclusive and welcoming.</li>' +
                '</ul>'
        },
        {
            img: '//img.veeam.com/careers/global/values/speed.svg',
            values: '<ul>' +
                '<li>We move fast and we work hard.</li>' +
                '<li>We’re energetic self-starters and we take the initiative.</li>' +
                '<li>We give that final push to see projects through to completion.</li>' +
                '</ul>'
        },
        {
            img: '//img.veeam.com/careers/global/values/team.svg?23092020',
            values: '<ul>' +
                '<li>We are passionate and enthusiastic.\n</li>' +
                '<li>We help the team to succeed.\n</li>' +
                '<li>We’re smart but we know how to listen.\n</li>' +
                '<li>Lack of experience is not a problem.\n</li>' +
                '</ul>'
        },
    ],
    [
        {
            img: '//img.veeam.com/careers/global/values/compete.svg',
            values: '<ul>' +
                '<li>We have a strong competitive drive and we don’t give up.</li>' +
                '<li>We have an intrinsic need to achieve.</li>' +
                '<li>We are optimists and we believe in ourselves.</li>' +
                '</ul>'
        }
    ],

];

export default values;
