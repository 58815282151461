import React, {createRef} from 'react';



interface IVideoPlayerState {
    isPlaying?: boolean;
}

interface IVideoWidthProps {
    width?: string,
}



class VideoPlayer extends React.Component<IVideoWidthProps, IVideoPlayerState> {
    private videoRef: React.RefObject<HTMLVideoElement>;

    constructor(props: any) {
        super(props);
        this.videoRef = React.createRef();
        this.state = {
            isPlaying: false,
        };

        this.onPlayButtonClick = this.onPlayButtonClick.bind(this);

    }



    componentDidMount(): void {
        let video = this.videoRef.current;

        if(video) {
            video.onplay = () => {
                this.setState({
                    isPlaying: true
                });
            };

            video.onpause = () => this.setState({
                isPlaying: false
            });
        }
    }


    componentWillUnmount(): void {
        let video = this.videoRef.current;

        // @ts-ignore
        video.oncanplaythrough = null;
        // @ts-ignore
        video.onplay = null;
        // @ts-ignore
        video.onpause = null;
        // @ts-ignore
        video.ontimeupdate = null;
        // @ts-ignore
        video.link = '';
    }

    onPlayButtonClick() {
        let video = this.videoRef.current;

        this.setState({
            isPlaying: true });
        // @ts-ignore
        this.videoRef.current.play();
       /* this.setState({ isPlaying: false });
        // @ts-ignore
        this.videoRef.current.pause();*/
    }


    render() {
       // @ts-ignore
        const {isPlaying} = this.state;
        let width = this.props.width;
        return (
            <React.Fragment>
                <button className={!isPlaying ? "d-block btn btn-play" : "d-none"} onClick={this.onPlayButtonClick}/>
                <video controls ref={this.videoRef} src="//img.veeam.com/careers/banners/bg-video-new-shorten.mp4"
                       poster="//img.veeam.com/careers/global/video/video-lg.png"
                       width={width} >
                </video>
            </React.Fragment>

        )
    }


}

export default VideoPlayer;
