import React from 'react';
import {RouteComponentProps} from 'react-router';
import {PeoplestoriesContext} from '../providers/peoplestories.context';
import NotFound from '../../../shared/components.shared/components/not-found/not-found';
import StoriesGrowsVeronica from './stories-grows-veronica';
import StoriesGrowsAshleigh from './stories-grows-ashleigh';
import StoriesGrowsAdrian from './stories-grows-adrian';
import StoriesGrowsDaniel from './stories-grows-daniel';
import StoriesGrowsFrancisco from './stories-grows-francisco';
import StoriesGrowsJames from './stories-grows-james';
import StoriesGrowsMarius from './stories-grows-marius';
import StoriesGrowsRick from './stories-grows-rick';
import StoriesGrowsSergey from './stories-grows-sergey';
import StoriesGrowsVenkatesh from './stories-grows-venkatesh';
import StoriesGrowsZetty from './stories-grows-zetty';
import StoriesGrowsMacro from './stories-grows-macro';

interface IGrowsAllProps extends RouteComponentProps <{
    alias: string
}> {}

class StoriesGrowsAll extends React.Component<IGrowsAllProps> {

    static contextType = PeoplestoriesContext;
    context! : React.ContextType<typeof PeoplestoriesContext>;

    componentDidUpdate(prevProps: IGrowsAllProps) {

        if (prevProps.match.params.alias !== this.props.match.params.alias) {
            window.scrollTo(0, 0);
        }
    }

    public render() {
        let alias = this.props.match.params.alias;
        let story = this.context && this.context.data && this.context.data.find(item => item.name === alias);
        let component = <NotFound/>;

        if(story) {
            switch (this.props.match.params.alias) {
                case 'adrian' : component=<StoriesGrowsAdrian story={story}/>; break;
                case 'ashleigh' : component=<StoriesGrowsAshleigh story={story}/>; break;
                case 'daniel' : component=<StoriesGrowsDaniel story={story}/>; break;
                case 'francisco' : component=<StoriesGrowsFrancisco story={story}/>; break;
                case 'james' : component=<StoriesGrowsJames story={story}/>; break;
                case 'marius' : component=<StoriesGrowsMarius story={story}/>; break;
                case 'rick' : component=<StoriesGrowsRick story={story}/>; break;
                case 'sergey' : component=<StoriesGrowsSergey story={story}/>; break;
                case 'venkatesh' : component=<StoriesGrowsVenkatesh story={story}/>; break;
                case 'veronica' : component=<StoriesGrowsVeronica story={story}/>; break;
                case 'zetty' : component=<StoriesGrowsZetty story={story}/>; break;
                case 'macro' : component=<StoriesGrowsMacro story={story}/>; break;
            }
        }

        return (
            component
        )
    }
}

export default StoriesGrowsAll;


