import React from 'react';
import {IOfficesContext, OfficesContext, defaultValue} from './offices.context';
import axios from 'axios';
import {IOffice} from "../../../types";
import {type} from "os";

interface IOfficesProviderProps {
    children: JSX.Element[] | JSX.Element
}

interface IOfficesProviderState extends IOfficesContext {}

class OfficesProvider extends React.Component<IOfficesProviderProps, IOfficesProviderState> {
    constructor(props: IOfficesProviderProps) {
        super(props);
        this.state = defaultValue;
    }
    componentDidMount() {
        this.setState({
            isLoading: true,
            isCompleted: false
        });
        axios.get('/api/office').then((res) => {
            this.setState({
                ...this.state,
                data: (res.data || []).map(_mapOfficeFrom),
                isLoading: false,
                isCompleted: true,
                error: null
            });
        }).catch((res) => {
            console.error(res);
            this.setState({
                ...this.state,
                isLoading: false,
                error: res.data
            })
        })
    }

    public render() {
        return (
            <OfficesContext.Provider value={this.state}>
                {this.props.children}
            </OfficesContext.Provider>
        )
    }
}

function _mapOfficeFrom(item: any): IOffice {
    let result = {
        name: item.name && typeof item.name === 'string' ? item.name : '',
        country: '',
        officeName: '',
        vacancies: []
    };
    let nameParts = result.name.split(',').map((part:string) => part.trim());
    if (nameParts.length > 1) {
        result.country = nameParts.pop();
        result.officeName = nameParts[0];
    }

    return result;
}

export default OfficesProvider;
