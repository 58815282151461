import React from 'react'
import { IVacancy } from '../../../../shared/types'
import VacancyListItem from '../../../components/vacancy-list-item/vacancy-list-item'
import { TEXT_BLOCKS } from '../StudentsAndGraduates.const'
import StudentsAndGraduatesFooter from '../footer/StudentsAndGraduates.footer'

interface IProgramViewProps {
  text: {
    title: string
    description: string
  }
  image: string
  vacancyList: IVacancy[]
}

const ProgramView = ({ text, image, vacancyList }: IProgramViewProps) => {
  return (
    <>
      <div className="bg-white">
        <div className="container container-spacer pb-0">
          <div className="row">
            <img src={image} className="img-fluid col-12" alt="banner" />
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container container-spacer">
          <div className="row" style={{ whiteSpace: 'pre-wrap' }}>
            <span className="col-12 col-md-4 mb-5 display-3">{text.title}</span>
            <span className="col-12 col-md-8">{text.description}</span>
          </div>
        </div>
      </div>

      <div className="bg-light">
        <div className="container container-spacer">
          <div className="row">
            {vacancyList.length > 0 ? (
              vacancyList.map((vacancy) => (
                <div className={'col-12 col-md-6 mb-5'}>
                  <VacancyListItem
                    vacancy={vacancy}
                    key={`vacancy-${vacancy.id}`}
                  />
                </div>
              ))
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center text-center col-12">
                <span className="display-3 mb-5">
                  Join Our Talent Community to Stay Updated on Future Open Roles
                </span>
                <div className="row">
                  <a
                    href="https://boards.eu.greenhouse.io/veeamsoftware/jobs/4304957101"
                    target="_blank"
                    className="btn btn-secondary mt-4 col-6"
                  >
                    Let's Go!
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="container container-spacer">
          <div className="row">
            <div className="col-12 col-md-3 mb-4 mb-md-0">
              <span className="display-3">
                {TEXT_BLOCKS.disctinctly.disctinctly}
              </span>
            </div>
            <div className="d-lg-block col-md-2" />
            <div className="col-12 col-md-7">
              <span>{TEXT_BLOCKS.disctinctly.description}</span>
            </div>
          </div>
          <div className="row">
            <div className="d-none d-lg-block col-lg-5" />
            <div className="col-12 col-lg-7 d-flex flex-column flex-md-row justify-content-between mt-5 px-5">
              {TEXT_BLOCKS.disctinctly.icons.map((iconUrl) => (
                <img
                  src={`//img.veeam.com/careers/global/students-graduates/${iconUrl}`}
                  className="img-fluid"
                  style={{ maxHeight: '250px' }}
                  alt="banner"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <StudentsAndGraduatesFooter />
    </>
  )
}

export default ProgramView
