import React from 'react';
import {IPeopleStories} from '../../types'

interface IPeopleStoriesProps {
    story: IPeopleStories;
    bg: string;
}

interface IPeopleStoriesState {
    displayText: boolean
}

class PeopleStories extends React.Component<IPeopleStoriesProps, IPeopleStoriesState> {

    constructor(props: any) {
        super(props);

        this.state = {
            displayText: false
        };

        this.handleDisplayText = this.handleDisplayText.bind(this);
    }

    handleDisplayText() {
        this.setState({
            displayText: !this.state.displayText
        })
    }

    public render() {
        let story = this.props.story;
        let bg = this.props.bg;



        return (
            <React.Fragment>
                <div className={bg + ' banner text-center py-5 py-md-0'}>
                    <img className="d-none d-lg-block img-fluid" src={story && story.banner} alt=""/>
                    <img className="d-inline-block d-lg-none" src={story && story.bannerMobile} alt=""/>
                    <h1 className="d-block d-lg-none" dangerouslySetInnerHTML={{__html: story && story.title}}></h1>
                </div>
                <div className="container container-spacer">
                    <div className="row">
                        <div className=  {!this.state.displayText ? 'col-12 col-md-6 mobile mobile-wrapper' : 'col-12 col-md-6'} dangerouslySetInnerHTML={{__html: story && story.copy}}></div>
                        <div className="col-12 d-block d-sm-none p-0">
                            <a className="btn btn btn-link btn-link__cta btn-link--shadowed py-5 pl-4" onClick={this.handleDisplayText}>
                                {!this.state.displayText ? 'Click to read more' : 'Click to read less'}
                            </a>
                        </div>
                        <div className="col-12 col-md-6 d-flex flex-column justify-content-md-between">
                            <blockquote className="blockquote">{story && story.quote}</blockquote>
                            <h3 className="mb-4" dangerouslySetInnerHTML={{__html: story && story.signature}}></h3>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PeopleStories
