const clientsNav = [
    {
        nav: 'Clients',
        index: 1
    },
    {
        nav: 'Partners',
        index: 2
    }
];


const clientsContent = [
    {
        desc: 'From IBM, Siemens and Shell, to Mercedes-Benz, BUPA or Ducks Unlimited in Canada — whether Fortune 500 enterprises or small start-up businesses...',
        content: 'Every one of our clients knows they’ll receive five-star customer service and Modern Data Protection products from Veeam',
        clients: [
            '//img.veeam.com/careers/global/clients/clients_marriott.png',
            '//img.veeam.com/careers/global/clients/clients_kohls.png',
            '//img.veeam.com/careers/global/clients/clients_cocacola.png',
            '//img.veeam.com/careers/global/clients/clients_carmax.png',
            '//img.veeam.com/careers/global/clients/clients_micron.png',
            '//img.veeam.com/careers/global/clients/clients_dillards.png',
            '//img.veeam.com/careers/global/clients/clients_uss.png',
            '//img.veeam.com/careers/global/clients/clients_fbe.png',
            '//img.veeam.com/careers/global/clients/clients_intel.png',
            '//img.veeam.com/careers/global/clients/clients_penske.png',
            '//img.veeam.com/careers/global/clients/clients_abm.png',
            '//img.veeam.com/careers/global/clients/clients_basf.png',
            '//img.veeam.com/careers/global/clients/clients_siemens.png',
            '//img.veeam.com/careers/global/clients/clients_3m.png',
        ],
        index: 1
    },
    {
        desc: 'We have an entire ecosystem of partners, which means we’ve partnered with the best technology companies in the industry to bring services to our joint customers worldwide — closing the gap between expectations and delivery.',
        content: '',
        clients: [
            '//img.veeam.com/careers/global/partners/partners_hpe.png',
            '//img.veeam.com/careers/global/partners/partners_cisco.png',
            '//img.veeam.com/careers/global/partners/partners_netapp.png',
            '//img.veeam.com/careers/global/partners/partners_vmware.png',
            '//img.veeam.com/careers/global/partners/partners_microsoft.png',
            '//img.veeam.com/careers/global/partners/partners_amazon.png',
            '//img.veeam.com/careers/global/partners/partners_microsoft.png',
            '//img.veeam.com/careers/global/partners/partners_ibm.png',
            '//img.veeam.com/careers/global/partners/partners_nutanix.png',
            '//img.veeam.com/careers/global/partners/partners_purestorage.png',
            '//img.veeam.com/careers/global/partners/partners_dell.png',
            '//img.veeam.com/careers/global/partners/partners_lenovo.png',
            '//img.veeam.com/careers/global/partners/partners_exagrid.png',
            '//img.veeam.com/careers/global/partners/partners_fujitsu.png',
            '//img.veeam.com/careers/global/partners/partners_quantum.png',
        ],
        index: 2
    }
];

export {clientsNav, clientsContent}
