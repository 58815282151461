const vi = [
    [
        {
            img: '//img.veeam.com/careers/global/page_di/vi/nisha.png',
            name: 'Nisha Tijare',
            title: 'Inside Sales Senior manager',
            quote: 'As an Inclusion Group member, I personally feel privileged to be able to work towards creating a culture where everyone can be themselves and be their best. ' +
                'I believe this approach is dynamic and constantly evolving. Being a member also gives me an opportunity to not only help other employees, ' +
                'but myself as well to do ongoing reflection and continuous enhancement.',
            link: '',
            type: 'vi'
        },
        {
            img: '//img.veeam.com/careers/global/page_di/vi/matt.png',
            name: 'Matt Kalmonson',
            title: 'VP, US Commercial Sales and Service Providers',
            quote: 'We launched our Inclusion ERG two years ago and the positive feedback and impact has been incredible. ' +
                'Our mantra is to “be yourself and be proud of who you are” as a diversified Veeam is a better Veeam. The Inclusion ERG covers broad topics from religion, ' +
                'race, disabilities and so much more.  All of our efforts aim to make Veeam the best place to work for all employees while helping us understand our differences, ' +
                'appreciate each-others uniqueness, and to listen to each-others journeys and ideas.',
            link: '',
            type: 'vi'
        },
    ]
];

export default vi;
