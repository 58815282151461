export interface IShufflePhotos {
    title: string;
    photo: string;
}

export interface IVeeamValues {
    img: string;
    values: string;
}

export interface IVeeamEmployees {
    img: string;
    name: string;
    title: string;
    quote: string;
    link: string;
    type?: string
}

export interface IVeeamFocus {
    img: string;
    copy: string;
}

export interface IPeopleStories {
    banner: string;
    title: string;
    bannerMobile: string;
    copy: string;
    quote: string;
    signature: string;
    name: string;
}

export interface IAskQeustion {
    name: string,
    secondname: string,
    img: string,
    position: string,
    areas: string[] | null,
    regions: string[],
    subregions: string[] | null,
    countries: string[] | null,
    profilelink: string,
    default: boolean,
    rule: {[key in 'OR' | 'AND']: Array<keyof IAskQeustion>} | null
}

export interface IAwards {
    year: string,
    title?: string,
    subTitle?: string,
    img: string,
    content?: string
}

export interface IUserLocation {
    region: string,
    subregion?: string,
    countryCode: string,
    country: string,
    state?: string,
    city?: string,
    isFake?: boolean,
    original?: object
}

export interface IBaseContext<T> {
    data: T,
    isLoading: boolean,
    isCompleted: boolean,
    error: null | string
}

export enum defaultsEnum {
    all = 'all',
    experience = 'without',
    departmentAll = 'All departments',
    experienceAll = 'Any experience',
    locationAll = 'All countries',
    statesAll = 'All states',
    cityAll = 'All cities'
}

export enum VacancyProgram {
    INTERNSHIP = 'Internship',
    GRADUATE = 'Graduate',
    NOT_APPLICABLE = 'Not Applicable'
}
