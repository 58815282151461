import React from 'react';
import {Nav, Tab} from 'react-bootstrap';
import {AwardsContext, IAwardsContext} from '../providers/awards.context';

interface IAwardsDragState {
    dragRight: boolean,
    dragLeft: boolean,
    page: number,
    activeTab: any,
    isClicked: boolean
}


class Awards extends React.Component<{}, IAwardsDragState> {
    static itemsPerPage = 2;

    constructor(props:any) {
        super(props);
        this.state = {
            dragRight: false,
            dragLeft: false,
            page: 1,
            activeTab: '2021',
            isClicked: false
        };
        this.onDragLeft = this.onDragLeft.bind(this);
        this.onDragRight = this.onDragLeft.bind(this);
        this.loadMore = this.loadMore.bind(this)
    }

    protected  onDragRight():void {
        this.setState(state => ({
            dragRight: !this.state.dragRight,
            dragLeft: this.state.dragLeft
        }))
    }

    protected onDragLeft():void {
        this.setState(state => ({
            dragLeft: !this.state.dragLeft,
            dragRight: this.state.dragRight
        }))
    }

    protected loadMore() {
        this.setState({
            isClicked: !this.state.isClicked,
            page: this.state.page + 1
        });
    }


    public render () {
        return (
            <AwardsContext.Consumer>
                {
                    context => this.renderAwards(context)
                }
            </AwardsContext.Consumer>
        )
    }


    protected renderAwards(context: IAwardsContext | any) {
        let result = (context && context.data && context.data.length > 0 && context.data) || [];
        let years =  result.map((item:any) => item.year).filter((v:any, i:any, a:any) => a.indexOf(v) === i);
        let visibleAwards = result.slice(0,  1 * Awards.itemsPerPage);
        let newsMap:any = {};
        result.forEach((news:any) => {
            let year = news.year || null;
            if(!year) {return;}
            if (!newsMap[year]) {
                newsMap[year] = [];
            }
            newsMap[year].push(news);
        });

        return (
            <div>
                <Tab.Container id="tabs" defaultActiveKey={this.state.activeTab}>
                    <div className="card-wrapper">
                        <span className="indicator indicator-left" onClick={this.onDragRight}></span>
                        <span className="indicator indicator-right" onClick={this.onDragLeft}></span>
                        <Nav className={this.state.dragLeft ? "move-left card card-scroll card-scroll--timeline mb-0 flex-row flex-nowrap w-100 bg-transparent" :
                            "card card-scroll card-scroll--timeline mb-0 flex-row flex-nowrap w-100 bg-transparent" ||
                            this.state.dragRight ? "move-right card card-scroll card-scroll--timeline mb-0 flex-row flex-nowrap w-100 bg-transparent" :
                                "card card-scroll card-scroll--timeline mb-0 flex-row flex-nowrap w-100 bg-transparent"}>
                            {years.map((year:any, index:number) =>
                                <Nav.Item key={`award-${index}`}>
                                    <Nav.Link eventKey={year}
                                              className= "nav-link--timeline justify-content-end">
                                        <span className="pl-2">{year}</span>
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                        </Nav>
                    </div>
                    <Tab.Content>
                        {Object.keys(newsMap).map((year) =>
                            <Tab.Pane eventKey={year} >
                                <div className="row mt-5 position-relative" style={{overflow: !this.state.isClicked ? 'hidden' : 'auto'}}>
                                    <div className="col-12 position-static">
                                        {newsMap[year].slice(0, 2).map((item:any) =>
                                        <div className="awards">
                                            <span>
                                                <p className="btn btn-link btn-link--fake mb-0" >
                                                    <span dangerouslySetInnerHTML={{__html:item.title}}/>
                                                    <br/>
                                                    {item.subTitle && <small className="small" dangerouslySetInnerHTML={{__html:item.subTitle}}/>}
                                                </p>
                                            </span>
                                        </div>
                                        )}
                                        {newsMap[year].slice(2).map((item:any) =>
                                            <div className={!this.state.isClicked ? "d-none awards" : "awards"}>
                                            <span>
                                                <p className="btn btn-link btn-link--fake mb-0" >
                                                    <span dangerouslySetInnerHTML={{__html:item.title}}/>
                                                    <br/>
                                                    {item.subTitle && <small className="small" dangerouslySetInnerHTML={{__html:item.subTitle}}/>}
                                                </p>
                                            </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Tab.Pane>
                        )}
                        <div className="block-spacer-top text-center">
                            {visibleAwards.length < result.length &&
                            <button className="btn btn-outline-success" onClick={this.loadMore}>{!this.state.isClicked ? 'Load more' : 'Show less'}</button>
                            }
                        </div>
                    </Tab.Content>
                </Tab.Container>

            </div>
        )
    }
}


export default Awards;
