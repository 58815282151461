import * as React from 'react';

export type IThemeContext = {
    transparentMenu: boolean,
    searchQuery: string,
    setSearchQuery(q: string): void,
    setMenuTransparency(value: boolean): void
};

export const ThemeContext = React.createContext<IThemeContext>({
        transparentMenu: false,
        searchQuery: '',
        setSearchQuery: () => {
        },
        setMenuTransparency: () => {
        }
    }
);
