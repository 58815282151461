export const TEXT_BLOCKS = {
  main: {
    launch: {
      header: `Graduates and Interns –`,
      description: `Our graduate programs and internships will allow you to start making your impact on the world. Whether you are looking to grow your career in Sales, Marketing, or in a more technical role, we can give you the platform to achieve your career goals.`
    },
    grow: {
      growWithUs: `Grow With Us!`,
      description: `Veeam is the global leader in Modern Data Protection, serving over 400,000+ organizations, including 82% of the Fortune 500 and 69% of the Global 2,000.\n
If you want to accelerate digital transformation, protect against cybercrime and drive business resilience, then Veeam can offer you the opportunity in both sales and technical roles to help you make an impact on the world. Our Veeam Internship Program and our Veeam Graduate Program are designed to complement your business role with professional skill development and provide experiences to teach you how to translate your academic achievements into career success. You will receive support and training, experiences that will challenge you, well-being support in an inclusive work environment, and a range of flexible and remote working options depending on where you are in the world.  We are committed to investing in you and your professional development, helping you kick-start your career.`
    }
  },
  programs: [
    {
      title: 'Veeam Internship Program (VIP)',
      description: 'Develop Your Skills and Make an Impact!\n\n' +
        'We have internships across the globe designed to let you test drive Veeam. As an intern, you will get real-life work experience, professional development sessions, networking opportunities, and team building with fellow interns.',
      points: ['Weekly programs', 'Sales Training', 'Soft Skills Training', 'Networking Events'],
      img: '//img.veeam.com/careers/global/students-graduates/internship.png',
      imgBig: '//img.veeam.com/careers/global/students-graduates/internship_big.png',
      button: '/students-and-graduates/internship'
    },
    {
      title: 'Veeam Graduate Program',
      description: 'The Next Generation of Veeamers!\n\n' +
        'As a new graduate, we have opportunities to match your passion and strengths where you can curate amazing career experiences. You will receive support and training to build skills for the future, work in a place that rewards performance, and become an integral part of our collaborative and connected Veeam culture.',
      points: ['Monthly programs', 'Sales Training', 'Development Opportunities', 'Mentoring'],
      img: '//img.veeam.com/careers/global/students-graduates/veeam_graduated.png',
      imgBig: '//img.veeam.com/careers/global/students-graduates/graduate_big.png',
      button: '/students-and-graduates/graduate'
    }
  ],
  students: [
    {
      img:'//img.veeam.com/careers/global/students-graduates/students/sandra_friery.png',
      name: 'Sandra Friery, Inside Sales VIP 2022',
      university: 'University of Georgia Joining Veeam\n' +
        'after Graduation',
      hear: 'I thoroughly enjoyed gaining insight on building my personal brand, resume, and interview techniques! I had a fantastic time getting to know, connect, and network with the other interns and full-time employees at Veeam. Everyone is so kind and open!'
    },
    {
      img:'//img.veeam.com/careers/global/students-graduates/students/janey_maude.png',
      name: 'Janey Maude, Customer Success VIP 2022',
      university: 'Georgia State UniversityJoining Veeam\n' +
        'after Graduation',
      hear: 'Career development, guest speakers, diversity and Inclusion panels, networking, and swag - Onsite week had it all. I thoroughly enjoyed getting to meet my fellow cohorts, and those we have worked with over the last few weeks. Being onsite helped me better understand the breadth of the Veeam culture and helped solidified that Veeam is a company that I want to continue to work with and for.'
    },
    {
      img:'//img.veeam.com/careers/global/students-graduates/students/mariam_conde.png',
      name: 'Mariam Conde, Marketing VIP 2022',
      university: 'University of GeorgiaJoining Veeam\n' +
        'after Graduation',
      hear: 'I felt that the onsite was an amazing experience. I enjoyed the informative and helpful conversations we had with all of our speakers as well as the chance to connect with the other interns.'
    },
    {
      img:'//img.veeam.com/careers/global/students-graduates/students/kiarra_jackson.png',
      name: 'Kiarra Jackson, Customer Success VIP 2022',
      university: 'Georgia Southern UniversityReturning\n' +
        'for a Second Internship',
      hear: 'I learned so much in such a small frame of time. I made so many long-lasting connections, and I was able to see what a great work culture looks like. So, that makes me excited for the future.'
    },
    {
      img:'//img.veeam.com/careers/global/students-graduates/students/alessandro_piras.png',
      name: 'Alessandro Piras, Inside Sales VIP 2022',
      university: 'University of Cagliari – Italy\n' +
        'Joining Veeam after Graduation',
      hear: 'Veeam gave me very useful tools in order to figure out what the most suitable career path for me would be. The whole experience was great thanks to the weekly challenges, fun Fridays, my colleagues and the management team who always made sure to check up on us. Even though I was far from home, I have never felt alone.'
    },
    {
      img:'//img.veeam.com/careers/global/students-graduates/students/theo_calin.png',
      name: 'Theo Calin, Renewals and Customer\n' +
        'Success VIP 2022',
      university: 'National University of Political Studies\n' +
        'and Public Administration',
      hear: 'This program was a truly fantastic experience. I was able to get the confidence I would need to be successful moving forward thanks to Veeam\'s buddy system. My team took care of me no matter what, and any questions I had were always answered. In the end this program was an experience worth having.'
    },
  ],
  disctinctly: {
    disctinctly: "Distinctly\n" +
      "Veeam",
    description: `Veeam is committed to creating a workplace that not only welcomes each employee to be their full self but encourages it! We are not looking for you to “fit” into our culture, we are looking for you to add to it. At Veeam our interns and graduates are a rich part of our Employee Resource Groups, bringing energy and passion to the company and helping us celebrate each other and give back to the community through our Veeam Cares events. Veeam is for everyone!`,
    icons: ['veeam_ahit.svg', 'veeam_inclusion.svg', 'veeam_wig.svg']
  },
  learnMore: [
    {
      videoUrl: 'https://www.youtube.com/embed/pBB_PiNrMZI?autoplay=1',
      posterUrl: '//img.veeam.com/careers/global/students-graduates/video_1.png'
    },
    {
      videoUrl: 'https://www.youtube.com/embed/MAMB00UmRmo?autoplay=1',
      posterUrl: '//img.veeam.com/careers/global/students-graduates/video_2.png'
    },
    {
      videoUrl: 'https://www.youtube.com/embed/6evewCbGDG4?autoplay=1',
      posterUrl: '//img.veeam.com/careers/global/students-graduates/video_3.png'
    },

  ],
  questions: {
    questions: `Questions?
Contact us`,
    navigate: `Navigating finding an internship or career can leave you feeling you have more questions than answers.
We get it. We were once right in your shoes. That is why our University Team at Veeam is available to help!`,
    button: `Join our Talent Community`,
  },
  faq: {
    title: 'FAQs',
    questions: [
      {
        question: 'Do you sponsor international students?',
        answer: 'We do not sponsor students for internships, with the only exception being our Kasten tech roles on occasion.'
      },
      {
        question: 'What year do interns need to be?',
        answer: 'We focus on students at universities who have started growing curious about their future careers and want to gain real project experience. Typically, our interns are in their third or fourth year of university with the potential to join Veeam full-time upon graduation.'
      },
      {
        question: 'What does Veeam look for when recruiting students?',
        answer: 'It is so simple you might not know you need to hear this, but…. Apply! Don’t second guess yourself and send in your resume.\nToo often students worry they aren’t qualified or ready and we are here to tell you that you are! If you are hungry, humble, smart, and ready to grow with us... you are halfway in the door! The only way we can get to know you and possibly hire you is for you to apply.\nDon’t wait!'
      },
      {
        question: 'Can we email you?',
        answer: 'You are welcome and encouraged to reach out to us with any questions. Be sure to pick the email address in your region.\n' +
          'US: universityrecruitment.us@veeam.com\nEurope: universityrecruitment.emea@veeam.com'
      }
    ]
  }
}