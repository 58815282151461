import {defaultsEnum} from "../../global/types";

const codeCountryMap = {
    all: defaultsEnum.all,
    AFG: 'Afghanistan',
    ARM: 'Armenia',
    ALA: 'Åland Islands',
    ARE: 'United Arab Emirates',
    ARG: 'Argentina',
    AUS: 'Australia',
    AUT: 'Austria',
    BEL: 'Belgium',
    BGD: 'Bangladesh',
    BHR: 'Bahrain',
    BRA: 'Brazil',
    BTN: 'Bhutan',
    CAN: 'Canada',
    CHE: 'Switzerland',
    CHN: 'China',
    COL: 'Colombia',
    CZE: 'Czech Republic',
    CYP: 'Cyprus',
    DEU: 'Germany',
    DNK: 'Denmark',
    EGY: 'Egypt',
    ESP: 'Spain',
    FIN: 'Finland',
    FRA: 'France',
    FRO: 'Faroe Islands',
    GBR: 'United Kingdom',
    GRC: 'Greece',
    GRL: 'Greenland',
    HUN: 'Hungary',
    IND: 'India',
    IDN: 'Indonesia',
    IRN: 'Iran',
    IRQ: 'Iraq',
    ISL: 'Iceland',
    ISR: 'Israel',
    ITA: 'Italy',
    JOR: 'Jordan',
    JPN: 'Japan',
    KOR: 'South Korea',
    KWT: 'Kuwait',
    LBN: 'Lebanon',
    LKA: 'Sri Lanka',
    MEX: 'Mexico',
    MYS: 'Malaysia',
    MDV: 'Maldives',
    NLD: 'Netherlands',
    NOR: 'Norway',
    NPL: 'Nepal',
    NZL: 'New Zealand',
    OMN: 'Oman',
    PAK: 'Pakistan',
    PAN: 'Panama',
    PHL: 'Philippines',
    PSE: 'Palestine',
    THA: 'Thailand',
    QAT: 'Qatar',
    ROU: 'Romania',
    RUS: 'Russian Federation',
    SAU: 'Saudi Arabia',
    SGP: 'Singapore',
    SYR: 'Syrian Arab Republic',
    ZAF: 'South Africa',
    SWE: 'Sweden',
    TUR: 'Turkey',
    USA: 'United States',
    VNM: 'Vietnam',
    YEM: 'Yemen'
};

const countryCodeRegionCodeMap = {
    all: ['Global'],
    AFG: ['APJ', 'SEAK'],
    ALA: ['ALLEMEA', 'Nordics'],
    ARE: ['ALLEMEA', 'ME'],
    ARG: ['LATAM'],
    AUS: ['APJ', 'ANZ'],
    AUT: ['ALLEMEA', 'EMEA'],
    ARM: ['ALLEMEA'],
    BEL: ['ALLEMEA', 'EMEA'],
    BGD: ['APJ', 'SEAK'],
    BHR: ['ALLEMEA', 'ME'],
    BRA: ['LATAM'],
    BTN: ['APJ', 'SEAK'],
    CAN: ['NA'],
    CHE: ['ALLEMEA', 'EMEA'],
    CHN: ['APJ'],
    COL: ['LATAM'],
    CZE: ['ALLEMEA'],
    CYP: ['ALLEMEA', 'ME'],
    DEU: ['ALLEMEA', 'EMEA'],
    DNK: ['ALLEMEA', 'Nordics'],
    EGY: ['ALLEMEA', 'ME'],
    ESP: ['ALLEMEA', 'EMEA'],
    FIN: ['ALLEMEA', 'Nordics'],
    FRA: ['ALLEMEA', 'EMEA'],
    FRO: ['ALLEMEA', 'Nordics'],
    GBR: ['ALLEMEA', 'EMEA'],
    GRC: ['ALLEMEA', 'EMEA'],
    GRL: ['ALLEMEA', 'Nordics'],
    HUN: ['ALLEMEA', 'EMEA'],
    IDN: ['APJ'],
    IND: ['APJ', 'ANZ'],
    IRN: ['ALLEMEA', 'ME'],
    IRQ: ['ALLEMEA', 'ME'],
    ISL: ['ALLEMEA', 'Nordics'],
    ISR: ['ALLEMEA', 'ME'],
    ITA: ['ALLEMEA', 'EMEA'],
    JOR: ['ALLEMEA', 'ME'],
    JPN: ['APJ'],
    KOR: ['APJ', 'SEAK'],
    KWT: ['ALLEMEA', 'ME'],
    LBN: ['ALLEMEA', 'ME'],
    LKA: ['APJ', 'SEAK'],
    MEX: ['LATAM'],
    MYS: ['APJ'],
    MDV: ['APJ', 'SEAK'],
    NLD: ['ALLEMEA', 'EMEA'],
    NOR: ['ALLEMEA', 'Nordics'],
    NPL: ['APJ', 'SEAK'],
    NZL: ['APJ', 'ANZ'],
    OMN: ['ALLEMEA', 'ME'],
    PAK: ['APJ', 'SEAK'],
    PAN: ['LATAM'],
    PHL: ['APJ'],
    PSE: ['ALLEMEA', 'ME'],
    QAT: ['ALLEMEA', 'ME'],
    ROU: ['ALLEMEA', 'EMEA'],
    RUS: ['ALLEMEA', 'RUSCIS'],
    SAU: ['ALLEMEA', 'ME'],
    SGP: ['APJ'],
    SYR: ['ALLEMEA', 'ME'],
    SWE: ['ALLEMEA', 'Nordics'],
    THA: ['APJ'],
    TUR: ['ALLEMEA', 'ME'],
    USA: ['NA'],
    VNM: ['APJ'],
    ZAF: ['ALLEMEA', 'EMEA'],
    YEM: ['ALLEMEA', 'ME']
};

export class LocationsConverter {
    private codeCountryMap: {[key: string]: string};
    private countryCodeRegionCodeMap: {[key: string]: string[]};

    constructor() {
        this.codeCountryMap = codeCountryMap;
        this.countryCodeRegionCodeMap = countryCodeRegionCodeMap;
    }

    getCountryByCode(code: string): string {
        return this.codeCountryMap[code];
    }

    getRegionsByCountryCode(code: string): string[] {
        return this.countryCodeRegionCodeMap[code];
    }

    getCodeByCountry(country: string): string | undefined {
        return Object.keys(codeCountryMap)
            .find(code => this.codeCountryMap[code] === country);
    }
}
