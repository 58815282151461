import React, {useState} from 'react';
import {Carousel} from "react-bootstrap";
import AwardsEmployee from "../../components/awards-employee/awards-employee";
import employeeAwards from "../../mocks/mocks-employee-awards";
import VeeamEmployees from "../../components/veeam-employees/veeam-employees";
import {MocksDiGender, MocksDiGenderMob} from "../../mocks/mocks-di-gender";
import {Link} from "react-router-dom";
import './DiversityInclusion.scss'
import {COMMITMENTS, TEXT_BLOCKS} from "./DiversityInclusion.const";

const DiversityInclusion = (): JSX.Element => {
  const [isClickedPlay, setIsClickedPlay] = useState<boolean>(false)

  const handleClickPlay = () => {
    setIsClickedPlay(!isClickedPlay)
  }


  return (
      <>
        <div className={'bg-green main-banner'}>
            <img src="//img.veeam.com/careers/global/gender-site/banner_2.jpg"
                 className="img-fluid main-banner-image"
                 alt="banner"/>
          <div className="main-banner-title">
            <div className="main-banner-title-wrapper">
              <p className="main-banner-title-wrapper-middle">{TEXT_BLOCKS.banner.title}</p>
            </div>
          </div>
        </div>
        <div className="position-relative">
          <img className="img-gender-pattern img-gender-pattern-left"
               src="//img.veeam.com/careers/global/gender-site/left-pattern.svg"
               alt="left-pattern"/>
          <div className="container container-spacer">
            <div className="row justify-content-start">
              <div className="col-12">
                <h1 className="display-1">{TEXT_BLOCKS.commitment.title}</h1>
                <div className="container">
                  {COMMITMENTS.map((commitmentRow) => <div className="row mb-2">
                    {commitmentRow.map((commitment) => <div className="col-lg px-0 mb-4">
                      <div className="row">
                        <div className="col-2">
                          <img src={commitment.image} alt={commitment.alt}/>
                        </div>
                        <div className="col-10 d-flex align-items-center">
                          {commitment.title}
                        </div>
                      </div>
                    </div>)}
                  </div>)}
                </div>
              </div>
            </div>
          </div>
          <img className="img-gender-pattern img-gender-pattern-right"
               src="//img.veeam.com/careers/global/gender-site/right-pattern.svg" alt="right-pattern"/>
        </div>
        <div className="bg-light empowering">
          <div className="container container-spacer">
            <div className="row">
              <div className="col-12 col-md-5">
                <h2 className="display-2 mt-0">
                  {TEXT_BLOCKS.empowering.title}
                  <br/>
                  {TEXT_BLOCKS.empowering.subtitle}
                </h2>
                <p>{TEXT_BLOCKS.empowering.firstP}</p>
                <p>{TEXT_BLOCKS.empowering.secondP}</p>
              </div>
              <div className="col-12 col-md-7">
                <div className="d-xl-block">
                  {isClickedPlay ?
                      <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/M8GWp3VCgEg?autoplay=1"
                                title="Women in Green at Veeamon"
                                allow='autoplay'
                                allowFullScreen>
                        </iframe>
                      </div>
                       :
                      <div className="thumbnail">
                        <img src="//img.veeam.com/careers/global/gender-site/thumbnail.jpeg" className="img-fluid" alt={"thumbnail"}/>
                        <div className="thumbnail-play-outer" onClick={handleClickPlay}>
                          <div className="thumbnail-play-outer-button"></div>
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
            <div className="row d-sm-flex justify-content-sm-start">
              <div className="col-12 col-md-4">
              <Link to="/diversity-and-inclusion"
                    className="btn btn-primary mt-4">{TEXT_BLOCKS.empowering.button}</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="position-relative">
          <img className="img-gender-pattern img-gender-pattern-bottom"
               src="//img.veeam.com/careers/global/gender-site/bottom-cropped-true.png"
               alt="pattern-bottom"/>
          <div className="container container-spacer">
            <div className="col-12">
              <p className="display-2 mt-0 mb-4">{TEXT_BLOCKS.inspirational.title}</p>
              <p>{TEXT_BLOCKS.inspirational.subtitle}</p>
              <div className="row">
                <div className="col-12 d-none d-lg-block">
                  <Carousel>
                    {MocksDiGender.map((employee, index: number) =>
                        <Carousel.Item key={`values-carousel-item-${index}`}>
                          <div
                              className="card-deck card-deck__card-deck-shadowed d-flex flex-row flex-nowrap justify-content-center">
                            {employee.map((slide, index) =>
                                <VeeamEmployees employee={slide} key={`employee-${index}`}/>
                            )}
                          </div>
                        </Carousel.Item>
                    )}
                  </Carousel>
                </div>
                <div className="col-12 d-lg-none">
                  <Carousel>
                    {MocksDiGenderMob.map((employee, index: number) =>
                        <Carousel.Item key={`values-carousel-item-${index}`}>
                          <div
                              className="card-deck card-deck__card-deck-shadowed d-flex flex-row flex-nowrap justify-content-center">
                            <VeeamEmployees employee={employee} key={`employee-${index}`}/>
                          </div>
                        </Carousel.Item>
                    )}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container container-spacer">
          <h2 className="display-3 mt-0">{TEXT_BLOCKS.career.title}</h2>
          <div className="row">
            <div className="col-12 col-lg-8">
              <p>{TEXT_BLOCKS.career.motivation}</p>
            </div>
            <div className="col-sm-12 col-md-4 d-sm-flex justify-content-sm-end align-items-baseline">
              <Link to="/vacancies"
                 className="btn btn-primary mr-lg-5" >{TEXT_BLOCKS.career.button}</Link>
            </div>
          </div>
        </div>
        <div className="bg-partial bg-partial--light">
          <div className="container container-spacer">
            <div className="row block-spacer mt-0">
              <div className="col-12 col-xl-5 d-md-row d-flex flex-column justify-content-center">
                <div className="header-with-image">
                  <h2 className="h2">{TEXT_BLOCKS.awards.title}</h2>
                  <img src="//img.veeam.com/careers/global/img/about/awards.png" alt="award"
                       className="img-fluid "/>
                </div>
                <p>{TEXT_BLOCKS.awards.slogan}</p>
              </div>
              <div className="col-12 col-xl-7">
                <AwardsEmployee awards={employeeAwards}/>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

export default DiversityInclusion;
