import React from 'react';
import axios from 'axios';
import {IAskQeustion, IBaseContext} from "../../types";

export interface ITalentManagersContext extends IBaseContext<IAskQeustion[]> {}

const contextDefaultValue = {
    data: [{
        name: 'Jessica',
        secondname: 'Sherer',
        img: '//img.veeam.com/careers/global/questions/sherer_jessica.png',
        position: 'Recruiter',
        areas: null,
        regions: ['Global', 'NA', 'LATAM'],
        subregions: null,
        countries: null,
        profilelink: 'https://www.linkedin.com/in/jessica-sherer-80a376/',
        default: true,
        rule: null
    }],
    isLoading: false,
    error: null,
    isCompleted: false
}

export const TalentManagersContext = React.createContext<ITalentManagersContext>(contextDefaultValue);

interface ITalentManagersProviderProps {
    children: JSX.Element[] | JSX.Element;
}

interface ITalentManagersProviderState extends ITalentManagersContext {}

class TalentManagersProvider extends React.Component<ITalentManagersProviderProps, ITalentManagersProviderState> {
    constructor(props: any) {
        super(props);
        this.state = contextDefaultValue;
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
            isCompleted: false,
        });

        axios.get('/api/talent-managers').then(res => {
            this.setState({
                data: res.data || [],
                isLoading: false,
                isCompleted: true
            });
        })
    }

    public render() {
        return (
            <TalentManagersContext.Provider value={this.state}>
                {this.props.children}
            </TalentManagersContext.Provider>
        )
    }
}

export default TalentManagersProvider;
