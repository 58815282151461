import React from 'react';
import {Carousel, Nav, Row, Tab} from 'react-bootstrap';
import VeeamValues from '../../components/veeam-values/veeam-values';
import values from '../../mocks/mocks-values';
import {lifeContent, lifeNav} from '../../mocks/mocks-life';
import {Link} from 'react-router-dom';
import grows from '../../mocks/mocks-grows';
import cares from '../../mocks/mocks-cares';
import focus from '../../mocks/mocks-focus';
import VeeamFocus from '../../components/veeam-focus/veeam-focus';
import learnMore from '../../../cz/mocks/learn-more';
import VacanciesList from '../../components/vacancies-list/vacancies-list';
import VideoPlayer from '../../components/video/video';
import CarouselComponent from '../../components/carousel/carousel';
import growsMob from '../../mocks/mocks-grows-mob';
import caresMob from '../../mocks/mocks-cares-mob';
import pageMenu from '../../mocks/mocks-page-menu';
import Scrollspy from 'react-scrollspy';
import valuesMob from "../../mocks/mocks-values-mob";

interface IShowContentState {
    isVisible: boolean;
    isActiveStories: number;
    isActiveExperience: number;
}


class Life extends React.Component<{}, IShowContentState> {
    private targetRef: any;

    constructor(props: any) {
        super(props);
        this.targetRef = React.createRef();

        this.state = {
            isVisible: false,
            isActiveStories: 1,
            isActiveExperience: 1
        };

        this.handleShowContent = this.handleShowContent.bind(this);
    }

    componentWillMount() {
        if (window.location.hash === '#cares') {

            this.setState({
                isActiveStories: 2
            })
        } else {
            this.setState({
                isActiveStories: 1
            })
        }

        if (window.location.hash === '#what-it-means') {
            this.setState({
                isActiveExperience: 3
            })
        } else if (window.location.hash === '#experience') {
            this.setState({
                isActiveExperience: 4
            })
        } else {
            this.setState({
                isActiveExperience: 1
            })
        }

        const script1 = document.createElement("script");
        const script2 = document.createElement("script");

        script1.src = "//fast.wistia.com/embed/medias/qo013wxf3b.jsonp";
        script1.async = true;

        script2.src = "//fast.wistia.com/assets/external/E-v1.js";
        script2.async = true;

        document.body.appendChild(script1);
        document.body.appendChild(script2);
    }

    handleShowContent() {
        this.setState({
            isVisible: !this.state.isVisible
        })
    }

    public render() {

        return (
            <React.Fragment>
                <div className="page-menu-wrapper">
                    <div className="page-menu">
                        <Scrollspy items={pageMenu[0]} currentClassName="is-active">
                                <li><a href="#life">Life at Veeam</a></li>
                                <li><a href="#veeam-values">Veeam values</a></li>
                                <li><a href="#ourVeemers">Our Veeamers</a></li>
                                <li><a href="#global-focus">Global focus</a></li>
                                <li><a href="#become-veeamer">Become a Veeamer</a></li>
                        </Scrollspy>
                    </div>
                </div>
                <div className="d-block d-xl-none wistia_embed wistia_async_8nxna6pel1 videoFoam=true"/>
                <div className="d-none d-xl-block wistia_embed wistia_async_8nxna6pel1"
                    style={{height:"800px"}}
                />
                <div className="container container-spacer" id="life">
                    <a href="" id="what-it-means"/>
                    <a href="" id="experience"/>
                    <h1 className="display-1">Life at Veeam</h1>
                    <div className="tabs-wrapper">
                        <Tab.Container id="tabs" defaultActiveKey={this.state.isActiveExperience}>
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <Nav className="card card-scroll border-0 mb-0 flex-row flex-md-column flex-nowrap">
                                        {lifeNav.map((nav, index:number) =>
                                            <Nav.Item key={`life-nav-${index}`}>
                                                <Nav.Link eventKey={nav.index}
                                                          className="justify-content-end font-weight-stronger">
                                                    {nav.nav.split(' ').map((navItem, index:number) =>
                                                        <span className="pl-2" key={`life-nav-item-${index}`} dangerouslySetInnerHTML={{__html: navItem}}/>
                                                    )}
                                                </Nav.Link>
                                            </Nav.Item>
                                        )}

                                    </Nav>
                                </div>
                                <div className="col-12 col-md-8">
                                    <Tab.Content>
                                        {lifeContent.map((content, index:number) =>
                                            <Tab.Pane eventKey={content.index} key={`life-tab-content-${index}`}>
                                                <div dangerouslySetInnerHTML={{__html: content.content}}/>

                                                {/*{content.content}*/}
                                            </Tab.Pane>
                                        )}
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
                <div className="container-wrapper" id="veeam-values">
                    <div className="bg-dark">
                        <span className="bg-pattern-left pattern-on-dark"/>
                        <span className="bg-pattern-right pattern-on-dark"/>
                        <div className="container container-spacer text-center fixed-height">
                            <h2 className="h1">Our Veeam Values</h2>
                            <h5 className="mb-0 position-relative">We live and breathe our values. Because they encourage behaviors that
                                never fail to breed success. We believe in our power to do good, through our products
                                and community work,
                                and being the passionate Veeamers we are, these values have come from all of us.
                                Our voices shaped them, and their influence focuses us.</h5>
                        </div>
                    </div>
                    <div className="container container-slider">
                        <div className="row">
                            <div className="col-12 d-none d-lg-block">
                                <div className="slider-wrapper">
                                    <Carousel>
                                        {values.map((value, index: number) =>
                                            <Carousel.Item key={`values-carousel-item-${index}`}>
                                                <div
                                                    className="card-deck card-deck__card-deck-shadowed d-flex flex-row flex-nowrap justify-content-center">
                                                    {value.map((item, index) =>
                                                        <VeeamValues values={item} key={index}/>
                                                    )}
                                                </div>
                                            </Carousel.Item>
                                        )}
                                    </Carousel>
                                </div>
                            </div>
                            <div className="col-12 d-lg-none">
                                <div className="slider-wrapper">
                                    <Carousel>
                                        {valuesMob.map((item, index) =>
                                        <Carousel.Item>
                                            <div
                                                className="card-deck card-deck__card-deck-shadowed d-flex flex-row flex-nowrap justify-content-center">

                                                    <VeeamValues values={item} key={index}/>

                                            </div>
                                        </Carousel.Item>
                                        )}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-partial bg-partial--light" id="ourVeemers">
                    <div className="container container-spacer">
                        <div className="row block-spacer mt-0">
                            <div className="col-12 col-md-4">
                                <h2 className="h1 mt-0 text-md-right">Our Veeamers</h2>
                            </div>
                            <div className="col-12 col-md-8"></div>
                            <div className="col-12  col-md-4">
                                <h2 className="text-md-right mt-0">Do something Veeamazing</h2>
                            </div>
                            <div className="col-12 col-md-8">
                                <p>Ever since introducing Veeam to the world in 2006, we’ve set our sights on the top.
                                    We summoned the speed, talent and fighting spirit to become the global leader in
                                    Modern Data Protection. But even though we’ve come this far in a short space of time,
                                    our people maintain the hunger to put distance between us and the competition – and
                                    there are more than 5,000 of us working hard at it worldwide. If you share our
                                    desire to win and strive for purpose in your career, the future of Modern Data Protection is calling you.</p>
                                <p>Our desire to do great things with the Cloud is clear for all our clients to see –
                                    with truly innovative solutions that govern, protect, automate and analyze data. A
                                    Veeamer radiates genuine passion – whether you’re supporting our award-winning
                                    software, selling our market-leading backup products or embarking on a growth-filled
                                    start to your career. Our team will bring out the best in you. We stand together and
                                    speak from the heart. So you’ll learn what Veeamazing looks like in no time. And
                                    become part of our vibrant, ambitious team. </p>
                                <Link to="/about" className="btn btn-success">find out more about us</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <a id="grows"/><a id="cares"/>

                            </div>
                            <div className="col-12 block-spacer">
                                <Tab.Container id="tabs-employees" defaultActiveKey={this.state.isActiveStories}>
                                    <Row>
                                        <div className="col-12 col-md-4">
                                            <h2 className="mt-0 text-md-right">People Stories</h2>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="1">
                                                    <h4 className="mt-3">Meet our people whose drive, passion and pure determination is leading to growth and success in their careers</h4>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="2">
                                                    <h4 className="mt-3">Meet our people unleashing the transformative power of volunteering on the world and enriching themselves as people</h4>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-12 col-md-4">
                                            <Nav className="flex-md-column flex-nowrap">
                                                <Nav.Item className="text-right nav-item--z-index">
                                                    <Nav.Link eventKey="1">
                                                        <span>Grows</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="text-right nav-item--z-index">
                                                    <Nav.Link eventKey="2">
                                                        <span>Cares</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="1">
                                                    <CarouselComponent carouselItemDesk={grows}
                                                                       carouselItemMob={growsMob}/>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="2">
                                                    <CarouselComponent carouselItemDesk={cares}
                                                                       carouselItemMob={caresMob}/>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container container-spacer" id="global-focus">
                    <h2 className="h1">Our Veeamazing Culture</h2>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-none d-md-flex card-deck justify-content-md-between">
                                {focus.map((focusItem, index: number) =>
                                    <VeeamFocus focus={focusItem} key={`focus-item-${index}`}/>
                                )}
                            </div>
                            <div className="d-flex d-md-none flex-column">
                                <div className="card-deck justify-content-md-between">

                                    {!this.state.isVisible ? <VeeamFocus focus={focus[0]}/> :
                                        focus.map((focusItem, index:number) =>
                                            <VeeamFocus focus={focusItem} key={`focus-item-m-${index}`}/>
                                        )
                                    }
                                </div>
                                <button className="btn btn-outline-success"
                                        onClick={this.handleShowContent}>{!this.state.isVisible ? 'Show more' : 'Show less'}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-light">
                    <div className="container container-spacer">
                        <h2>Become a Veeamer</h2>
                        <div className="row mb-5 mb-md-0" id="become-veeamer">
                            <div
                                className="col-12 d-flex flex-column flex-md-row justify-content-md-between align-items-start">
                                <h3 className="mt-0">Explore our opportunities</h3>
                                <Link to={"/vacancies"} className="btn btn-link btn-link__cta">See all</Link>
                            </div>
                        </div>

                        <div className="card-deck card-deck-vacancies">
                            <VacanciesList limit={4} learnMoreData={learnMore} searchBlock={false}/>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

// @ts-ignore
export default Life;
