import React from 'react';
import {IVeeamFocus} from '../../types';

interface IVeeamFocusProps {
    focus: IVeeamFocus;
}

class VeeamFocus extends React.Component<IVeeamFocusProps> {
    public render() {
        let focus = this.props.focus;

        return (
            <div className="card card-focus border-0">
                <div className="card-header px-0 justify-content-start">
                    <img src={focus.img} alt="" className="img-fluid"/>
                </div>
                <div className="card-body px-0 pb-0">
                    <p>{focus.copy}</p>
                </div>
            </div>
        )
    }
}

export default VeeamFocus;
