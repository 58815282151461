import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {ThemeContext} from '../../shared/components.shared/components/providers/theme.context';
import ThemeProvider from '../../shared/components.shared/components/providers/theme.provider';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Homepage from '../pages/homepage/homepage';
import Life from '../pages/life/life';
import axios from "axios";
import VacanciesProvider from '../components/providers/vacancies.provider';
import ScrollToTop from '../../shared/components.shared/components/scroll-to-top/scroll-to-top';
import BackToTop from '../components/back-to-top/back-to-top';
import {Container} from 'react-bootstrap';
import PeoplegrowsProvider from './providers/peoplegrows.provider';
import StoriesGrowsAll from './sories-grows/stories-grows-all';
import PeopleCaresProvider from './providers/peoplecares.provider';
import StoriesCaresAll from './stories-cares/stories-cares-all';
import Opportunities from '../pages/opportunitites/opportunities';
import Vacancy from '../pages/vacancy/vacancy';
import About from '../pages/about/about';
import AwardsProvider from './providers/awards.provider';
import UserLocationProvider, { UserLocationContext } from "./providers/userlocation.provider";
import TalentManagersProvider from "./providers/talentmanagers.provider";
import Vacancies from "../pages/vacancies/vacancies";
import OfficesProvider from "../../shared/components.shared/components/providers/offices.provider";
import DepartmentsProvider from "../../shared/components.shared/components/providers/departments.provider";
import ErrorBoundary from './error-boundary/error-boundary';
import DiVeeam from "../pages/diversityInclusion/diveeam";
import DiversityInclusion from '../pages/diversityInclusion/DiversityInclusion'
import NotFound from "../../shared/components.shared/components/not-found/not-found";
import StudentsAndGraduates from "../pages/students-graduates/StudentsAndGraduates";
import InternshipProgram from "../pages/students-graduates/program/InternshipProgram";
import GraduateProgram from "../pages/students-graduates/program/GraduateProgram";

axios.defaults.headers['Content-Type'] = 'application/json';
if (process.env.NODE_ENV !== 'production') {
    axios.defaults.baseURL = 'http://localhost:8080';
}


const AppGlobal: React.FC = () => {
    return(

            <UserLocationProvider>
                <UserLocationContext.Consumer>
                    {
                        (userLocation) =>
                            userLocation.isCompleted &&
                            <TalentManagersProvider>
                                <VacanciesProvider>
                                    <DepartmentsProvider>
                                        <PeopleCaresProvider>
                                            <PeoplegrowsProvider>
                                                <AwardsProvider>
                                                    <OfficesProvider>
                                                        <ThemeProvider>
                                                            <ThemeContext.Consumer>
                                                                {
                                                                    (theme) =>
                                                                        <div className="content-body">
                                                                            <Header transparentMenu={theme.transparentMenu}/>
                                                                            <Container className="container-main" fluid={true}>
                                                                                <ErrorBoundary>
                                                                                    <Switch>
                                                                                        <Route path="/" exact>
                                                                                            <Homepage />
                                                                                        </Route>
                                                                                        <Route path="/grows/:alias" exact component={StoriesGrowsAll}/>
                                                                                        <Route path="/cares/:alias" exact component={StoriesCaresAll}/>
                                                                                        <Route path="/life" exact component={Life}/>
                                                                                        <Route path="/opportunities" exact component={Opportunities}/>
                                                                                        <Route path="/about" exact component={About}/>
                                                                                        <Route path="/vacancies" exact component={Vacancies} />
                                                                                        <Route path="/vacancies/:department/:alias" component={Vacancy}/>
                                                                                        <Route path="/diversity-and-inclusion" component={DiVeeam}/>
                                                                                        <Route path="/women-at-veeam" component={DiversityInclusion}/>
                                                                                        <Route
                                                                                          path="/students-and-graduates"
                                                                                          render={({ match: { url } }) => (
                                                                                            <Switch>
                                                                                              <Route path={`${url}/`} component={StudentsAndGraduates} exact />
                                                                                              <Route path={`${url}/internship`} component={InternshipProgram} exact />
                                                                                              <Route path={`${url}/graduate`} component={GraduateProgram} exact />
                                                                                              <Route path={`${url}/*`} component={NotFound} />
                                                                                            </Switch>
                                                                                          )}
                                                                                        />
                                                                                        <Route path="*" component={NotFound}/>
                                                                                    </Switch>
                                                                                </ErrorBoundary>
                                                                            </Container>
                                                                            <Footer userLocation={userLocation.data}></Footer>
                                                                            <ScrollToTop/>
                                                                            <BackToTop/>
                                                                        </div>
                                                                }
                                                            </ThemeContext.Consumer>
                                                        </ThemeProvider>
                                                    </OfficesProvider>
                                                </AwardsProvider>
                                            </PeoplegrowsProvider>
                                        </PeopleCaresProvider>
                                    </DepartmentsProvider>
                                </VacanciesProvider>
                            </TalentManagersProvider>
                    }
                </UserLocationContext.Consumer>
            </UserLocationProvider>

    )
};

export default AppGlobal;
