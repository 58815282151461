import React from 'react';
import {HashLink as Link, HashLinkProps} from 'react-router-hash-link';

interface IShufflePeopleStoriesProps {
    people: {
    tag: string,
    title: string,
    subtitle: string,
    img: string,
    employee: string,
    link: string
}

}

class ShufflePeopleStories extends React.Component<IShufflePeopleStoriesProps, HashLinkProps> {

    render() {
        let people = this.props.people;

        return (
            <Link smooth to="/life/#cares"
                className="card card-no-hover card-homepage card-homepage--stories card-banner bg-dark "

            >

                <div className="card-header" dangerouslySetInnerHTML={{__html: people.tag}}/>
                <div className="card-body">
                    <img src="//img.veeam.com/careers/global/cards/cares/heart.png" alt="" className="img fluid"/>
                </div>
                <div className="card-footer flex-column">
                    <h3 className="display-3 mb-3">{people.title}</h3>
                    <h4 className="w-80 my-0">{people.subtitle}</h4>
                </div>
            </Link>
        )
    }
}

export default ShufflePeopleStories
