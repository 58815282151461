import React from 'react';
import {HashLink} from 'react-router-hash-link';


interface ILifeAtVeeamProps {
    details: {
        tag: string,
        title: any,
        subtitle?: any,
        link: string,
        type: string
    }
}

class ShuffleLifeAtVeeam extends React.Component<ILifeAtVeeamProps> {
    public render() {
        let details = this.props.details;

        let bgColor = details.type === 'culture' ? 'bg-info card-homepage--culture' :
            details.type ==='courses' ? 'bg-info card-homepage--courses' :
            details.type ==='vibes' ? 'bg-green card-homepage--vibes' :
            details.type ==='community' ? 'bg-green card-homepage--vibes' :
            details.type === 'values' ? 'bg-green card-homepage--values' :
            details.type === 'opportunities' ? 'bg-info card-homepage card-homepage--opportunities' :
            details.type === 'diveeam' ? 'bg-dark card-homepage card-homepage--diveeam' : 'bg-green';

        return (
            <HashLink target={details.type === 'opportunities' ? '_blank' : ''}
                smooth to={details.link} className={`card card-no-hover card-banner card-homepage ${bgColor}`}>
                <div className="card-header" dangerouslySetInnerHTML={{__html: details.tag}} />
                <div className="card-body">
                    <span dangerouslySetInnerHTML={{__html: details.title}} />
                    <span dangerouslySetInnerHTML={{__html: details.subtitle}}/>
                </div>
            </HashLink>
        )
    }
}


export default ShuffleLifeAtVeeam;
