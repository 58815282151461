import React from 'react';


class ShuffleGridSettings extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="grid-sizer"></div>
            </React.Fragment>
        )
    }
}


export default ShuffleGridSettings;
