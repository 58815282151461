const focus = [
    {
        img: '//img.veeam.com/careers/global/focus/focus-grows.svg',
        copy: 'We are dedicated to your career growth and offer plenty of resources and additional courses. Last year alone Veeamers spent 5000+ hours on our online learning platform. Combine this with training, mentoring, big projects, and equally big clients, you\'ll gain a fast-tracked route into the position you want. ',
    },
    {
        img: '//img.veeam.com/careers/global/focus/focus-wellness.svg',
        copy: 'Whether it\'s your mental, physical or occupational health, Wellness is important to us — because when you\'re well, you can enjoy what you do, achieve great things, and really get the most out of your potential. Work life balance is a key part of our culture and setting boundaries that your team mates respect is just as important.',
    },
    {
        img: '//img.veeam.com/careers/global/focus/focus-vibes.svg',
        copy: 'Veeamers love coming together to network and connect. Whether it\'s just for fun or we\'re doing it for personal and professional development, we like to keep things lively, inclusive and supportive. Our regular Vibes events are an opportunity to expand your social circle, meet new colleagues and connect with other departments.',
    },
    {
        img: '//img.veeam.com/careers/global/focus/focus-cares.svg',
        copy: 'Every Veeamer gets 24 hours of leave every year to volunteer. We believe everyone should have more opportunities to give back to the community on behalf of our future-focused business. In 2021, more than 3,000 hours were taken globally by our employees in order to give back!'
    },
];

export default focus;
