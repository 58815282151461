import React from 'react';

const NotFound: React.FC = () => {
    return (
        <div className="container container-spacer-lg">
            <div className="row">
                <div className="col-12">
                    <h1>404</h1>
                    <p>Page <strong>{window.location.pathname}</strong> was not found.</p>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
