import React from 'react';
import {Carousel} from 'react-bootstrap';


interface ICarouselProps {
    index?: any;
}

class CarouselImpression extends React.Component<ICarouselProps>{

    constructor(props: any) {
        super(props);
        this.state = {
            active: 0,
        };
        this.setACtive = this.setACtive.bind(this);
    }

    setACtive(index: number) {
        this.setState(state => ({
            active: index,
        }))
    }


    render() {

        return (

            <Carousel className="carousel carousel-xl">
                <Carousel.Item>
                    <div className="card-deck">
                        <div className="card card-md-45 border-0">
                            <div className="card-header  px-0 px-md-5 pr-md-0"><span
                                className="d-flex flex-lg-column flex-row align-items-center align-items-lg-start"><span
                                className="icon-circle icon-circle--green mb-0 mb-lg-5">
                                    <img src="//img.veeam.com/careers/global/icons/research.svg" alt=""/>
                                </span>
                                <h3 className="my-0 ml-4 ml-lg-0 flex-3">Do your research</h3></span>
                            </div>
                            <div className="card-body p-0 px-md-5 pr-md-0">
                                <p>Don’t just read the first page of the website. That’s a rookie error. Check out our press releases on the corporate site, uncover recent news surrounding our growth or leadership team, and dig a little deeper into our company values. We love people who appreciate our culture.</p>
                            </div>
                        </div>
                        <div className="card card-md-45 border-0">
                            <div className="card-body p-0 px-md-5 pr-md-0">
                                <img src="//img.veeam.com/careers/global/pickpeople/interview.png" alt="" className="ing-fluid"/>
                            </div>
                        </div>
                        <div className="card card-md-45 border-0">
                            <div className="card-header px-0 px-md-5 pr-md-0"><span
                                className="d-flex flex-lg-column flex-row align-items-center align-items-lg-start"><span
                                className="icon-circle icon-circle--green mb-0 mb-lg-5">
                                    <img src="//img.veeam.com/careers/global/icons/linkedin.svg" alt=""/>
                                </span>
                                <h3 className="my-0 ml-4 ml-lg-0 flex-3">Check us out on LinkedIn</h3></span>
                            </div>
                            <div className="card-body p-0 px-md-5 pr-md-0">
                                <p>If you have the names of the managers you’ll be interviewing with, look them up on LinkedIn and find out if you have anything in common. We really don’t mind that level of initiative!</p>
                            </div>
                        </div>
                        <div className="card card-md-45 border-0">
                            <div className="card-header px-0 px-md-5 pr-md-0"><span
                                className="d-flex flex-lg-column flex-row align-items-center align-items-lg-start"><span
                                className="icon-circle icon-circle--green mb-0 mb-lg-5">
                                    <img src="//img.veeam.com/careers/global/icons/questions.svg" alt=""/>
                                </span>
                                <h3 className="my-0 ml-4 ml-lg-0 flex-3">Ask great questions</h3></span>
                            </div>
                            <div className="card-body p-0 px-md-5 pr-md-0">
                                <p>We embrace talkers. There should be a feeling that you’re interviewing us too, so don’t keep quiet. We may say “ask great questions”, but there’s no such thing as a foolish question either. If you don’t know something, ask. Demonstrate a burning desire to learn and be better.</p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="card-deck">
                        <div className="card card-md-45 border-0">
                            <div className="card-header px-0 px-md-5 pr-md-0"><span
                                className="d-flex flex-lg-column flex-row align-items-center align-items-lg-start"><span
                                className="icon-circle icon-circle--green mb-0 mb-lg-5">
                                    <img src="//img.veeam.com/careers/global/icons/dress.svg" alt=""/>
                                </span>
                                <h3 className="my-0 ml-4 ml-lg-0">Dress for success</h3></span>
                            </div>
                            <div className="card-body p-0 px-md-5 pr-md-0">
                                <p>First impressions matter. Whether your interview is in-person or online via video, show us you’re a professional by being dressed like one. </p>
                            </div>
                        </div>
                        <div className="card card-md-45 border-0">
                            <div className="card-body p-0 px-md-5 pr-md-0">
                                <img src="//img.veeam.com/careers/global/pickpeople/interview.png" alt="" className="ing-fluid"/>
                            </div>
                        </div>
                        <div className="card card-md-45 border-0">
                            <div className="card-header px-0 px-md-5 pr-md-0"><span
                                className="d-flex flex-lg-column flex-row align-items-center align-items-lg-start"><span
                                className="icon-circle icon-circle--green mb-0 mb-lg-5">
                                    <img src="//img.veeam.com/careers/global/icons/prepared.svg" alt=""/>
                                </span>
                                <h3 className="my-0 ml-4 ml-lg-0">Be prepared</h3></span>
                            </div>
                            <div className="card-body p-0 px-md-5 pr-md-0">
                                <p>Better to be overprepared than underprepared, that’s our motto. Bring enough hard copies of your resume for each manager interviewing you. Bring a pen and a notepad. Try to imagine every possible scenario and make sure you have the answers.</p>
                            </div>
                        </div>
                        <div className="card card-md-45 border-0">
                            <div className="card-header px-0 px-md-5 pr-md-0"><span
                                className="d-flex flex-lg-column flex-row align-items-center align-items-lg-start"><span
                                className="icon-circle icon-circle--green mb-0 mb-lg-5">
                                    <img src="//img.veeam.com/careers/global/icons/person-presentation.svg" alt=""/>
                                </span>
                                <h3 className="my-0 ml-4 ml-lg-0">Get your story straight</h3>
                            </span>
                            </div>
                            <div className="card-body p-0 px-md-5 pr-md-0">
                                <p>Look at the job description and identify the aspects of your experience, qualifications and ambition that matches it most strongly. Think of where you’ve performed brilliantly in previous roles, and remember, it’s your job to make us see why that’s relevant.</p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="card-deck">
                        <div className="card card-md-45 border-0">
                            <div className="card-header px-0 px-md-5 pr-md-0"><span
                                className="d-flex flex-lg-column flex-row align-items-center align-items-lg-start"><span
                                className="icon-circle icon-circle--green mb-0 mb-lg-5">
                                <img src="//img.veeam.com/careers/global/icons/ask-job.svg" alt=""/>
                                </span>
                                <h3 className="my-0 ml-4 ml-lg-0">Ask for the job</h3></span>
                            </div>
                            <div className="card-body p-0 px-md-5 pr-md-0">
                                <p>Tell us why you want the job! And why we should give it to you. It all boils down to passion. If we think you genuinely want to be a Veeamer, that will work massively in your favour. So don’t be afraid to speak from the heart.</p>
                            </div>
                        </div>
                        <div className="card card-md-45 border-0">
                            <div className="card-body p-0 px-md-5 pr-md-0">
                                <img src="//img.veeam.com/careers/global/pickpeople/interview.png" alt="" className="img-fluid"/>
                            </div>
                        </div>
                        <div className="card card-md-45 border-0">
                            <div className="card-header px-0 px-md-5 pr-md-0"><span
                                className="d-flex flex-lg-column flex-row align-items-center align-items-lg-start"><span
                                className="icon-circle icon-circle--green mb-0 mb-lg-5">
                                <img src="//img.veeam.com/careers/global/icons/email.svg" alt=""/>
                                </span>
                                <h3 className="my-0 ml-4 ml-lg-0">Strong follow up</h3></span>
                            </div>
                            <div className="card-body p-0 px-md-5 pr-md-0">
                                <p>Grab the contact information of everyone you meet. If you think of any questions you forgot to ask, or even left out a story you wish you told, email us within the next 24hours. We’re happy to consider it and we’ll know that you’re still thinking about us.</p>
                            </div>
                        </div>
                        <div className="card card-md-45 border-0">
                            <div className="card-header px-0 px-md-5 pr-md-0"><span
                                className="d-flex flex-lg-column flex-row align-items-center align-items-lg-start"><span
                                className="icon-circle icon-circle--green mb-0 mb-lg-5">
                                    <img src="//img.veeam.com/careers/global/icons/practice.svg" alt=""/>
                                </span>
                                <h3 className="my-0 ml-4 ml-lg-0">Practice makes perfect</h3>
                            </span>
                            </div>
                            <div className="card-body p-0 px-md-5 pr-md-0">
                                <p>If you’re unsure about your interview technique, practice with a friend or mentor. You may not think it works at first, but uttering your story out loud beforehand means it’s fresh in your mind – and comes out in the right order on the day of the interview.</p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>

        )
    }
};

export default CarouselImpression;
