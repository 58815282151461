import React from 'react';
import {RouteComponentProps} from 'react-router';
import NotFound from '../../../shared/components.shared/components/not-found/not-found';
import StoriesCaresCristina from './stories-cares-cristina';
import StoriesCaresVicky from './stories-cares-vicky';
import {PeopleCaresContext} from '../providers/peoplecares.context';
import StoriesCaresAlina from './stories-cares-alina';
import StoriesCaresAdriana from './stories-cares-adriana';
import StoriesCaresBert from './stories-cares-bert';
import StoriesCaresCandace from './stories-cares-candace';
import StoriesCaresGracia from './stories-cares-gracia';
import StoriesCaresJonathan from './stories-cares-jonathan';

interface ICaresAllProps extends RouteComponentProps <{
    alias: string
}> {}

class StoriesCaresAll extends React.Component<ICaresAllProps> {

    static contextType = PeopleCaresContext;
    context! : React.ContextType<typeof PeopleCaresContext>;

    componentDidUpdate(prevProps: ICaresAllProps) {
        if (prevProps.match.params.alias !== this.props.match.params.alias) {
            window.scrollTo(0, 0);
        }
    }

    public render() {
        let alias = this.props.match.params.alias;
        let story = this.context && this.context.data && this.context.data.find(item => item.name === alias);
        let component = <NotFound/>;

        if(story) {
            switch (this.props.match.params.alias) {
                case 'adriana' : component=<StoriesCaresAdriana story={story}/>; break;
                case 'alina' : component=<StoriesCaresAlina story={story}/>; break;
                case 'bert' : component=<StoriesCaresBert story={story}/>; break;
                case 'candace' : component=<StoriesCaresCandace story={story}/>; break;
                case 'cristina' : component=<StoriesCaresCristina story={story}/>; break;
                case 'gracia' : component=<StoriesCaresGracia story={story}/>; break;
                case 'jonathan' : component=<StoriesCaresJonathan story={story}/>; break;
                /*case 'kerry' : component=<StoriesCaresKerry story={story}/>; break;*/
                case 'vicky' : component=<StoriesCaresVicky story={story}/>; break;


            }
        }

        return (
            component

        )
    }
}

export default StoriesCaresAll;


