import React from 'react';
import {Carousel} from 'react-bootstrap';
import VeeamEmployees from '../veeam-employees/veeam-employees';
import {IVeeamEmployees} from '../../types';


interface ICarouselItemProps {
    carouselItemDesk?: IVeeamEmployees[][];
    carouselItemMob?: IVeeamEmployees[];
    carouselItemAll?: IVeeamEmployees[][];
}


class CarouselComponent extends React.Component<ICarouselItemProps> {

    public render() {
        let carouselItemMob = this.props.carouselItemMob;
        let carouselItemDesk = this.props.carouselItemDesk;

        return (
            <React.Fragment>
                <Carousel className={carouselItemDesk ? "carousel-lg d-none d-lg-block" : "carousel-lg"}>
                    {carouselItemDesk&&carouselItemDesk.map((employee, index) =>
                        <Carousel.Item key={`carousel-item-${index}`}>
                            <div className="card-deck card-deck-slider card-deck__card-deck-shadowed d-flex flex-row flex-nowrap justify-content-start justify-content-md-center">
                                {employee.map((slide, index) =>
                                    <VeeamEmployees employee={slide} key={`employee-${index}`}/>
                                )}
                            </div>
                        </Carousel.Item>
                    )}
                </Carousel>
                <Carousel className="carousel-lg d-block d-lg-none">
                    {carouselItemMob&&carouselItemMob.map((item, index) =>
                        <Carousel.Item key={`carousel-item-m-${index}`}>
                            <div className="card-deck card-deck-slider d-flex flex-row flex-nowrap justify-content-start justify-content-md-center">
                                    <VeeamEmployees employee={item} key={`employee-m-${index}`}/>
                            </div>
                        </Carousel.Item>
                )}
                </Carousel>
            </React.Fragment>

        )
    }
}

export default CarouselComponent;
