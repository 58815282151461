import React from 'react';
import {IAwards} from '../../types'

export type IAwardsContext = {
    data: IAwards[],
    isLoading: boolean,
    isCompleted: boolean,
    error: null | string
}

export const AwardsContext = React.createContext<IAwardsContext | null>({
    data: [],
    isLoading: false,
    error: null,
    isCompleted: false
});
