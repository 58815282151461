const employeeAwards = [
    {
        award: "Dangvy Keller on CRN’s 2022 Women of the Channel Power 100 List",
        img: "//img.veeam.com/careers/global/page_di/awards/power_100.jpeg"
    },
    {
        award: "16 Women Named to CRN’s 2022 Women of the Channel List",
        img: "//img.veeam.com/careers/global/page_di/awards/women_2022.jpeg"
    },
    {
        award: "Female Employee of the Year – Business ",
        img: "//img.veeam.com/careers/global/page_di/awards/steve.png"
    },
    {
        award: "Channel Asia Women in ICT Awards 2021 (Singapore)",
        img: "//img.veeam.com/careers/global/page_di/awards/asia.png"
    },
    {
        award: "ARN Women in ICT 2021 (Australia)",
        img: "//img.veeam.com/careers/global/page_di/awards/arn.png"
    },
    {
        award: "Twelve Featured on CRN’s 2021 Women of the Channel List ",
        img: "//img.veeam.com/careers/global/page_di/awards/crn.png"
    }
];


export default employeeAwards;
