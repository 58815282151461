import React from 'react';
import {Link} from 'react-router-dom';
import {IVacancy} from '../../../shared/types';

interface IVacancyListItemProps {
    vacancy: IVacancy
}


interface IVacancyFormatted {
    city: string;
    country: string;
}
class VacancyListItem extends React.Component<IVacancyListItemProps> {

    static setUrl() {
        let query: any;
        let param: string;
        let paidCampaign: string;
        let medium: string;

        query = window.location.search.slice(1).split('&');

        query = query.map(function(entry:string) {
            let key = entry.split('=')[0];
            let value = entry.split('=')[1];
            let container:any = {};
            container[key] = value;

            return container;
        });

        query = Object.assign.apply(null, query);
        paidCampaign = query['utm_campaign'];
        medium = query['utm_medium'];

        if(!query['utm_source'] && !query['utm_medium'] && !query['utm_campaign']) {
            param = 'utm_source=careers.veeam.com';
        } else if(query && paidCampaign && medium) {
            param = `utm_medium=${query['utm_medium']}&utm_source=${query['utm_source']}&utm_campaign=${query['utm_campaign']}`;
        } else {
            param = `utm_source='${query['utm_source']}`;
        }

        return param;
    }

    public render() {
        let vacancy = this.props.vacancy;
        let titleIcon = vacancy.isHot ? 'icon icon-bg-alert' : '';
        let titleClassName = vacancy.isHot ? 'text-warning' : 'text-font';

        let formattedLocations = vacancy.location.map((location, index, arr) => {
            return {
                city: arr.filter(item => item.country === location.country).length > 2 ? 'Multiple locations' : location.city,
                country: location.country
            }
        }).reduce((acc: IVacancyFormatted[], loc:IVacancyFormatted) => {
            let found = acc.find((item) => item.city === loc.city);
            if (!found) {
                acc.push(loc);
            }
            return acc;
        }, []);

        return (
            <Link to={`/vacancies/${vacancy.department.alias}/${vacancy.routeAlias}?${VacancyListItem.setUrl()}`}
                  className="card card-md-45 card-no-hover card--shadowed">
                <div className="card-header align-items-center pb-0">
                    {titleIcon === `` ? `` : <span className={titleIcon}></span>}
                    <span className={titleClassName}>{vacancy.department.title}</span>
                </div>
                <div className="card-body pb-0 pt-4">
                    <h5 className="header-custom-spacer-md text-font font-weight-strong">{vacancy.title}</h5>
                </div>
                <div className="card-footer pt-0 d-block">
                    {
                        formattedLocations.map((item) =>
                            <span className="location">
                                    <span className="icon icon-bg-globe_green d-none d-xl-flex"></span>
                                    <span
                                        className="text-placeholder-pressed">{item.city && item.city.charAt(0).toUpperCase() + item.city.slice(1)} / {item.country} </span>
                            </span>)
                    }
                    {/*<Link className="btn btn-link btn-link-cta mt-4 mt-sm-0 py-0" data-vacancy={vacancy.title} to={`/vacancies/${vacancy.department.alias}/${vacancy.routeAlias}`}>Learn more</Link>*/}
                </div>
            </Link>
        );
    }
}

export default VacancyListItem;
