import {IVeeamEmployees} from '../types'

export const MocksDiGender: IVeeamEmployees[][] = [[
  {
    img: '//img.veeam.com/careers/global/gender-site/ebony_king_1.png',
    name: 'Ebony King',
    title: 'Inside Sales Manager, US',
    quote: '«What I love most about Veeam is that I feel valued for the contributions I bring to my peers, team, and company. As a minority leader, I feel seen and heard. That is the culture of Veeam, and for me, it makes work feel less like work and more like purpose.»',
    link: '',
    type: 'digender'
  },
  {
    img: '//img.veeam.com/careers/global/gender-site/valentina_1.png',
    name: 'Valentina Ugai',
    title: 'QA Development Team Leader, Prague',
    quote: '«As a mentor at Veeam, I strive to assist other people, including women, in growing professionally, in order for us to achieve our common goals together. As we [women] often struggle to be seen or recognised, I believe that mentoring them and helping them build confidence, contributes to their success and the success of the company.»',
    link: '',
    type: 'digender'
  },
  {
    img: '//img.veeam.com/careers/global/gender-site/tan_1.png',
    name: 'YenFen Tan',
    title: 'Inside Sales Associate, APJ',
    quote: '«As a working mother at Veeam, I am proud of the flexibility and support from managers I receive. I’ve been able to progress within my career, stay relevant, all while maintaining strong mental health and juggling the demands at home for my family.»',
    link: '',
    type: 'digender'
  },
  ],
  [
    {
      img: '//img.veeam.com/careers/global/gender-site/cecilia_1.png',
      name: 'Cecilia Melinte',
      title: 'Associate Manager Renewals France Strategic, Romania',
      quote: '«At Veeam, I feel like my voice is heard. I am a wellness enthusiast, and I found the ground to contribute to our inclusive culture by escalating wellness to greater heights. I promote strategies to enhance physical, mental, and financial well-being through empowerment to others, which allows me to be purposeful at work.»',
      link: '',
      type: 'digender'
    },{
    img: '//img.veeam.com/careers/global/gender-site/sara_1.png',
    name: 'Sarah Wilson',
    title: 'Sr. Director, Sales Channel, US',
    quote: '«As a woman leader at Veeam, I bring a different perspective. One of my biggest strengths is being able to lead with emotional intelligence while maintaining a strategic vision on the business. I aim to be an agent for positive change and to lead others in a clear, time-specific, and well-communicated strategy.»',
    link: '',
    type: 'digender'
  },
    {
      img: '//img.veeam.com/careers/global/gender-site/pam_1.png',
      name: 'Pamela Napier',
      title: 'Senior Manager – Cloud, UK/I',
      quote: '«Being a leader in Veeam is really rewarding. I have amazing support for all across the business and the best part is, through Veeam’s mentoring program and coaching, I can pass this on to the next future leaders.»',
      link: '',
      type: 'digender'
    }
  ]
]

export const MocksDiGenderMob: IVeeamEmployees[] = [
  {
    img: '//img.veeam.com/careers/global/gender-site/ebony_king_1.png',
    name: 'Ebony King',
    title: 'Inside Sales Manager, US',
    quote: '«What I love most about Veeam is that I feel valued for the contributions I bring to my peers, team, and company. As a minority leader, I feel seen and heard. That is the culture of Veeam, and for me, it makes work feel less like work and more like purpose.»',
    link: '',
    type: 'digender'
  },
  {
    img: '//img.veeam.com/careers/global/gender-site/valentina_1.png',
    name: 'Valentina Ugai',
    title: 'QA Development Team Leader, Prague',
    quote: '«As a mentor at Veeam, I strive to assist other people, including women, in growing professionally, in order for us to achieve our common goals together. As we [women] often struggle to be seen or recognised, I believe that mentoring them and helping them build confidence, contributes to their success and the success of the company.»',
    link: '',
    type: 'digender'
  },
  {
    img: '//img.veeam.com/careers/global/gender-site/tan_1.png',
    name: 'YenFen Tan',
    title: 'Inside Sales Associate, APJ',
    quote: '«As a working mother at Veeam, I am proud of the flexibility and support from managers I receive. I’ve been able to progress within my career, stay relevant, all while maintaining strong mental health and juggling the demands at home for my family.»',
    link: '',
    type: 'digender'
  },
  {
    img: '//img.veeam.com/careers/global/gender-site/cecilia_1.png',
    name: 'Cecilia Melinte',
    title: 'Associate Manager Renewals France Strategic, Romania',
    quote: '«At Veeam, I feel like my voice is heard. I am a wellness enthusiast, and I found the ground to contribute to our inclusive culture by escalating wellness to greater heights. I promote strategies to enhance physical, mental, and financial well-being through empowerment to others, which allows me to be purposeful at work.»',
    link: '',
    type: 'digender'
  },
  {
    img: '//img.veeam.com/careers/global/gender-site/sara_1.png',
    name: 'Sarah Wilson',
    title: 'Sr. Director, Sales Channel, US',
    quote: '«As a woman leader at Veeam, I bring a different perspective. One of my biggest strengths is being able to lead with emotional intelligence while maintaining a strategic vision on the business. I aim to be an agent for positive change and to lead others in a clear, time-specific, and well-communicated strategy.»',
    link: '',
    type: 'digender'
  },
  {
    img: '//img.veeam.com/careers/global/gender-site/pam_1.png',
    name: 'Pamela Napier',
    title: 'Senior Manager – Cloud, UK/I',
    quote: '«Being a leader in Veeam is really rewarding. I have amazing support for all across the business and the best part is, through Veeam’s mentoring program and coaching, I can pass this on to the next future leaders.»',
    link: '',
    type: 'digender'
  }
]