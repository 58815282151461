import React, { ChangeEvent } from "react"
import {
  ISelectItem,
  IVacanciesListFilter,
  IVacancy,
  IVacancyDepartment,
  IVacancyLocation,
} from "../../../shared/types"
import { DepartmentsContext } from "../../../shared/components.shared/components/providers/departments.context"
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import { defaultsEnum, IUserLocation } from "../../types"
import ScrollArea from "react-scrollbar"
import { Form } from "react-bootstrap"

interface IVacanciesListFilterProps {
  list: IVacancy[]
  initFilter?: IVacanciesListFilter
  searchQuery: string
  userLocation: IUserLocation

  setSearchQuery(q: string): void

  onChange(name: string, value: any): void
}

class VacanciesListFilter extends React.Component<
  IVacanciesListFilterProps,
  IVacanciesListFilter
> {
  static contextType = DepartmentsContext
  context!: React.ContextType<typeof DepartmentsContext>
  private initFilter: {}

  constructor(props: IVacanciesListFilterProps) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.mapDepartmentToOption = this.mapDepartmentToOption.bind(this)
    this.resetFilter = this.resetFilter.bind(this)
    this.applyFilter = this.applyFilter.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.applyFilterOnQuery = this.applyFilterOnQuery.bind(this)

    let preFilter: any = {
      ...this.props.initFilter,
    }

    if (
      this.props.initFilter &&
      !this.props.initFilter.location &&
      this.props.userLocation.country
    ) {
      preFilter.location = this.props.userLocation.country
    }

    this.state = {
      query: this.props.searchQuery,
      department: undefined,
      languages: undefined,
      location: undefined,
      state: undefined,
      city: undefined,
      dirty: false,
      ...preFilter,
    }

    this.initFilter = {
      query: "",
      department: defaultsEnum.all,
      location: this.props.userLocation.country,
      state: defaultsEnum.all,
      city: defaultsEnum.all,
    }
  }

  public render() {
    let departmentOptions =
        (this.context && this.context.data.map(this.mapDepartmentToOption)) ||
        [],
      locationOptions: ISelectItem[] = [],
      statesOptions: { [key: string]: ISelectItem[] } = {},
      citiesOptions: { [key: string]: ISelectItem[] } = {}

    departmentOptions.unshift(this.setAllOption("department"))
    locationOptions.unshift(this.setAllOption("location"))
    statesOptions[defaultsEnum.all] = [this.setAllOption("states")]
    citiesOptions[defaultsEnum.all] = [this.setAllOption("city")]

    this.props.list.forEach((vacancy: IVacancy) => {
      vacancy.department.title =
        vacancy.department.title === "Technical Documentation Development"
          ? "Technical Writing"
          : vacancy.department.title

      vacancy.location.forEach((location: IVacancyLocation) => {
        location.city = location.city === "SP" ? "Sao Paulo" : location.city

        let locationString = this.locationToString(location),
          locationIsExist = locationOptions.some((item: ISelectItem) => {
            return locationString === item.value
          }),
          locationWithStates = location.state && location.state !== null,
          stateIsDuplicate =
            statesOptions[locationString] &&
            statesOptions[locationString].some(
              (state) => location.state === state.value
            ),
          cityIsDuplicate =
            citiesOptions[locationString] &&
            citiesOptions[locationString].some(
              (city) => location.city === city.value
            )

        if (!locationIsExist) {
          locationOptions.push(this.mapLocationToOption(location))
        }

        if (!stateIsDuplicate) {
          if (!statesOptions[locationString]) {
            statesOptions[locationString] = [this.setAllOption("states")]
          }
          statesOptions[locationString].push(this.mapStateToOption(location))
        }

        if (!cityIsDuplicate) {
          if (!citiesOptions[locationString]) {
            citiesOptions[locationString] = [this.setAllOption("city")]
          }
          citiesOptions[locationString].push(this.mapCityToOption(location))

          if (locationWithStates) {
            if (location.state && !citiesOptions[location.state]) {
              citiesOptions[location.state] = [this.setAllOption("city")]
            }
            location.state &&
              citiesOptions[location.state].push(this.mapCityToOption(location))
          }
        }
      })

      if (vacancy.department.alias) {
        let isDuplicate = departmentOptions.some(
          (department) => vacancy.department.alias === department.value
        )

        if (!isDuplicate) {
          departmentOptions.push(this.mapDepartmentToOption(vacancy.department))
        }
      }
    })

    let activeRegion: any =
      this.state.location && !!citiesOptions[this.state.location]
        ? this.state.location
        : defaultsEnum.all
    let activeState: any =
      this.state.location && !!statesOptions[this.state.location]
        ? this.state.location
        : defaultsEnum.all
    // @ts-ignore
    // let usActiveState: any =
    //   this.state.state && !!citiesOptions[this.state.state]
    //     ? this.state.state
    //     : defaultsEnum.all

    // if(this.state.location === 'USA' && this.state.state) {
    //     activeRegion = usActiveState;
    // }

    departmentOptions.forEach((department) => {
      department.count = this.props.list.reduce(
        (acc: number, vacancy: IVacancy) => {
          let country =
            Array.isArray(vacancy.location) &&
            vacancy.location[0] &&
            vacancy.location[0].country
              ? vacancy.location[0].country
              : ""
          if (
            [vacancy.department.alias, "all"].includes(department.value) &&
            [country, "all"].includes(activeRegion)
          ) {
            acc++
          }
          return acc
        },
        0
      )
    })

    this.sortBy(departmentOptions, "count")
    this.sortBy(locationOptions, "label")

    Object.keys(citiesOptions).forEach((key) =>
      this.sortBy(citiesOptions[key], "label")
    )
    Object.keys(statesOptions).forEach((key) =>
      this.sortBy(statesOptions[key], "label")
    )

    return (
      <header className="bg-white">
        <div className="container container-spacer-lg">
          <div className="vacancies-filter">
            <div className="form-group d-md-flex">
              <label className="vacancies-filter__label" htmlFor="">
                Hi! I'm looking for{" "}
              </label>
              <Form.Control
                type="search"
                className={
                  this.state.query !== ""
                    ? "dropdown-toggle text-primary"
                    : "dropdown-toggle"
                }
                placeholder="enter job title or skill"
                value={this.state.query}
                onChange={this.handleChange}
                onKeyDown={this.onKeyDown}
              />
            </div>
            <div className="form-group d-md-flex">
              <label className="vacancies-filter__label" htmlFor="">
                in
              </label>
              <DropdownButton
                id="department-toggler"
                variant="link"
                title={this.getActiveOption(
                  departmentOptions,
                  this.state.department,
                  true
                )}
              >
                <ScrollArea
                  speed={0.8}
                  className="area"
                  contentClassName="content"
                  horizontal={true}
                >
                  {departmentOptions.map((option, index: number) => (
                    <Dropdown.Item
                      key={`dep-opt-${index}`}
                      eventKey={option.value}
                      onSelect={(value: any) =>
                        this.handleSelectChange("department", value)
                      }
                    >
                      {option.label}
                    </Dropdown.Item>
                  ))}
                </ScrollArea>
              </DropdownButton>
            </div>
            <div className="form-group d-md-flex">
              <label className="vacancies-filter__label" htmlFor="">
                in
              </label>
              <Dropdown>
                <DropdownButton
                  id="city-toggler"
                  variant="link"
                  title={this.getActiveOption(
                    locationOptions,
                    this.state.location,
                    true
                  )}
                >
                  <ScrollArea
                    speed={0.8}
                    className="area"
                    contentClassName="content"
                    horizontal={true}
                  >
                    {locationOptions.map((option, index: number) => (
                      <Dropdown.Item
                        key={`loc-opt-${index}`}
                        eventKey={option.value}
                        onSelect={(value: any) =>
                          this.handleSelectChange("location", value)
                        }
                      >
                        {option.label}
                      </Dropdown.Item>
                    ))}
                  </ScrollArea>
                </DropdownButton>
              </Dropdown>
              {this.state.location !== "USA" ? (
                <>
                  <span className="vacancies-filter__label d-none d-lg-inline-block">
                    &#44;
                  </span>
                  <Dropdown>
                    <DropdownButton
                      id="city-toggler"
                      variant="link"
                      title={this.getActiveOption(
                        citiesOptions[activeRegion],
                        this.state.city
                      )}
                    >
                      <ScrollArea
                        speed={0.8}
                        className="area"
                        contentClassName="content"
                        horizontal={true}
                      >
                        {citiesOptions[activeRegion].map(
                          (option, index: number) => (
                            <Dropdown.Item
                              key={`city-opt-${index}`}
                              eventKey={option.value}
                              onSelect={(value: any) =>
                                this.handleSelectChange("city", value)
                              }
                            >
                              {option.label}
                            </Dropdown.Item>
                          )
                        )}
                      </ScrollArea>
                    </DropdownButton>
                  </Dropdown>
                </>
              ) : (
                ""
              )}
            </div>
            {this.state.location === "USA" ? (
              <div className="form-group d-md-flex">
                <div className="form-group d-md-flex">
                  <label className="vacancies-filter__label" htmlFor="">
                    in
                  </label>
                  <Dropdown>
                    <DropdownButton
                      id="city-toggler"
                      variant="link"
                      title={this.getActiveOption(
                        statesOptions[activeState],
                        this.state.state
                      )}
                    >
                      <ScrollArea
                        speed={0.8}
                        className="area"
                        contentClassName="content"
                        horizontal={true}
                      >
                        {statesOptions["USA"] &&
                          statesOptions["USA"].map((option, index: number) => (
                            <Dropdown.Item
                              key={`loc-opt-${index}`}
                              eventKey={option.value}
                              onSelect={(value: any) =>
                                this.handleSelectChange("state", value)
                              }
                            >
                              {option.label}
                            </Dropdown.Item>
                          ))}
                      </ScrollArea>
                    </DropdownButton>
                  </Dropdown>
                </div>

                <span className="vacancies-filter__label d-none d-lg-inline-block">
                  &#44;
                </span>
                <Dropdown>
                  <DropdownButton
                    id="city-toggler"
                    variant="link"
                    title={this.getActiveOption(
                      citiesOptions[activeRegion],
                      this.state.city
                    )}
                  >
                    <ScrollArea
                      speed={0.8}
                      className="area"
                      contentClassName="content"
                      horizontal={true}
                    >
                      {citiesOptions[activeRegion].map(
                        (option, index: number) => (
                          <Dropdown.Item
                            key={`city-opt-${index}`}
                            eventKey={option.value}
                            onSelect={(value: any) =>
                              this.handleSelectChange("city", value)
                            }
                          >
                            {option.label}
                          </Dropdown.Item>
                        )
                      )}
                    </ScrollArea>
                  </DropdownButton>
                </Dropdown>
              </div>
            ) : (
              ""
            )}
            <div className="form-group d-flex flex-column flex-lg-row justify-content-center justify-content-md-end align-items-md-center">
              <p className="vacancies-filter__cta mb-lg-0 mr-md-5 text-center text-lg-right">
                ...to be part of the elite in Modern Data Protection
              </p>
              <button
                className="btn btn-outline-success"
                onClick={this.applyFilter}
              >
                Find a career
              </button>
              {this.state.dirty && (
                <button
                  type="button"
                  className="btn btn-link d-flex justify-content-center align-items-center mt-3 mt-xl-0"
                  onClick={this.resetFilter}
                >
                  <span className="btn btn-close pt-0 pr-2" />
                  Clear all filters
                </button>
              )}
            </div>
          </div>
        </div>
      </header>
    )
  }

  protected mapDepartmentToOption(department: IVacancyDepartment): ISelectItem {
    department.title =
      department.title === "Technical Documentation Development"
        ? "Technical Writing"
        : department.title
    let count = 0
    return this.generateSelectItem(department.alias, department.title, count)
  }

  protected mapLocationToOption(
    location: IVacancyLocation,
    forceState: boolean = false
  ): ISelectItem {
    // @ts-ignore
    let loc = forceState ? location.country : this.locationToString(location)
    return this.generateSelectItem(loc, loc)
  }

  protected mapStateToOption(location: IVacancyLocation): ISelectItem {
    // @ts-ignore
    return this.generateSelectItem(location.state, location.state)
  }

  protected mapCityToOption(location: IVacancyLocation): ISelectItem {
    return this.generateSelectItem(location.city, location.city)
  }

  protected applyFilterOnQuery() {
    if (this.state.query && this.state.query !== " ") {
      this.applyFilter()
    }
  }

  protected onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      event.preventDefault()
      event.stopPropagation()
      this.applyFilter()
    }
  }

  protected handleChange(e: any) {
    e.preventDefault()
    let name = "query"
    let value = e.target.value

    this.setState({
      ...this.state,
      [name]: value,
      dirty: true,
    })

    /*if (name === 'query') {
            this.props.setSearchQuery(value);
        }*/

    //this.props.onChange(name, value);
  }

  protected handleSelectChange(name: string, value: any) {
    let data = { [name]: value }
    if (name === "location") {
      data.city = data.state = defaultsEnum.all
    }

    if (name === "state") {
      data.city = defaultsEnum.all
    }

    this.setState({
      ...this.state,
      ...data,
      dirty: true,
    })
    //this.props.onChange(name, value);
  }

  protected locationToString(location: IVacancyLocation) {
    return [location.country].join(", ")
  }

  protected stateToString(location: IVacancyLocation) {
    return [location.state].join(", ")
  }

  protected resetFilter(): void {
    this.setState({
      ...this.state,
      ...this.initFilter,
      dirty: false,
    })

    for (let prop in this.initFilter) {
      // @ts-ignore
      this.props.onChange(prop, this.initFilter[prop])
    }
  }

  protected applyFilter() {
    this.props.onChange("query", this.state.query)
    this.props.onChange("location", this.state.location)
    this.props.onChange("state", this.state.state)
    this.props.onChange("city", this.state.city)
    this.props.onChange("department", this.state.department)
  }

  protected sortBy(
    array: { sort: (arg0: (a: any, b: any) => 1 | -1 | 0) => void },
    fieldName: string
  ) {
    array.sort((a, b) => {
      if (b.value === defaultsEnum.all) {
        return 1
      }

      if (
        !isFinite(b[fieldName] - a[fieldName]) &&
        a[fieldName] < b[fieldName]
      ) {
        return -1
      } else if (
        isFinite(b[fieldName] - a[fieldName]) &&
        b[fieldName] < a[fieldName]
      ) {
        return -1
      }

      return 0
    })
  }

  protected getActiveOption(
    options: ISelectItem[],
    value: any,
    needToSlice?: boolean,
    defaultValue?: string
  ): string {
    needToSlice = needToSlice || false
    defaultValue = defaultValue || defaultsEnum.all

    let defaultOption = options.find((opt) => opt.value === defaultValue),
      activeOption =
        options.find((opt: ISelectItem) => opt.value === value) ||
        defaultOption ||
        options[0]

    return activeOption.label.length > 19 && needToSlice
      ? activeOption.label.slice(0, 19) + " ..."
      : activeOption.label
  }

  protected setAllOption(field: string): ISelectItem {
    // @ts-ignore
    let label = defaultsEnum[field + "All"]

    return this.generateSelectItem(defaultsEnum.all, label)
  }

  protected generateSelectItem(
    value: string,
    label: string,
    count?: number
  ): ISelectItem {
    return {
      value: value,
      label: label,
      count: count,
    }
  }

  componentDidMount(): void {
    this.applyFilterOnQuery()
  }

  componentDidUpdate(prevProps: IVacanciesListFilterProps) {
    if (this.props.searchQuery !== prevProps.searchQuery) {
      this.props.onChange("query", this.props.searchQuery)
    }
  }
}

export default VacanciesListFilter
