import React, {ComponentClass} from 'react';
import shuffledItems from '../../mocks/mocks-shuffle';
import Masonry, {MasonryPropTypes} from 'react-masonry-component';
import ShuffleAboutUs from '../../components/shuffle-about-us/shuffle-about-us';
import ShufflePeopleStories from '../../components/shuffle-people-stories/shuffle-people-stories';
import ShuffleLifeAtVeeam from '../../components/shuffle-life-at-veeam/shuffle-life-at-veeam';
import ShuffleBannerSlider from '../../components/shuffle-banner-slider/shuffle-banner-slider';
import ShuffleVideo from '../../components/shuffle-video/shuffle-video';
import ShuffleGridSettings from '../../components/shuffle-gridSettings/shuffle-gridSettings';
import ShuffleGraduates from "../../components/shuffle-graduates/shuffle-graduates";

interface IHomepageState {
    items: any[];
}

class Homepage extends React.Component<any, IHomepageState> {
    masonryOptions = {
        columnWidth: '.grid-sizer',
        itemSelector: '.card-homepage:not(.slider-inner):not(.static-banner)',
        gutter: 26,
        horizontalOrder: true,
        transitionDuration: '0.3s',
        percentPosition: true
    };

    constructor(props: any) {
        super(props);
        this.state = {
            items: shuffledItems,
        };

        this.fetchItems = this.fetchItems.bind(this);
        this.updateItems = this.updateItems.bind(this);
    }

    updateItems() {
        this.fetchItems().then((items) => {
            this.setState({items});
        })
    }

    fetchItems(): Promise<any[]> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(shuffleArrayFrom(shuffledItems, 2));
            }, 30)
        })
    }

    componentWillMount() {
        this.updateItems();
    }

    getShuffleTemplate(item?: any, index?: number): any {
        return {
            slider: <ShuffleBannerSlider  key={`item-${index}`}/>,
            gridSettings: <ShuffleGridSettings key={`item-${index}`}/>,
            lifeAtVeeam: <ShuffleLifeAtVeeam details={item} key={`item-${index}`}/>,
            aboutUs: <ShuffleAboutUs details={item} key={`item-${index}`}/>,
            peopleStories: <ShufflePeopleStories people={item} key={`item-${index}`}/>,
            graduates: <ShuffleGraduates graduates={item} key={`item-${index}`}/>,
            video: <ShuffleVideo key={`item-${index}`}/>,
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="bg-light">
                    <div className="container-fluid container-fluid__fixed container-spacer py-5">
                        <Masonry className="card-homepage-wrapper"
                            options = {this.masonryOptions}
                        >
                            {this.state.items.map((item, index) =>
                                this.getShuffleTemplate(item, index)[item.widget]
                            )}
                        </Masonry>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function shuffleArrayFrom(unshuffled: any[], from: number) {
    const shuffled = unshuffled.slice(from).sort(() => 0.5 - Math.random());
    return unshuffled.slice(0, from).concat(shuffled);
}

export default Homepage;
