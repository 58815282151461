import React from 'react';
import {IVeeamValues} from '../../types';

interface IVeeamValuesProps {
    values: IVeeamValues;
}

class VeeamValues extends React.Component<IVeeamValuesProps> {
    public render() {
        let values = this.props.values;

        return (
            <div className="card card-values">
                <div className="card-header px-0 pb-0 justify-content-center">
                    <img src={values.img} alt="" className="img-fluid"/>
                </div>
                <div className="card-body">
                    <div dangerouslySetInnerHTML={{__html: values.values}} />
                </div>
            </div>
        )
    }
}

export default VeeamValues;
