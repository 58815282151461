import React from 'react';
import {HashLink as Link} from "react-router-hash-link";

interface IGraduatesProps {
    graduates: {
        tag: string,
        title: string,
        subtitle: '',
        link: '',
        img: string,
        name: string,
    }
}

class ShuffleGraduates extends React.Component<IGraduatesProps> {
    render() {
        let graduates = this.props.graduates;

        return (
            <Link smooth to="/opportunities/#graduates"
                  className="card card-no-hover card-homepage card-homepage--graduates card-banner bg-green">
                <div className="card-header" dangerouslySetInnerHTML={{__html: graduates.tag}}/>
                <div className="card-body">
                    <h3 className="display-3 mb-3" dangerouslySetInnerHTML={{__html: graduates.title}}/>
                    <h4 className="w-80 my-0" dangerouslySetInnerHTML={{__html: graduates.subtitle}}/>
                </div>
                <div className="card-footer flex-column mt-5">
                    <img src="//img.veeam.com/careers/global/cards/about-us/graduates.png" alt="" className="img fluid"/>
                </div>
            </Link>
        )
    }
}


export default ShuffleGraduates;
