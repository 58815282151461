import React from 'react';
import {ThemeContext} from './theme.context';
import qs from "qs";

interface IThemeProviderProps {
    children: JSX.Element[] | JSX.Element
}

interface IThemeProviderState {
    transparentMenu: boolean,
    searchQuery: string,
}

class ThemeProvider extends React.Component<IThemeProviderProps, IThemeProviderState> {

    constructor(props: IThemeProviderProps) {
        super(props);
        let queryParams = qs.parse(window.location.search, {
            ignoreQueryPrefix: true
        });
        this.state = {
            transparentMenu: false,
            searchQuery: queryParams.query || ''
        };
        this.setMenuTransparency = this.setMenuTransparency.bind(this);
        this.setSearchQuery = this.setSearchQuery.bind(this);
    }

    setMenuTransparency(value: boolean): void {
        this.setState({
            ...this.state,
            transparentMenu: value
        })
    }

    setSearchQuery(q: string): void {
        this.setState({
            ...this.state,
            searchQuery: q
        })
    }

    public render() {
        let value = {
            transparentMenu: this.state.transparentMenu,
            setMenuTransparency: this.setMenuTransparency,
            searchQuery: this.state.searchQuery,
            setSearchQuery: this.setSearchQuery
        };
        return (
            <ThemeContext.Provider value={value}>
                {this.props.children}
            </ThemeContext.Provider>
        )
    }
}

export default ThemeProvider;