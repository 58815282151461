import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index-global.scss';
import AppGlobal from './global/components/app'
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-NW4QW7'
};

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
} else {
    // production code
    TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
    <Router>
        <Switch>
            <Route path="/">
                <AppGlobal/>
            </Route>
        </Switch>
    </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
