import React from "react"
import { Link } from "react-router-dom"
import VacanciesCount from "../vacancies-count/vacancies-count"
import DepartmentsCount from "../../../shared/components.shared/components/departments-count/departments-count"
import {
  IVacancy,
  IVacancyDepartment,
  IVacancyLocation,
  IVacancyTag,
} from "../../../shared/types"
import {
  IVacanciesContext,
  VacanciesContext,
} from "../../../shared/components.shared/components/providers/vacancies.context"

interface ISidebannerVacancies {
  department?: IVacancyDepartment
  location: IVacancyLocation[]
  tag?: IVacancyTag
}

class SidebannerVacancies extends React.Component<ISidebannerVacancies> {
  protected renderDepartmentResult() {
    return (
      <React.Fragment>
        <div className="card-body">
          <span className="display-2">Open jobs:</span>
          <br />
          <strong className="display-number">
            <VacanciesContext.Consumer>
              {(vacanciesContext) => (
                <VacanciesCount
                  vacancies={vacanciesContext.data}
                  departmentAlias={
                    this.props.department && this.props.department.alias
                  }
                  location={this.props.location}
                />
              )}
            </VacanciesContext.Consumer>
          </strong>
          <br />
          {(this.props.department && (
            <span className="display-3">
              in {this.props.department.title} department
            </span>
          )) || (
            <VacanciesContext.Consumer>
              {(departmentsContext) => (
                <span className="display-3">
                  in
                  <strong className="text-secondary pl-2">
                    <DepartmentsCount departments={departmentsContext.data} />
                  </strong>{" "}
                  departments
                </span>
              )}
            </VacanciesContext.Consumer>
          )}
        </div>
        <div className="card-footer justify-content-end">
          <Link to={this.getVacanciesLink(1)} className="btn btn-secondary">
            All jobs
          </Link>
        </div>
      </React.Fragment>
    )
  }

  protected renderTagResult(vacanciesContext: IVacanciesContext) {
    let tag = this.props.tag
    let vacancies = vacanciesContext.data.filter((vacancy) => {
      return (
        vacancy.isVisible &&
        vacancy.homepageTags.some(
          (item: IVacancyTag) =>
            tag && item.type === tag.type && item.value === tag.value
        )
      )
    })
    let vacanciesCount = vacancies.length
    let departments = vacancies.reduce(
      (result: IVacancyDepartment[], vacancy: IVacancy) => {
        let found = result.find(
          (department: IVacancyDepartment) =>
            department.alias === vacancy.department.alias
        )
        if (!found) {
          result.push(vacancy.department)
        }
        return result
      },
      []
    )
    let departmentsCount = departments.length
    return (
      <React.Fragment>
        <div className="card-body">
          <span className="display-2">Open jobs:</span>
          <br />
          <strong className="display-number">
            <span className="text-secondary">{vacanciesCount}</span>
          </strong>
          <br />
          {departmentsCount > 1 && (
            <span className="display-3">
              in <strong className="text-secondary">{departmentsCount}</strong>{" "}
              departments
            </span>
          )}
          {departmentsCount === 1 && (
            // @ts-ignore
            <span className="display-3">
              in {[departments[0].title]} department
            </span>
          )}
        </div>
        <div className="card-footer justify-content-end">
          <Link
            to={this.getVacanciesLink(departmentsCount)}
            className="btn btn-secondary"
          >
            All jobs
          </Link>
        </div>
      </React.Fragment>
    )
  }

  protected getVacanciesLink(departmentsCount: number): string {
    let vacanciesListLink = "/vacancies?experience=all"
    if (this.props.department && departmentsCount === 1) {
      vacanciesListLink += `&department=${this.props.department.alias}&location=${this.props.location[0].country}`
    }
    if (
      this.props.tag &&
      this.props.tag.type === "department" &&
      departmentsCount === 1
    ) {
      vacanciesListLink += `&department=${this.props.tag.value}`
    } else if (this.props.tag && this.props.tag.type !== "department") {
      vacanciesListLink += `&tag=${this.props.tag.type}:${this.props.tag.value}`
    }
    return vacanciesListLink
  }
  public render() {
    return (
      <Link
        to={this.getVacanciesLink(1)}
        className="card card-no-hover card-banner card-banner-main bg-dark mb-3 mb-lg-0"
      >
        {(this.props.tag && (
          <VacanciesContext.Consumer>
            {(context) => this.renderTagResult(context)}
          </VacanciesContext.Consumer>
        )) ||
          this.renderDepartmentResult()}
      </Link>
    )
  }
}

export default SidebannerVacancies
