import React from 'react';
import {IPeopleStories} from '../../types'

export type IPeopleCaresContext = {
    data: IPeopleStories[],
    isLoading: boolean,
    isCompleted: boolean,
    error: null | string
}

export const PeopleCaresContext = React.createContext<IPeopleCaresContext | null>({
    data: [],
    isLoading: false,
    error: null,
    isCompleted: false
});
