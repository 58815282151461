import React from 'react';

interface IPickPeopleProps {
    people: {
        number?: string,
        count?: string,
        arrow?: string,
        img?:string
        title?: string,
        body?: string
    },
    countArrow?: string,
}
class PickPeople extends React.Component<IPickPeopleProps> {

    public render() {
        let people = this.props.people;
        let countArrow = people.count ? `arrow arrow--${people.count}` : `arrow arrow--img`;

        return (
        <div className={countArrow}>
            <div className="card border-0 mb-0">

                <div className="card-header px-0 px-md-5 pr-md-0">
                    {people.number && people.number ?
                        <span className="d-flex flex-lg-column flex-row align-items-center align-items-lg-start">
                            <span className="icon-circle icon-circle--green mb-0 mb-lg-5">
                                <strong className="display-4"><em>{people.number}</em></strong>
                            </span>
                             <h3 className="my-0 ml-4 ml-lg-0">{people.title}</h3>
                        </span> :
                        <img className="img-fluid" src={people.img} alt=""/>
                    }
                </div>

                {people.img ? '' :
                    <div className="card-body py-0 px-0 px-md-5 pr-md-0">
                        <p>{people.body}</p>
                    </div>
                }
            </div>
        </div>
        )
    }
}

export default PickPeople;
