import React from "react"
import VacancyListItem from "../vacancy-list-item/vacancy-list-item"
import {
  IVacanciesContext,
  VacanciesContext,
} from "../../../shared/components.shared/components/providers/vacancies.context"
import Fuse from "fuse.js"
import {
  ISelectItem,
  IVacanciesListFilter,
  IVacancy,
  IVacancyLanguage,
  IVacancyLocation,
  IVacancyTag,
  ILearnMore,
} from "../../../shared/types"
import { UserLocationContext } from "../providers/userlocation.provider"
import ApplyBlocks from "../apply-blocks/apply-blocks"
import blocks from "../../mocks/mocks-apply-block"

interface IVacanciesListProps {
  limit?: number
  departmentAlias?: string | null
  excludedVacancy?: IVacancy | null
  filter?: IVacanciesListFilter
  tag?: IVacancyTag | null
  learnMoreData?: ILearnMore
  searchBlock?: boolean
  vacanciesBlock?: boolean
  onEmptyList?: any
  customEmptyBlock?: boolean
  page?: number
}

class VacanciesList extends React.Component<IVacanciesListProps> {
  static itemsPerPage = 7
  constructor(props: IVacanciesListProps) {
    super(props)

    this.handleEmptyList = this.handleEmptyList.bind(this)
  }

  public render() {
    return (
      <UserLocationContext.Consumer>
        {(userLocation) => (
          <VacanciesContext.Consumer>
            {(context) => this.renderArray(context, userLocation.data)}
          </VacanciesContext.Consumer>
        )}
      </UserLocationContext.Consumer>
    )
  }

  protected renderArray(context: IVacanciesContext, location: any) {
    let vacanciesBlock = this.props.vacanciesBlock
    let departmentAlias = this.props.departmentAlias
    let excludedVacancy = this.props.excludedVacancy
    let filter = this.props.filter || {}
    let page = this.props.page
    let result = context.data
    let visibleVacancies: any = []
    let exactKeyWords = ["intern", "hr", "it"]
    let searchResult: any = []

    // @ts-ignore
    filter.userLocation = location

    if (filter && filter.query && filter.query.length > 1) {
      const fuse = new Fuse(result, {
        includeScore: true,
        useExtendedSearch: true,
        keys: [
          {
            name: "title",
            weight: 1,
          },
          {
            name: "department.title",
            weight: 0.8,
          },
          {
            name: ["qualifications"],
            weight: 0.2,
          },
          {
            name: ["responsibilities"],
            weight: 0.2,
          },
          {
            name: ["responsibilitiesMain"],
            weight: 0.2,
          },
        ],
        sortFn: (a, b) => a.score - b.score,
      })

      if (exactKeyWords.includes(filter.query.toLowerCase())) {
        searchResult = fuse.search(`=${filter.query} | ${filter.query}$`)
      } else {
        searchResult = fuse.search(
          `=${filter.query} | ${filter.query}$ | '${filter.query}`
        )
      }

      result = searchResult.map((ref: any) => ref.item)
    }

    result =
      result
        .filter((vacancy) => {
          let usVacancies = "USA"
          let result = vacancy.isVisible
          if (departmentAlias) {
            result = result && vacancy.department.alias === departmentAlias
          }
          if (excludedVacancy) {
            result = result && vacancy.routeAlias !== excludedVacancy.routeAlias
          }
          if (filter && filter.department) {
            result =
              result &&
              (filter.department === "all" ||
                vacancy.department.alias === filter.department)
          }
          if (filter && filter.languages && filter.languages.length > 0) {
            result =
              result &&
              filter.languages.some((option: ISelectItem) => {
                return vacancy.language.some(
                  (lang: IVacancyLanguage) => option.value === lang.id
                )
              })
          }
          if (filter && filter.userLocation && !filter.location) {
            if (
              filter.userLocation.country === usVacancies &&
              !context.data.some(
                (vacancy: IVacancy) =>
                  vacancy.location[0].country === usVacancies
              )
            ) {
              filter.location = "all"
            }

            result =
              result &&
              vacancy.location.some(
                (loc: IVacancyLocation) =>
                  filter &&
                  filter.userLocation &&
                  loc.country === filter.userLocation.country
              )
          }
          if (filter && filter.location) {
            result =
              result &&
              vacancy.location.some((loc: IVacancyLocation) => {
                let cityFilter =
                  filter.city || ""
                    ? filter.city === "all" || loc.city === filter.city
                    : true
                let stateFilter =
                  filter.state || ""
                    ? filter.state === "all" || loc.state === filter.state
                    : true

                return (
                  filter &&
                  cityFilter &&
                  (filter.location === -1 ||
                    filter.location === "all" ||
                    (typeof filter.location === "string" &&
                      loc.locationString.startsWith(filter.location)) ||
                    loc.id === filter.location) &&
                  stateFilter
                )
              })
          }

          return result
        })
        .sort((a: IVacancy, b: IVacancy) => {
          return filter && filter.query && filter.query.length > 1
            ? 0
            : Number(b.isHot) - Number(a.isHot)
        }) || []
    if (this.props.limit) {
      result = result.slice(0, this.props.limit)
    }

    if (this.props.onEmptyList) {
      this.handleEmptyList(result.length === 0)
    }

    if (filter && this.props.page) {
      visibleVacancies = result.slice(
        0,
        page && page * VacanciesList.itemsPerPage
      )
    }

    return (
      <React.Fragment>
        {!vacanciesBlock
          ? result.length > 0 &&
            result.map((vacancy) => (
              <VacancyListItem
                vacancy={vacancy}
                key={`vacancy-${vacancy.id}`}
              />
            ))
          : result.length > 0 && (
              <React.Fragment>
                <div className="d-none d-lg-block">
                  {result.length &&
                    result.map((vacancy: any) => (
                      <VacancyListItem
                        vacancy={vacancy}
                        key={`vacancy-${vacancy.id}`}
                      />
                    ))}
                </div>
                <div className="d-block d-lg-none">
                  {result.length &&
                    result
                      .slice(0, result.length)
                      .map((vacancy: any) => (
                        <VacancyListItem
                          vacancy={vacancy}
                          key={`vacancy-${vacancy.id}`}
                        />
                      ))}
                </div>
              </React.Fragment>
            )}
      </React.Fragment>
    )
  }
  protected handleEmptyList(value: boolean) {
    if (this.props.onEmptyList) {
      this.props.onEmptyList(value)
    }
  }
}

export default VacanciesList
