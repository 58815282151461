import React from 'react';
import {DepartmentsContext, IDepartmentsContext} from './departments.context';
import axios from 'axios';

interface IDepartmentsProviderProps {
    children: JSX.Element[] | JSX.Element
}

interface IDepartmentsProviderState extends IDepartmentsContext {
}

class DepartmentsProvider extends React.Component<IDepartmentsProviderProps, IDepartmentsProviderState> {

    constructor(props: IDepartmentsProviderProps) {

        super(props);
        this.state = {
            isLoading: false,
            isCompleted: false,
            data: [],
            error: null
        }
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
            isCompleted: false
        });
        axios.get('/api/department')
            .then(res => {
                this.setState((state) => {
                    return {
                        ...state,
                        data: res.data,
                        isLoading: false,
                        isCompleted: true
                    }
                });
            })
            .catch((error) => {
                this.setState((state) => {
                    return {
                        ...state,
                        error: error,
                        isCompleted: true,
                        isLoading: false
                    }
                })
            })
    }

    public render() {
        return <DepartmentsContext.Provider value={this.state}>
            {this.props.children}
        </DepartmentsContext.Provider>
    }
}

export default DepartmentsProvider;
