import React from 'react';
import './back-to-top.scss';
import {throttle} from 'throttle-debounce';


interface IMenuToggleState {
    isVisible: boolean
}

class BackToTop extends React.Component<{}, IMenuToggleState> {

    constructor(props: any) {
        super(props);
        this.state = {
            isVisible: false
        };
        this.handleScroll = throttle(300, this.handleScroll.bind(this));
    }

    static toTop(): void {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    componentDidMount(): void {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(): void {
        if (window.scrollY > 200 && !this.state.isVisible) {
            this.setState({
                isVisible: true
            });
        }
        if (window.scrollY <= 200 && this.state.isVisible) {
            this.setState({
                isVisible: false
            });
        }
    }

    render() {
        let className = `back-to-top ${(this.state.isVisible && 'show') || ''}`;
        return (
            <div className={className} onClick={() => BackToTop.toTop()}>
                <img src="//img.veeam.com/careers/global/icons/scroll.svg" alt=""/>
            </div>
        );
    }
}

export default BackToTop;
