import {RouteComponentProps} from "react-router";
import React from "react";
import VacanciesListFilter from "../../components/vacancies-list-filter/vacancies-list-filter";
import {VacanciesContext} from "../../../shared/components.shared/components/providers/vacancies.context";
import {IVacancyTag} from "../../../shared/types";
import {debounce} from "throttle-debounce";
import {ThemeContext} from "../../../shared/components.shared/components/providers/theme.context";
import qs from "qs";
import learnMore from "../../../cz/mocks/learn-more";
import VacanciesList from "../../components/vacancies-list/vacancies-list";
import {UserLocationContext} from "../../components/providers/userlocation.provider";
import AskQuestion from '../../components/ask-question/ask-question';
import VacanciesListButton from '../../components/vacancies-list-button/vacancies-list-button';
import ApplyBlocks from '../../components/apply-blocks/apply-blocks';
import blocks from '../../mocks/mocks-apply-block';
import ContentLoader, {Facebook} from 'react-content-loader';

interface IVacanciesPageState {
    [key: string]: any;
    page: number
}


class Vacancies extends React.Component<RouteComponentProps, IVacanciesPageState> {
    constructor(props: any) {
        super(props);

        let queryParams = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true
        });

        this.state = {
            filter: {
                query: queryParams.query || '',
                department: queryParams.department || '',
                location: queryParams.location || '',
                state: queryParams.state || '',
                city: queryParams.city || ''
            },
            isEmptyVacanciesList: false,
            page: 1,
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleEmptyVacanciesList = this.handleEmptyVacanciesList.bind(this);
        this.handleTagSelect = this.handleTagSelect.bind(this);
        this.updateRouteParams = debounce(1000, this.updateRouteParams.bind(this));
        this.loadMore = this.loadMore.bind(this);
    }

    protected handleFilterChange(filterName: string, filterValue: any): void {
        this.setState((prevState: IVacanciesPageState) => {
            return {
                filter: {
                    ...prevState.filter,
                    [filterName]: filterValue,
                }
            }
        });

        this.setState({
            page: 1
        });

        this.updateRouteParams();
    }

    protected handleTagSelect(tag: IVacancyTag): void {
        this.setState({
            tag
        });
        this.updateRouteParams();
    }

    protected updateRouteParams() {
        let history = this.props.history;
        let filter = this.state.filter;

        history.push(window.location.pathname + `?query=${filter.query}&department=${filter.department}&location=${filter.location}&state=${filter.state}&city=${filter.city}`);
    }

    protected handleEmptyVacanciesList(isEmpty: boolean) {
        if (this.state.isEmptyVacanciesList !== isEmpty) {
            this.setState(() => {
                return {
                    isEmptyVacanciesList: isEmpty
                }
            });
        }
    }

    protected loadMore():void {
        this.setState({
            page: this.state.page + 1
        });
    }



    public render() {
        let location = this.state.filter.location;
        let department = this.state.filter.department;

        return (
            <UserLocationContext.Consumer>
                {
                    (userLocation) =>
                        <ThemeContext.Consumer>
                            {
                                (theme) =>
                                    <React.Fragment>
                                        <VacanciesContext.Consumer>
                                            {
                                                (vacanciesContext) =>
                                                    <VacanciesListFilter onChange={this.handleFilterChange}
                                                                         list={vacanciesContext.data}
                                                                         initFilter={this.state.filter}
                                                                         userLocation={userLocation.data}
                                                                         searchQuery={theme.searchQuery}
                                                                         setSearchQuery={theme.setSearchQuery}
                                                    />
                                            }
                                        </VacanciesContext.Consumer>
                                        <VacanciesContext.Consumer>
                                            {
                                                (vacanciesContext) => vacanciesContext.isLoading ?
                                                    <div className="bg-light position-relative">
                                                        <div className="container container-spacer">
                                                            <h1>Vacancies are being loaded, please wait.</h1>
                                                            <ContentLoader/>
                                                            <Facebook />
                                                        </div>
                                                    </div> : ''
                                            }
                                        </VacanciesContext.Consumer>
                                        <div className="bg-light position-relative">
                                            <div className={'container' + (!this.state.isEmptyVacanciesList && ' container-spacer')}>
                                                <div className="row d-none d-md-block">
                                                    <div className="col-12">
                                                        <div className={!this.state.isEmptyVacanciesList ? 'card-columns card-columns__card-columns-vacancies' : ''}>
                                                            <VacanciesList filter={this.state.filter}
                                                                           page={this.state.page}
                                                                           learnMoreData={learnMore}
                                                                           vacanciesBlock={true}
                                                                           onEmptyList={this.handleEmptyVacanciesList}
                                                                           customEmptyBlock={true}/>
                                                            {
                                                                !this.state.isEmptyVacanciesList &&
                                                                <React.Fragment>
                                                                    <AskQuestion key={'askQuestion-vacancies'} filter={this.state.filter} />
                                                                    {/*<ApplyBlocks blocks={blocks[2]}/>*/}
                                                                </React.Fragment>

                                                            }
                                                            {
                                                                !this.state.isEmptyVacanciesList && location === 'Czech Republic' ? <ApplyBlocks blocks={blocks[4]}/> : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    {!this.state.isEmptyVacanciesList ?
                                                        <div className="col-12">
                                                            <div className="card-columns card-columns__card-columns-vacancies">
                                                                <ApplyBlocks blocks={blocks[0]}/>
                                                                <ApplyBlocks blocks={blocks[1]}/>
                                                                {department === 'support' ?
                                                                    <ApplyBlocks blocks={blocks[5]}/>
                                                                :''}
                                                            </div>
                                                        </div> : ''
                                                    }
                                                </div>
                                                <div className="row d-block d-md-none">
                                                    <div className="col-12">
                                                        <div className={!this.state.isEmptyVacanciesList ? 'card-columns card-columns__card-columns-vacancies' : ''}>
                                                            <VacanciesList filter={this.state.filter}
                                                                           page={this.state.page}
                                                                           learnMoreData={learnMore}
                                                                           vacanciesBlock={true}
                                                                           onEmptyList={this.handleEmptyVacanciesList}
                                                                           customEmptyBlock={true}/>
                                                            {
                                                                !this.state.isEmptyVacanciesList &&
                                                                <AskQuestion key={'askQuestion-vacancies'} filter={this.state.filter} />

                                                            }
                                                            {
                                                                !this.state.isEmptyVacanciesList && location === 'Czech Republic' ? <ApplyBlocks blocks={blocks[4]}/> : ''
                                                            }
                                                        </div>
                                                        {!this.state.isEmptyVacanciesList ?
                                                            <div className="block-spacer">
                                                                <ApplyBlocks blocks={blocks[0]}/>
                                                                {/*<ApplyBlocks blocks={blocks[2]}/>*/}
                                                                <ApplyBlocks blocks={blocks[1]}/>
                                                                {department === 'support' ?
                                                                    <ApplyBlocks blocks={blocks[5]}/>
                                                                :''}
                                                            </div> : ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.isEmptyVacanciesList &&
                                            <React.Fragment>
                                                <div className="bg-light position-relative py-0">
                                                    <div className="container container-spacer pt-0 py-5">
                                                        <h3 className="my-0">Unfortunately, your search returned no results</h3>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                            }
                        </ThemeContext.Consumer>
                }
            </UserLocationContext.Consumer>
        );
    }
}

export default Vacancies;
