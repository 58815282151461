import React from 'react';
import slider from '../../mocks/mocks-slider';
import Carousel from 'react-bootstrap/Carousel';
import {HashLink} from 'react-router-hash-link';

interface IShuffleSliderProps {
    slider: {
        tag: string,
        title: string,
        link: string,
        type: string
    }
}

class ShuffleBannerSlider extends React.Component {
    public render() {
        return (

            <div className="card card-homepage card-homepage--slider mb-5">
                <Carousel>
                    {slider.map((content, index) =>
                        <Carousel.Item key={index} interval={6000}>
                            <div
                                className={`slider-inner card-banner ${content.type === 'grows' ? 'slider-inner--grows bg-dark' :
                                    content.type === 'vacancies' ? 'slider-inner--vacancies bg-dark' :
                                    content.type === 'cares' ? 'slider-inner--cares bg-green' :
                                        content.type === 'values' ? 'slider-inner--values bg-info' : ''}`}>
                                <HashLink smooth to={content.link}>
                                    <div className="card-header" dangerouslySetInnerHTML={{__html: content.tag}}/>
                                    <div className="card-body">
                                        <span dangerouslySetInnerHTML={{__html: content.title}}/>
                                    </div>
                                </HashLink>
                            </div>
                        </Carousel.Item>
                    )}
                </Carousel>
            </div>

        )
    }
}

export default ShuffleBannerSlider
