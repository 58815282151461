import React from 'react';

interface IStudentCard {
  student : {
    img: string,
    name: string,
    university: string,
    hear: string
  }
}
const StudentCard = ({student}: IStudentCard): JSX.Element => {
  return (
    <div className={"col-12 col-md-6"}>
      <div className='col mb-5'>
      <div className='row mb-4' style={{height: '125px'}}>
        <div className={'col-4'}>
          <div
            style={
              {
                position: 'relative',
                margin: '0 auto',
                background: `url(${student.img}) no-repeat center center`,
                backgroundSize: 'cover',
                width: '100%',
                height: '100%',
                borderRadius: '10px'
              }
            }
          />
        </div>
        <div className='col-8 d-flex flex-column'>
          <span className="student-name">{student.name}</span>
          <span className="student-university">{student.university}</span>
        </div>
      </div>
      <span className="student-hear">{student.hear}</span>
    </div>
    </div>
  );
};

export default StudentCard;