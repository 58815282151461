import React from 'react';
import {Link} from 'react-router-dom';
import VacanciesList from '../../components/vacancies-list/vacancies-list';
import learnMore from '../../../cz/mocks/learn-more';
import {Nav, Tab} from 'react-bootstrap';
import {clientsContent, clientsNav} from '../../mocks/mocks-clients';
import Awards from '../../components/awards/awards';
import Scrollspy from 'react-scrollspy';
import pageMenu from '../../mocks/mocks-page-menu';


class About extends React.Component {

    public render() {
        return (
            <React.Fragment>
                <div className="page-menu-wrapper">
                    <div className="page-menu">
                        <Scrollspy items={pageMenu[2]} currentClassName="is-active">
                            <li><a href="#our-clients">Our clients</a></li>
                            <li><a href="#our-platform">Our platform</a></li>
                            <li><a href="#awards">Awards</a></li>
                            <li><a href="#clients-partners">Clients and partners</a></li>
                            <li><a href="#become-veeamer">Become a Veeamer</a></li>
                        </Scrollspy>
                    </div>
                </div>
                <div className="banner-spacer banner-spacer-lg">
                    <img src="//img.veeam.com/careers/global/banner/about/cloud.png" alt="" className="img img-banner--cloud"/>
                    <img src="//img.veeam.com/careers/global/banner/about/cloud_with_star.png" alt="" className="img img-banner--cloud-with-star"/>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-block d-lg-none">
                                <h1 className="display-1 mt-0">About Veeam</h1>
                                <h3 className="mb-5">We’re the elite in Modern Data Protection</h3>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5 order-2 order-md-1">
                                <img src="//img.veeam.com/careers/global/banner/about/hand.png" alt="" className="img-fluid img-banner--absolute-bottom"/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-7 order-1 order-md-2">
                                <h1 className="display-1 mt-0 d-none d-lg-block">About Veeam</h1>
                                <h3 className="mb-5 d-none d-lg-block">We’re the elite in Modern Data Protection</h3>
                                <h5 className="mb-0">A premier team of sales professionals, technical experts, engineers and business support.
                                    And we’re on the verge of something big — rising at a rapid rate with double-digit growth,
                                    unlike anything ever seen in our industry. </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-dark">
                    <div className="container container-spacer" id="our-clients">
                        <h2>Our clients need us to protect and manage their data</h2>
                        <div className="row">
                            <div className="col-12 col-md-6 col-xl-5">
                                <div className="card flex-column bg-transparent mb-0 w-100 border-0">
                                    <div className="card-header flex-column pl-0 pt-0">
                                        <h2 className="display-number text-success-light mb-2 mt-0"><em className="nowrap">24 / 7 / 365</em></h2>
                                        <h4 className="mb-3">Available whenever it is needed</h4>
                                        <p>We do that no matter it may reside cloud, physical or virtual environment. Wherever it sits, it has our protection.</p>
                                    </div>
                                    <div className="card-body text-center pl-0">
                                        <img src="//img.veeam.com/careers/global/img/about/client_01.png" alt="" className="img-fluid w-80"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-5 offset-xl-2">
                                <div className="card flex-column bg-transparent mb-0 w-100 border-0">
                                    <div className="card-header flex-column pl-0 pt-0">
                                        <h2 className="display-number text-success-light mb-2 mt-0"><em className="nowrap">400,000 +</em></h2>
                                        <h4 className="mb-3">Сustomers to protect and manage their data and infrastructure</h4>
                                        <p>All while keeping their costs low, business performance high and making data loss a thing of the past.</p>
                                    </div>
                                    <div className="card-body text-center pl-0">
                                        <img src="//img.veeam.com/careers/global/img/about/client_02.png" alt="" className="img-fluid w-65"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container container-spacer">
                    <h2>Our platform</h2>
                    <div className="row" id="our-platform">
                        <div className="col-12 col-md-4 order-md-2 order-xl-1">
                            <img src="//img.veeam.com/careers/global/img/about/platform.png" alt="" className="img-fluid"/>
                        </div>
                        <div className="col-12 col-md-8 order-md-1 order-xl-1">
                            <p>Our best-in-class always-on platform puts all our innovations in one place, making it easier for our customers to access solutions and for us to manage our products well.
                                And our awards shelf is testament to how successful we are. </p>
                            <a href="" id="why-our-product"/>
                            <h2>Why is our product needed?</h2>
                            <p>Veeamers will always tell you one of the three main reasons we want to work here is the product. It’s the best there is.
                                And the only thing better than knowing we’re providing our customers with guaranteed quality is understanding why what we do is so important.</p>
                            <h5>Businesses around the world need our products:</h5>
                            <ul className="list-unstyled list-unstyled--checked">
                                <li>
                                    <span className="icon-checked--circled"/>
                                    <span>To protect against internal and external security threats</span>
                                </li>
                                <li>
                                    <span className="icon-checked--circled"/>
                                    <span>In case of accidental deletion of files or programs</span>
                                </li>
                                <li>
                                    <span className="icon-checked--circled"/>
                                    <span>For legal and compliance requirements</span>
                                </li>
                                <li>
                                    <span className="icon-checked--circled"/>
                                    <span>To give peace of mind over virtual environments</span>
                                </li>
                                <li>
                                    <span className="icon-checked--circled"/>
                                    <span>To give clients control over their data</span>
                                </li>
                                <li>
                                    <span className="icon-checked--circled"/>
                                    <span>For GDPR reporting</span>
                                </li>
                                … and much more!
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="bg-partial bg-partial--light">
                    <div className="container container-spacer">
                        <div className="row block-spacer mt-0" id="awards">
                            <div className="col-12 col-xl-3 d-md-row">
                                <div className="header-with-image">
                                    <h2 className="h1">Awards</h2>
                                    <img src="//img.veeam.com/careers/global/img/about/awards.png" alt=""
                                         className="img-fluid "/>
                                </div>
                                <p>We’re committed to making Veeam a diverse and inspiring place to work for all of our employees — it’s how we deliver outstanding solutions and keep clients happy. </p>

                            </div>
                            <div className="col-12  col-xl-8 offset-xl-1">
                                <Awards />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container container-spacer">
                    <Tab.Container id="tabs" defaultActiveKey="1">
                        <div className="row" id="clients-partners">
                            <div className="col-12">
                                <Nav className="card card-scroll card-scroll--partners mb-0 flex-row flex-nowrap w-100 bg-transparent">
                                    {clientsNav.map((nav:any, index:number) =>
                                        <Nav.Item key={`about-tab-${index}`}>
                                            <Nav.Link eventKey={nav.index} className="justify-content-end">
                                                <span>{nav.nav}</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    )}
                                </Nav>
                            </div>
                        </div>
                        <Tab.Content>
                            {clientsContent.map((content, index:number) =>
                                <Tab.Pane eventKey={content.index} key={`about-tab-pane-${index}`}>
                                    <div className="row mt-lg-5">
                                        <div className="col-12 col-xl-3 d-none d-xl-block">
                                            {content.desc ? <p>{content.desc}</p> : ''}
                                        </div>
                                        <div className="col-xl-8 offset-xl-1">
                                            {content.content ? <h4 className="block-spacer mt-0">{content.content}</h4> : ''}
                                            <div className="card-deck flex-wrap flex-row">
                                            {content.clients.map((item, index:number) =>
                                                <div key={`about-logo-${index}`} className="card card-logos border-0 bg-transparent">
                                                    <div className="card-body">
                                                        <img className="img-fluid" src={item} alt=""/>
                                                    </div>
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            )}
                        </Tab.Content>
                    </Tab.Container>
                </div>
                <div className="bg-light" id="become-veeamer">
                    <div className="container container-spacer">
                        <h2>Become a Veeamer</h2>
                        <div className="row mb-5 mb-md-0">
                            <div className="col-12 d-flex flex-column flex-md-row justify-content-md-between align-items-start">
                                <h3 className="mt-0">Explore our opportunities</h3>
                                <Link to={"/vacancies"} className="btn btn-link btn-link__cta">See all</Link>
                            </div>
                        </div>
                        <div className="card-deck card-deck-vacancies">
                            <VacanciesList limit={4} learnMoreData={learnMore} searchBlock={false}/>
                        </div>
                    </div>

                </div>

            </React.Fragment>
        )
    }
}

export default About;
