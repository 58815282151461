const grows = [
    [
        {
            img: '//img.veeam.com/careers/global/employees/grows/veronica_vasilescu.png',
            name: 'Veronica Vasilescu',
            title: 'Director, Renewals Specialist',
            quote: 'Coming here was a fresh start with a great vibe',
            link: '/grows/veronica'
        },
        {
            img: '//img.veeam.com/careers/global/employees/grows/ashleigh_skuse.png',
            name: 'Ashleigh Skuse',
            title: 'Inside Sales Director',
            quote: 'I didn\'t raise my hand for the change...but I\'m so glad others raised it for me',
            link: '/grows/ashleigh'
        },
    ],
    [
        {
            img: '//img.veeam.com/careers/global/employees/grows/vishwajeeth.png',
            name: 'Vishwajeeth Venkatesh',
            title: 'Systems Engineer',
            quote: 'Everyone is so flexible and supportive - it shows you\'re actually valued',
            link: '/grows/venkatesh'
        },
        {
            img: '//img.veeam.com/careers/global/employees/grows/zetty_riam.png',
            name: 'Zetty Riam',
            title: 'Inside Sales Team Lead ',
            quote: 'I love the family feel here, there’s always someone who will help you out',
            link: '/grows/zetty'
        },
    ],
    [
        {
            img: '//img.veeam.com/careers/global/employees/grows/marius_nita.png',
            name: 'Marius Nita',
            title: 'Customer Support Engineer ',
            quote: 'I teamed up with three veeamazing guys and we tackled the challenges together',
            link: '/grows/marius'
        },
        {
            img: '//img.veeam.com/careers/global/employees/grows/sergey_stepanov.png',
            name: 'Sergey Stepanov',
            title: 'Inside Sales Manager',
            quote: 'Now in my fifth role in eight years, I can say that our product and our people are our greatest assets',
            link: '/grows/sergey'
        },
    ],
    [
        {
            img: '//img.veeam.com/careers/global/employees/grows/adrian_boboc.png',
            name: 'Adrian Boboc',
            title: 'Inside Sales Manager',
            quote: 'Having the agility to spot opportunities and be the best in new markets makes me feel I\'m in the right place',
            link: '/grows/adrian'
        },
        {
            img: '//img.veeam.com/careers/global/employees/grows/macro_zhang.png',
            name: 'Macro Zhang',
            title: 'Senior Director of Sales in China ',
            quote: 'We can see how the international company runs, so we can learn from it in China.',
            link: '/grows/macro'
        },
        /*{
            img: '//img.veeam.com/careers/global/employees/grows/james_hazley.png',
            name: 'James Hazley',
            title: 'Key Account Manager',
            quote: 'We genuinely celebrate each other\'s sales successes',
            link: '/grows/james'
        },*/
    ],
    [
        {
            img: '//img.veeam.com/careers/global/employees/grows/daniel_triplehorn.png',
            name: 'Daniel Triplehorn',
            title: 'Technical Support Engineer',
            quote: 'Veeam is a large company with a small-company vibe',
            link: '/grows/daniel'
        },
        {
            img: '//img.veeam.com/careers/global/employees/grows/rick_johnson.png',
            name: 'Rick Johnson',
            title: 'Senior Director, Cloud & Service Providers ',
            quote: 'You have to see your career as navigating traffic - you can join the fast lane or stay where you’re happy with the speed',
            link: '/grows/rick'
        },
    ],
];

export default grows;
