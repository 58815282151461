import React, {useEffect, useRef, useState} from 'react';
import {TEXT_BLOCKS} from "./StudentsAndGraduates.const";
import './StudentsAndGraduates.scss'
import StudentsAndGraduatesFooter from "./footer/StudentsAndGraduates.footer";
import StudentCard from "./studentCard/StudentCard";
import ProgramCard from "./programCard/ProgramCard";
import {Link} from "react-router-dom";

const StudentsAndGraduates = (): JSX.Element => {
  const [currentVideo, setCurrentVideo] = useState<string | null>(null)
  const iframe = useRef<HTMLIFrameElement>(null)

  const handlePlayVideo = (url: string) => {
    setCurrentVideo(url)
    if(iframe.current) {
      iframe.current.requestFullscreen()
    }
  }


  useEffect(() => {
    const handlingFullscreen = ()  => {
      if (!document.fullscreenElement) {
        setCurrentVideo(null)
      }
    }

    document.addEventListener('fullscreenchange', handlingFullscreen);
    document.addEventListener('webkitfullscreenchange', handlingFullscreen);
  },[])
  return (
    <div className="position-relative">

    <div className="internship">
      <div className="container container-spacer">
          <div className="bg-light row launch mx-1 mx-md-0 position-relative d-flex justify-content-end">
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center pr-0 mt-5 mt-md-0">
              <div className="row">
                <div className="col-12 col-md-1"/>
                <div className="col-12 col-md-11 px-md-0 d-flex flex-column">
                  <span className={'display-3'}>{TEXT_BLOCKS.main.launch.header}</span>
                  <span className={'display-3'}>Launch your <span className={'link-secondary'}>Career at Veeam</span>!</span>
                  <br/>
                  <div className={"row"}>
                    <p className={"col-12 col-md-10"}>{TEXT_BLOCKS.main.launch.description}</p>
                  </div>
                </div>
              </div>

            </div>
            <div className="d-none d-md-block col-md-6 pr-0 pl-0 d-flex align-items-end justify-content-end launch-wrapper">
              <div className="launch-wrapper-image"/>
            </div>
          </div>
      </div>
      <div className="container container-spacer">
        <div className='bg-white'>
          <div className="row grow">
            <div className="col-12 col-md-5 mb-4">
              <span className="display-3">{TEXT_BLOCKS.main.grow.growWithUs}</span>
            </div>
            <div className="col-12 col-md-7">
              <span>{TEXT_BLOCKS.main.grow.description}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light">
        <div className="container container-spacer">
          <h2 className='display-3'>
            Programs
          </h2>
          <div className="col-12">
            <ProgramCard program={TEXT_BLOCKS.programs[0]}/>
            <ProgramCard program={TEXT_BLOCKS.programs[1]} isReversed/>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container container-spacer">
          <h2 className='display-3'>
            Hear From Students Like You...
          </h2>
          <div className="row">
              {TEXT_BLOCKS.students.map(student => <StudentCard student={student} key={student.name}/>)}
          </div>
        </div>
      </div>
      <div className="bg-light">
        <div className="container container-spacer">
          <div className="row">
            <div className="col-12 col-md-3 mb-4 mb-md-0">
              <span className="display-3">{TEXT_BLOCKS.disctinctly.disctinctly}</span>
            </div>
            <div className="d-lg-block col-md-2"/>
            <div className="col-12 col-md-7">
              <span>{TEXT_BLOCKS.disctinctly.description}</span>
            </div>
          </div>
          <div className="row mb-5 mb-lg-0">
            <div className="d-none d-lg-block col-lg-5"/>
            <div className="col-12 col-lg-7 d-flex flex-column my-5 px-5">
              <div className="col-12 d-flex flex-column flex-md-row justify-content-between">{TEXT_BLOCKS.disctinctly.icons.map((iconUrl =>
                <img src={`//img.veeam.com/careers/global/students-graduates/${iconUrl}`}
                     className="img-fluid"
                     key={iconUrl}
                     style={{maxHeight: '250px'}}
                     alt="banner"/>))}
              </div>
              <div className="d-flex justify-content-center mt-4">
                <div className="col-12 col-md-6 d-flex justify-content-center">
                <Link to="/diversity-and-inclusion"
                         className="btn btn-secondary">Learn more</Link></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container container-spacer position-relative">
          <div className="d-flex justify-content-between align-items-center flex-column flex-md-row">
            {TEXT_BLOCKS.learnMore.map((video, i) => <button key={video.videoUrl} className={`thumbnail ${i !== 0 && i !== TEXT_BLOCKS.learnMore.length-1 ? 'mx-0 mx-md-2 my-2 my-md-0' : ''}`} onClick={() => handlePlayVideo(video.videoUrl)}>
              <img src={video.posterUrl} className="img-fluid" alt="video"/>
              <img src={'//img.veeam.com/careers/global/video/button-play.png'} alt={'playButton'} className={"thumbnail-play"}/>
            </button>)
            }
          </div>

        </div>
      </div>
      <StudentsAndGraduatesFooter/>
    </div>
    <iframe src={currentVideo ?? ""}
            ref={iframe}
                  style={{display: currentVideo ? 'block' : 'none'}}
                  title={"test"}
                  allow='autoplay'
                  allowFullScreen/>
    </div>
  );
};

export default StudentsAndGraduates;