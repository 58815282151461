import React from 'react';
import {IVacancy, IVacancyLocation} from "../../../shared/types";

interface IVacanciesCountProps {
    departmentAlias?: string
    vacancies:IVacancy[];
    location: IVacancyLocation[];
}

class VacanciesCount extends React.Component<IVacanciesCountProps> {
    public render() {
        return (
            <span className="text-secondary pl-2">
                {this.renderDepartmentNumber()}
            </span>
        );
    }

    protected renderDepartmentNumber() {
        let department = this.props.departmentAlias;
        let location = this.props.location[0].country;
        let data = this.props.vacancies;
        data = data.filter(vacancy => department ? vacancy.department.alias === department : true)
            .filter(vacancy => location ? vacancy.location[0].country === location : true);
        return data.length;
    }
}



export default VacanciesCount;
