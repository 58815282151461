import React from "react"
import { IVacancy } from "../../../shared/types"

interface IVacancyShare {
  vacancy: IVacancy
}

class ShareVacancy extends React.Component<IVacancyShare> {
  public render() {
    let vacancy = this.props.vacancy
    return (
      <ul className="list-unstyled d-flex flex-row align-items-center mb-0">
        <span className="text-placeholder-pressed">Share a job</span>
        <li className="mb-0 pl-3">
          <a
            className="sprites bg-facebook"
            target="_blank"
            href={`//www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcareers.veeam.com%2Fvacancies%2F${vacancy.department.alias}/${vacancy.routeAlias}&caption=${vacancy.title}&description=${vacancy.description}`}
          />
        </li>
        <li className="mb-0 pl-3">
          <a
            className="sprites bg-linkedin"
            target="_blank"
            href={`//www.linkedin.com/sharing/share-offsite/?url=careers.veeam.com/vacancies/${vacancy.department.alias}/${vacancy.routeAlias}&title=${vacancy.title}`}
          />
        </li>
        <li className="mb-0 pl-3">
          <a
            className="sprites bg-twitter"
            target="_blank"
            href={`//twitter.com/intent/tweet?via=SmartRecruiters&url=${vacancy.applyUrl}&text=${vacancy.title}`}
          />
        </li>
      </ul>
    )
  }
}

export default ShareVacancy
