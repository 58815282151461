import React from 'react';
import {HashLink} from 'react-router-hash-link';

interface IApplyBocks {
    blocks: {
        img?: string,
        imgBottom?: string,
        name?: string,
        title: string,
        quote?: string
        link: string,
        banner: boolean,
        localBanner?: boolean,
        externalBanner?:boolean,
        opportunities?: boolean

    };
}


class ApplyBlocks extends React.Component<IApplyBocks> {
    render() {
        let blocks = this.props.blocks;

    return (
         <React.Fragment>
             {!blocks.banner ?
             <HashLink className="card card-no-hover" to={blocks.link}>
                 <div className="card-header p-0 justify-content-center">
                     <img src={blocks.img} alt="" className="img-fluid"/>
                 </div>
                 <div className="card-body bg-green">
                     <p className="mb-0"><strong>{blocks.name}</strong></p>
                     <p><small>{blocks.title}</small></p>
                     <h5 className="mb-0"><strong>{blocks.quote}</strong></h5>
                 </div>
             </HashLink> :
                 blocks.localBanner?
                 <a className="card card-banner card-no-hover bg-dark" href={blocks.link} target="_blank">
                     <div className="card-body block-spacer-top py-0">
                         <h2 className="h2 my-0"><strong dangerouslySetInnerHTML={{__html:blocks.title}}/></h2>
                         <button type="button" className="btn btn-secondary block-spacer-top">Learn more</button>
                     </div>
                     <div className="card-footer d-flex p-0 justify-content-center">
                        <img className="img img__bottom"  src={blocks.imgBottom} alt=""/>
                     </div>
                 </a> :
                 blocks.externalBanner ?
                 <a className="card card-no-hover card-banner bg-green card-homepage--external" href={blocks.link} target="_blank">
                     <img className="img img__top" src={blocks.img} alt=""/>
                     <div className="card-body pt-0">
                         <h2 className="h2 my-5"><strong dangerouslySetInnerHTML={{__html:blocks.title}}/></h2>
                         <button type="button" className="btn btn-secondary">Learn more</button>
                     </div>
                 </a>
                     : ''
             }
         </React.Fragment>
      )
    }
}

export default ApplyBlocks;
