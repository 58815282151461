import React from 'react';
import {IVacancy} from "../../../types";

interface IDepartmentsCountProps {
    departments: IVacancy[];
}

class DepartmentsCount extends React.Component<IDepartmentsCountProps> {
    public render() {
        return (
            this.renderDepartments()
        );
    }

    protected renderDepartments(){
        let depMap = new Map();
        let data = this.props.departments;
        data.forEach((vacancy) => {
           depMap.set(vacancy.department.alias, vacancy.department.alias);
        });
        return depMap.size;
    }
}

export default DepartmentsCount;
