import React from 'react';
import {IOffice} from '../../../types';

export type IOfficesContext = {
    data: IOffice[],
    isLoading: boolean,
    isCompleted: boolean,
    error: null | string
};
export const defaultValue = {
    data: [],
    isLoading: false,
    error: null,
    isCompleted: false
};

export const OfficesContext = React.createContext<IOfficesContext>(defaultValue);
