import React from "react"
import axios from "axios"
import { IBaseContext, IUserLocation } from "../../types"

export interface IUserLocationContext extends IBaseContext<IUserLocation> {}

const contextDefaultValue = {
  data: {
    region: "NA",
    countryCode: "USA",
    country: "USA",
  },
  isLoading: false,
  error: null,
  isCompleted: false,
}

export const UserLocationContext =
  React.createContext<IUserLocationContext>(contextDefaultValue)

interface IAwardsProviderProps {
  children: JSX.Element[] | JSX.Element
}

interface IUserLocationProviderState extends IUserLocationContext {}

class UserLocationProvider extends React.Component<
  IAwardsProviderProps,
  IUserLocationProviderState
> {
  constructor(props: any) {
    super(props)
    this.state = contextDefaultValue
  }
  componentDidMount() {
    this.setState({
      isLoading: true,
      isCompleted: false,
    })

    axios
      .get("/api/user-location")
      .then((res) => {
        this.setState({
          data: res.data || [],
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false,
          isCompleted: true,
        })
      })
  }

  public render() {
    return (
      <UserLocationContext.Provider value={this.state}>
        {this.props.children}
      </UserLocationContext.Provider>
    )
  }
}

export default UserLocationProvider
