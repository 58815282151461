import React from 'react';
import PeopleStories from '../../pages/people-stories/people-stories';
import {IPeopleStories} from '../../types';

interface IPeopleStoriesProps {
    story: IPeopleStories
}

class StoriesCaresJonathan extends React.Component<IPeopleStoriesProps> {
    public render() {
        let story = this.props.story;

        return (
            <PeopleStories bg='bg-info' story={story}/>
        )
    }
}

export default StoriesCaresJonathan;




