import React from 'react';
import {IPeopleCaresContext, PeopleCaresContext} from './peoplecares.context';
import axios from 'axios';

interface INewsProviderProps {
    children: JSX.Element[] | JSX.Element;
}

interface INewsProviderState extends IPeopleCaresContext {

}

class PeopleCaresProvider extends React.Component<INewsProviderProps, INewsProviderState> {
    componentDidMount() {
        this.setState({
            isLoading: true,
            isCompleted: false,
        });

        axios.get('/api/people-stories/cares').then(res => {
            this.setState({
                data: (res.data || []),
                isLoading: false,
                isCompleted: true
            });
        })
    }

    public render() {
        return (
            <PeopleCaresContext.Provider value={this.state}>
                {this.props.children}
            </PeopleCaresContext.Provider>
        )
    }
}

export default PeopleCaresProvider;
