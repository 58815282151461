import React from 'react'
import { TEXT_BLOCKS } from '../StudentsAndGraduates.const'
import SagQuestion from './SAGQuestion/SAGQuestion'

const JOIN_LINK =
  'https://boards.eu.greenhouse.io/veeamsoftware/jobs/4304957101'

const StudentsAndGraduatesFooter = () => {
  return (
    <>
      <div className="bg-light-green">
        <div className="container container-spacer">
          <div className="row">
            <div className="col-12 col-md-3 mb-4 mb-md-0">
              <span className="display-3">
                {TEXT_BLOCKS.questions.questions}
              </span>
            </div>
            <div className="d-lg-block col-md-2" />
            <div className="col-12 col-md-4">
              <span>{TEXT_BLOCKS.questions.navigate}</span>
            </div>
            <div className="d-lg-block col-md-3" />
          </div>
          <div className="row">
            <div className="col-12 col-md-5" />
            <div className="col-12 col-md-4 mt-5 d-flex justify-content-start">
              <a
                href={JOIN_LINK}
                target="_blank"
                rel="noreferrer noopener"
                className="btn btn-light mt-4"
              >
                {TEXT_BLOCKS.questions.button}
              </a>
            </div>
            <div className="d-lg-block col-md-3" />
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container container-spacer">
          <div className="row">
            <div className="col-12 col-md-3 mb-4 mb-md-0">
              <span className="display-3">{TEXT_BLOCKS.faq.title}</span>
            </div>
            <div className="d-lg-block col-md-1" />
            <div className="col-12 col-md-6">
              {TEXT_BLOCKS.faq.questions.map((question) => (
                <SagQuestion
                  key={question.question}
                  question={question.question}
                  answer={question.answer}
                />
              ))}
            </div>
            <div className="d-lg-block col-md-3" />
          </div>
        </div>
      </div>
    </>
  )
}

export default StudentsAndGraduatesFooter
