import React from "react"
import { IVacancy } from "../../../shared/types"
import dayjs from "dayjs"

interface IVacancyItemProps {
  vacancy: IVacancy
}

interface IVacancyFormatted {
  city: string
  country: string
}

class VacancyItem extends React.Component<IVacancyItemProps> {
  public render() {
    let vacancy = this.props.vacancy
    let titleClassName = vacancy.isHot ? "text-warning" : "text-normal"
    let url = new URL(vacancy.applyUrl)
    let date = new Date(vacancy.publicationDate)
    let formattedLocations = vacancy.location
      .map((location, index, arr) => {
        return {
          city:
            arr.filter((item) => item.country === location.country).length > 2
              ? "Multiple locations"
              : location.city,
          country: location.country,
        }
      })
      .reduce((acc: IVacancyFormatted[], loc: IVacancyFormatted) => {
        let found = acc.find((item) => item.city === loc.city)
        if (!found) {
          acc.push(loc)
        }
        return acc
      }, [])

    const decodeHtml = (html: string) => {
      var txt = document.createElement("textarea")
      txt.innerHTML = html
      return txt.value
    }

    return (
      <div className="content-wrapper">
        <h3 className="text-placeholder-pressed my-0">
          Jobs available in{" "}
          <span
            // @ts-ignore
            className={titleClassName}
          >
            {vacancy.department.title}
          </span>
        </h3>
        <h1 className="display-1 mt-4 mb-4">{vacancy.title}</h1>
        <p className="text-placeholder-pressed">
          <span className="pr-2">Date of publication:</span>
          {dayjs(date).format("DD MMMM YYYY")}
        </p>

        {formattedLocations.map((item) => (
          <div className="text-placeholder-pressed">
            {formattedLocations.length === 1 &&
            item.city &&
            item.city === "Multiple locations" ? (
              <span className="font-weight-bold">
                {item.city &&
                  item.city.charAt(0).toUpperCase() + item.city.slice(1)}{" "}
              </span>
            ) : formattedLocations.length <= 2 &&
              item.city &&
              item.city !== "Multiple locations" ? (
              <span>
                {item.city &&
                  item.city.charAt(0).toUpperCase() + item.city.slice(1)}{" "}
                / {item.country}{" "}
              </span>
            ) : (
              <span>
                {item.city &&
                  item.city.charAt(0).toUpperCase() + item.city.slice(1)}{" "}
                / {item.country}{" "}
              </span>
            )}
          </div>
        ))}
        <div
          className="block-spacer"
          dangerouslySetInnerHTML={{ __html: decodeHtml(vacancy.description) }}
        />
        {vacancy.responsibilities !== `` && (
          <div className="card mb-5 card--shadowed">
            <div className="card-body">
              <h3 className="mt-0">Responsibilities</h3>
              {vacancy.responsibilitiesMain != "undefined" && (
                <div
                  className="text-pre-wrap mb-5"
                  dangerouslySetInnerHTML={{
                    __html: vacancy.responsibilitiesMain,
                  }}
                />
              )}
              <div
                className="text-pre-wrap"
                dangerouslySetInnerHTML={{ __html: vacancy.responsibilities }}
              />
            </div>
          </div>
        )}
        {vacancy.qualifications !== `` && (
          <div className="card mb-5 card--shadowed">
            <div className="card-body">
              <h3 className="mt-0">Qualifications</h3>
              <div
                className="text-pre-wrap"
                dangerouslySetInnerHTML={{ __html: vacancy.qualifications }}
              />
            </div>
          </div>
        )}
        {vacancy.we_offer !== `` && (
          <div className="card mb-5 card--shadowed">
            <div className="card-body">
              {!url.hostname.includes("myworkdayjobs") ? (
                <h3 className="mt-0">We offer</h3>
              ) : (
                ""
              )}
              <div dangerouslySetInnerHTML={{ __html: vacancy.we_offer }} />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default VacancyItem
