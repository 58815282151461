const diVeeamNav = [
    {
        nav: 'Women in Green',
        index: 1,
    },
    {
        nav: 'African Heritage in IT',
        index: 2,
    },
    {
        nav: 'Veeam Inclusion',
        index: 3
    }
];

export default diVeeamNav;
