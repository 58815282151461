import React from "react"
import { Link, RouteComponentProps } from "react-router-dom"
import ApplyButton from "../../components/apply-button/apply-button"
import ShareVacancy from "../../components/share-vacancy/share-vacancy"
import SidebannerVacancies from "../../components/sidebanner-vacancies/sidebanner-vacancies"
import VacancyItem from "../../components/vacancy-item/vacancy-item"
import VacanciesList from "../../components/vacancies-list/vacancies-list"
import { VacanciesContext } from "../../../shared/components.shared/components/providers/vacancies.context"
import blocks from "../../mocks/mocks-apply-block"
import ApplyBlocks from "../../components/apply-blocks/apply-blocks"

interface IVacancyProps
  extends RouteComponentProps<{
    department: string
    alias: string
  }> {}

class Vacancy extends React.Component<IVacancyProps> {
  static contextType = VacanciesContext
  context!: React.ContextType<typeof VacanciesContext>

  static setUrl() {
    let query: any
    let param: string
    let paidCampaign: string
    let medium: string

    query = window.location.search.slice(1).split("&")

    query = query.map(function (entry: string) {
      let key = entry.split("=")[0]
      let value = entry.split("=")[1]
      let container: any = {}
      container[key] = value

      return container
    })

    query = Object.assign.apply(null, query)
    paidCampaign = query["utm_campaign"]
    medium = query["utm_medium"]

    if (
      !query["utm_source"] &&
      !query["utm_medium"] &&
      !query["utm_campaign"]
    ) {
      param = "utm_source=careers.veeam.com"
    } else if (query && paidCampaign && medium) {
      param = `source=${query["utm_medium"]}_${query["utm_campaign"]}_${query["utm_source"]}`
    } else {
      param = `utm_source='${query["utm_source"]}`
    }

    if (param.indexOf("gh_src") === -1) {
      param = param + "&gh_src=22853c72teu"
    }

    return param
  }

  componentDidUpdate(prevProps: IVacancyProps) {
    if (prevProps.match.params.alias !== this.props.match.params.alias) {
      window.scrollTo(0, 0)
    }
  }

  public render() {
    let vacancy =
      this.context &&
      this.context.data &&
      this.context.data.find(
        (item) => item.routeAlias === this.props.match.params.alias
      )
    let isCompleted = this.context && this.context.isCompleted
    let isLoading = this.context && this.context.isLoading
    let country = vacancy && vacancy.location[0].country

    return (
      <React.Fragment>
        <div className="bg-light">
          <img
            className="img-pattern img-pattern-left-top"
            src="//img.veeam.com/careers/global/img/vacancy/pattern-left-top.png"
            alt=""
          />
          <img
            className="img-pattern img-pattern-right-bottom"
            src="//img.veeam.com/careers/global/img/vacancy/pattern-right.png"
            alt=""
          />
          <div className="container container-spacer-lg">
            <div className="row">
              <div className="col-12 col-md-8">
                {isLoading && <h4 className="text-center">Loading</h4>}
                {isCompleted && vacancy && <VacancyItem vacancy={vacancy} />}
                {isCompleted && !vacancy && <h1>Job was not found</h1>}
                <div className="card card-cta mb-5 bg-transparent">
                  <div className="card-body py-0">
                    {isCompleted && vacancy && (
                      <ApplyButton vacancy={vacancy} param={Vacancy.setUrl()} />
                    )}
                    {isCompleted && vacancy && (
                      <ShareVacancy vacancy={vacancy} />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="btn-wrapper d-none d-md-block block-spacer-bottom">
                  {isCompleted && vacancy && (
                    <ApplyButton vacancy={vacancy} param={Vacancy.setUrl()} />
                  )}
                </div>
                {country === "Czech Republic" ? (
                  <ApplyBlocks blocks={blocks[4]} />
                ) : (
                  <ApplyBlocks blocks={blocks[1]} />
                )}
                {isCompleted &&
                vacancy &&
                vacancy.department.alias === "support" ? (
                  <ApplyBlocks blocks={blocks[5]} />
                ) : (
                  ""
                )}
                <div className="mb-5"></div>
                <Link
                  className="card card-no-hover card-banner bg-danger mt-5"
                  to="/life"
                >
                  <div className="card-header">
                    <span className="sprites icon-bg-globe_white"></span>
                    <span>Team</span>
                  </div>
                  <div className="card-body">
                    <h3 className="display-3">
                      Learn more about life at Veeam
                    </h3>
                  </div>
                  <div className="card-footer justify-content-end">
                    <a href="/life" className="btn btn-outline-secondary">
                      read
                    </a>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-12">
              <h2>Similar jobs</h2>
            </div>
            <div className="row">
              <div className="col-12 col-md-8 mb-5 mb-md-0">
                <div className="">
                  <VacanciesList
                    limit={3}
                    departmentAlias={vacancy && vacancy.department.alias}
                    filter={{
                      location: vacancy && vacancy.location[0].country,
                    }}
                    excludedVacancy={vacancy}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                {vacancy && vacancy.department && (
                  <SidebannerVacancies
                    department={vacancy && vacancy.department}
                    location={vacancy && vacancy && vacancy.location}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <img
          className="img-pattern img-pattern-left-bottom"
          src="//img.veeam.com/careers/global/img/vacancy/pattern-left-bottom.png"
          alt=""
        />
      </React.Fragment>
    )
  }
}

export default Vacancy
