import React from 'react';
import {IVeeamEmployees} from '../../types';
import {Link} from 'react-router-dom';

interface IVeeamEmployeesProps {
    employee: IVeeamEmployees;
}

class VeeamEmployees extends React.Component<IVeeamEmployeesProps> {
    public render() {
        let employee = this.props.employee;

        return (
            <React.Fragment>
            {employee&&employee.type === 'ahit' ?
                <Link className="card card-no-hover" to={employee.link}>
                    <div className="card-header p-0 justify-content-center">
                        <img src={employee.img} alt="" className="img-fluid"/>
                    </div>
                    <div className="card-body bg-dark">
                        <p className="mb-0"><strong>{employee.name}</strong></p>
                        <p><small>{employee.title}</small></p>
                        <p className="mb-0"><strong>{employee.quote}</strong></p>
                    </div>
                </Link> :
                employee&&employee.type === 'wig'?
                <Link className="card card-no-hover" to={employee.link}>
                    <div className="card-header p-0 justify-content-center">
                        <img src={employee.img} alt="" className="img-fluid"/>
                    </div>
                    <div className="card-body bg-success-light">
                        <p className="mb-0 text-font"><strong>{employee.name}</strong></p>
                        <p className="text-font"><small>{employee.title}</small></p>
                        <p className="text-font"><strong>{employee.quote}</strong></p>
                    </div>
                </Link> :
                employee&&employee.type === 'vi'?
                <Link className="card card-no-hover" to={employee.link}>
                    <div className="card-header p-0 justify-content-center">
                        <img src={employee.img} alt="" className="img-fluid"/>
                    </div>
                    <div className="card-body bg-blue">
                        <p className="mb-0"><strong>{employee.name}</strong></p>
                        <p><small>{employee.title}</small></p>
                        <p className="mb-0"><strong>{employee.quote}</strong></p>
                    </div>
                </Link> :
                    employee && employee.type === 'digender' ?
                        <div  className="card card-values">
                            <div className="card-header pt-0 px-0 pb-0 justify-content-center">
                                <img src={employee.img} alt="" className="img-fluid" />
                            </div>
                            <div className="card-body bg-green bg-green-gender">
                                <p className="mb-0"><strong>{employee.name}</strong></p>
                                <p><small>{employee.title}</small></p>
                                <h5 className="mb-0"><strong>{employee.quote}</strong></h5>
                            </div>
                        </div>
                        :
                <Link className="card card-no-hover" to={employee.link}>
                    <div className="card-header p-0 justify-content-center">
                        <img src={employee.img} alt="" className="img-fluid"/>
                    </div>
                    <div className="card-body bg-green">
                        <p className="mb-0"><strong>{employee.name}</strong></p>
                        <p><small>{employee.title}</small></p>
                        <h5 className="mb-0"><strong>{employee.quote}</strong></h5>
                    </div>
                </Link>
            }
            </React.Fragment>
        )
    }
}

export default VeeamEmployees;
