import React from 'react';
import {IAwardsContext, AwardsContext} from './awards.context';
import axios from 'axios';

interface IAwardsProviderProps {
    children: JSX.Element[] | JSX.Element;
}

interface IAwardsProviderState extends IAwardsContext {

}

class AwardsProvider extends React.Component<IAwardsProviderProps, IAwardsProviderState> {
    componentDidMount() {
        this.setState({
            isLoading: true,
            isCompleted: false,
        });

        axios.get('/api/awards').then(res => {
            this.setState({
                data: (res.data || []),
                isLoading: false,
                isCompleted: true
            });
        })
    }

    public render() {
        return (
            <AwardsContext.Provider value={this.state}>
                {this.props.children}
            </AwardsContext.Provider>
        )
    }
}

export default AwardsProvider;
