import React from 'react';
import {defaultsEnum, IAskQeustion} from '../../types';
import {ITalentManagersContext, TalentManagersContext} from "../providers/talentmanagers.provider";
import {LocationsConverter} from '../../../shared/helpers/locations.converter'

interface IAskQeustionProps {
    filter: { [key: string]: any }
}

class AskQuestion extends React.Component<IAskQeustionProps> {
    private managers: IAskQeustion[] = [];
    private activeManager: IAskQeustion | null = null;
    private Converter: LocationsConverter = new LocationsConverter();

    shouldComponentUpdate(nextProps: Readonly<IAskQeustionProps>): boolean {
        return Object.keys(nextProps.filter)
            .some((key: string) => nextProps.filter[key] !== this.props.filter[key]);
    }

    public render () {
        return (
            <TalentManagersContext.Consumer>
                {
                    (managers: ITalentManagersContext) => this.renderer(managers.data)
                }
            </TalentManagersContext.Consumer>
        )
    }

    protected renderer(managers: IAskQeustion[]) {
        this.managers = managers;
        this.activeManager = this.getManagers();

        return (
            <a href={this.activeManager.profilelink}
               className="bg-dark card card-no-hover card-banner__new"
               target="_blank">
                <div className="card-header p-0">
                    <div className="badge">
                        <p className="badge--regular mb-3">{this.activeManager.name} {this.activeManager.secondname}</p>
                        <p className="badge--sm mb-0">{this.activeManager.position}</p>
                    </div>
                    <img className="img-fluid" src={this.activeManager.img} alt=""/>
                </div>
                <div className="card-body pt-3">
                    <h5>Got questions?</h5>
                    <h2 className="mb-0">Write directly to our recruitment team</h2>
                </div>
                <div className="card-footer">
                    <a href={this.activeManager.profilelink} target="_blank">
                        <button className="btn btn-outline-secondary">
                            ask {this.activeManager.name} a question
                        </button>
                    </a>
                </div>
            </a>
        )
    }

    private getManagers(): IAskQeustion {
        let result: IAskQeustion[] = [];
        let country = this.props.filter.userLocation.country,
            code = this.props.filter.userLocation.countryCode,
            regions = [this.props.filter.userLocation.region];

        if (this.props.filter.location) {
            country = this.props.filter.location.split(',')[0];
            code = this.Converter.getCodeByCountry(country) || code;
            regions = this.Converter.getRegionsByCountryCode(code) || regions;
        }

        result = this.filterByRegion(this.managers, regions, country);
        result = this.filterByArea(result, this.props.filter.department, country);

        if (this.filterByCountry(result, country).length > 0) {
            result = this.filterByCountry(result, country);
        }

        if (result.length === 0) {
            result = this.getDefaultManager(regions, country);
        }

        return this.getRandomManager(result);
    }

    private getDefaultManager(regions: string[], country: string): IAskQeustion[] {
            let result: IAskQeustion[] = this.managers.filter(manager => manager.default);

            if (regions.length > 0) {
                result = this.filterByRegion(result, regions, country, true);
            }

            if (result.length === 0) {
                return this.managers.filter(manager => manager.regions.includes('Global'));
            }

            return result;
    }

    protected filterByRegion(managers: IAskQeustion[], regions: string[], country: string, searchDefault: boolean = false): IAskQeustion[] {
        if (managers.length === 1) return managers;

        let region = regions[0],
            data: {[key in keyof Partial<IAskQeustion>]: string[] | false} = {
                subregions: regions.length > 1 && regions.slice(1),
                countries: [country]
            },
            result = managers.filter(manager => region === defaultsEnum.all || manager.regions.includes(region));

        if (data.subregions && data.subregions.length > 0) {
            result = result.filter(manager => {
                if (!manager.subregions) return true;

                if (!manager.rule) {
                    return data.subregions &&
                        data.subregions.some((subregion: string) => manager.subregions && manager.subregions.includes(subregion));
                }

                return manager.rule.OR && manager.rule.OR.map((field: keyof IAskQeustion) => {
                    return manager[field] && (manager[field] as string[]).some(value => (data[field] as string[]).includes(value));
                }).some(res => res);
            })
        } else {
            result = result.filter(manager => {
                return (searchDefault && manager.default) || !manager.subregions || this.filterByCountryEach(manager, country);
            });
        }
        return result;
    }

    protected filterByCountry(managers: IAskQeustion[], country: string): IAskQeustion[] {
        if (managers.length === 1) return managers;

        return managers.filter(manager => {
            return this.filterByCountryEach(manager, country);
        });
    }

    protected filterByCountryEach(manager: IAskQeustion, country: string) {
        return manager.countries && manager.countries.includes(country);
    }

    protected filterByArea(managers: IAskQeustion[], area: string, country: string): IAskQeustion[] {
        if (managers.length === 1) return managers;
        area = area.toLowerCase();

        return managers.filter(manager => {
            return (
                (area === defaultsEnum.all && manager.areas && manager.areas[0] === defaultsEnum.all) ||
                        (manager.areas && manager.areas[0] === defaultsEnum.all) ||
                        (manager.areas && manager.areas.includes(area))
                    ) && (!manager.countries || manager.countries.includes(country));
        });
    }

    protected getRandomManager(managers: IAskQeustion[]): IAskQeustion {
        if (managers.length === 1) return managers[0];

        let minNun = 0,
            maxNum = managers.length;

        function start(): IAskQeustion {
            let randomNum = Math.floor(minNun + Math.random() * (maxNum - minNun));

            if (!managers[randomNum]) return start();

            return managers[randomNum];
        }

        return start();
    }
}


export default AskQuestion;
