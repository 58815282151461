import React from 'react';
import {Card, Button} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';


interface IAccodionContentProps {
    accordion: {title: string, body: string};
}

interface IAccodrionState {
    isShowed: boolean
}


class AccordionComponent extends React.Component<IAccodionContentProps, IAccodrionState> {
    constructor(props: any) {
        super(props);

        this.state = {
            isShowed: false
        };

        this.onHandleClick = this.onHandleClick.bind(this);
    }

    onHandleClick() {
        this.setState({
            isShowed: !this.state.isShowed
        })
    }


    public render() {
        let accordion = this.props.accordion;

        return (
            <Accordion>
                <Card className="card-accordion">
                    <Accordion.Toggle onClick={this.onHandleClick} as={Button} variant="link" eventKey="0">
                        <Card.Header className={!this.state.isShowed ? 'card-header--closed' : 'card-header--opened'}>
                                <h5 className="mb-0 accordion-title">{accordion.title}</h5>
                        </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body dangerouslySetInnerHTML={{__html: accordion.body}}/>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

        )

    }
}


export  default AccordionComponent;

