const lifeNav = [
    {
        nav: 'Life at Veeam',
        index: 1,
    },
    {
        nav: 'Education. Exposure. Experience.',
        index: 2,
    },
    {
        nav: 'Diverse. Daring. Fast',
        index: 3,
    },
    {
        nav: 'What this means for you',
        index: 4,
    },

    {
        nav: 'Our Veeamers',
        index: 5,
    },
];


const lifeContent = [
        {
            content: 'At Veeam, there are unlimited opportunities to better yourself. Because we invest in our Veeamers to grow as fast as we are. If you have the drive to succeed, you’ll fit in just fine.',
            index: 1
        },
        {
            content: 'We make sure our Veeamers have access to all the resources they need to grow their careers. Our mentoring and coaching programs, libraries of training and development materials as well as on-demand learning and acceleration programs are designed to fast-track your education and upskilling.',
            index: 2
        },
        {
            content: 'The Veeam Team is fuelled by inclusivity and openness. Perhaps that’s why we’ve been rated a ‘great place to work’ by over 91% of our Veeamers. By joining them, you’ll benefit from a culture driven by the people in it. Because when our diverse backgrounds, experiences, strengths, ideas, and opinions come together, we are stronger – it makes us Distinctly Veeam. <br>' +
                '<a href="/diversity-and-inclusion" class="btn btn-success mt-5">Check out more here!</a>',
            index: 3
        },
        {
            content: 'We make progress simple, so you’ll have plenty of room to grow in our collaborative environment, where you’ll be listened to and taken seriously. And we’ll give you everything you need to win – as an individual and as a part of our team. We may be a billion-dollar company, but we’re just getting started. And we need more Veeamers to grow with us.',
            index: 4
        },

        {
            content: 'Our people are the key to our growth and leadership in Modern Data Protection – and there are more than 5,000 of us working hard at it worldwide. If you share our desire to win and strive for purpose in your career, the future of Modern Data Protection is calling you.',
            index: 5
        }
    ];

export {lifeNav, lifeContent};
