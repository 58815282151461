import * as React from 'react';
import {IDepartment} from '../../../types';

export type IDepartmentsContext = {
    data: IDepartment[],
    isLoading: boolean,
    isCompleted: boolean,
    error: null | string
};

export const DepartmentsContext = React.createContext<IDepartmentsContext | null>({
    data: [],
    isLoading: false,
    error: null,
    isCompleted: false
});
