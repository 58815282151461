const opportunityNav = [
    {
        nav: 'Sales',
        index: 0,
    },
    {
        nav: 'Marketing',
        index: 1,
    },

    {
        nav: 'Human Resources',
        index: 2,
    },
    {
        nav: 'Finance',
        index: 3,
    },
    {
        nav: 'Corporate Technology',
        index: 4,
    },
    {
        nav: 'R&D',
        index: 5,
    }
];


const opportunityContent = [
        {
            content: 'With teams worldwide, Sales is the driving force behind our explosive growth. The secret to their success is no secret at all. They sell the world’s leading Modern Data Protection solutions, which have Net Promoter Scores. Join them and you’ll not only have complete faith in the award-winning products you sell, but world-class training at your disposal, bringing together strategic selling techniques with top industry certifications. And trust us: the rewards and incentives are just as worthwhile!',
            contentAccordion: [
                {
                    title: 'Inside Sales Associates: Field warm leads – then decide how best to convert them into sales ',
                    body: 'If you’re looking to get your sales career off the ground, Veeam is the place to be. Our channel partners and end users trust our virtualization and cloud technology products because they work – protecting data and keeping the world moving. People are always eager to know more, and you’ll handle a constant stream of warm, Salesforce-generated leads, qualifying them on short calls and making decisions on which Veeam team is best to convert them into sales. You’ll be among people who are hungry and smart but humble and polite. Comprehensive training will keep you fully up to date – and if you prove you’re a cut above, you won’t have to hang around to move up to the next level of your career. Everyone sells at Veeam so everyone is rewarded, and you can look forward to a highly competitive salary plus commission.'
                },
                {
                    title: 'Inside Sales Representatives: Sell our products to key decision-makers, taking deals through to close',
                    body: 'You love the challenge of selling. You can manage a lead and close a deal. You’re also savvy enough to know that great products open doors. Veeam’s virtualization and cloud technology products work, protecting data and keeping the world moving – which is why we have more than 35,000 active channel partners, 400,000+ customers. We’re obsessed with winning every deal. With training to keep you fully up to speed, you’ll be selling to executive and technical decision-makers, taking deals of varying size through to close – we’re a 100% channel business, so the role will involve co-selling with our partners. Hit your quotas and rewards will be exceptional, both in terms of rapid career progression into other roles within the sales arena and a highly incentivizing package that includes a competitive salary and commission.'
                },
                {
                    title: 'Inside Channel Developers: Reinvigorate relationships with our less active channel partners ',
                    body: 'People trust Veeam’s virtualisation and cloud technology products because they work: protecting data and keeping the world moving. We are a 100% channel business and we work in synergy with some 35,000+ active channel partners to pursue new opportunities and close deals. Naturally, we work with some partners more than others, and your challenge will be to reactivate and nurture those that have been less active. This will entail getting in contact with these partners, introducing them to our product upgrades and finding out what we need to do to bring them back on board and. Your role will be vital in our bid to vigorously pursue every deal, big or small, and achieve our growth plans. So we’ll give you every support and incentive to perform, from training to become fully conversant with Veeam technology to a clear path for career progression and a highly attractive package.'
                },
                {
                    title: 'Inside Channel Managers: Build revenue-generating relationships with our channel partners',
                    body: 'People trust Veeam’s virtualisation and cloud technology products because they work: protecting data and keeping the world moving. We work with some 35,000+ active channel partners to pursue new opportunities and close deals – and because we are a 100% channel business, partnership is the word. You’ll build relationships with a group of partners, enabling and nurturing them to do more business with us and advising them on our products. You’ll work collaboratively on developing Salesforce-generated leads and getting deals (both big and small) over the line – all to deliver mutual results and growth. You might spend time out in the field. You’ll definitely have quotas to meet. But from sales professionals to Territory Managers and Enterprise Account Managers, Veeam has a team that will help you to meet them, grow your career and enjoy a great salary and commission package.'
                },
                {
                    title: 'Partner Managers: Train our channel partners to embrace and sell our products',
                    body: 'Nurture executive-level relationships with the biggest channel partners. Keep them on board by exciting them about data protection solutions that harness the biggest advancements in virtualization and cloud technology. Develop collaborative plans to win the biggest deals. You’ve got it, this is a big opportunity. The role will focus on engaging with our channel partners, training and enabling them to embrace and sell Veeam products above those of our competitors. We’re a 100% channel business – so it would be great if your enterprise software solutions experience is in the channel arena. You’ll see the challenge ahead of you. But also the rewards for hitting your quotas: like the career prospects that will come from our continued growth, plus the chance to earn an attractive package.'
                },
                {
                    title: 'Enterprise Account Managers: Develop business with major corporate accounts and work with our partners to close deals',
                    body: 'You’re ready to scale up. So prepare for the biggest of the big. This is enterprise-level selling in its truest sense. You’ll be responsible for developing business with major corporate accounts, convincing chief decision-makers that Veeam’s virtualization and cloud technology products are superior in protecting data and keeping the world moving. We’re a 100% channel business, so you’ll then work with our partners to close deals. Essentially, this is about implementing our ‘land and expand’ model to evolve and leverage ever-bigger business opportunities. It’s a great chance to build on your proven track record in enterprise software sales, take your career to the next level and earn the kind of package your talents deserve.'
                },
                {
                    title: 'Inside Account Managers: Support our Enterprise Account Managers and channel partners to secure the biggest deals',
                    body: 'You’ve already proved your sales and commercial flair in an inside sales or business development role. Now you’re ready to take the next step in your career. This is your opportunity to move into enterprise-level selling. A 100% channel operation, our virtualization and cloud technology products protect data and keep the world moving. You’ll be instrumental in growing our business pipeline. This will entail supporting multiple Enterprise Account Managers and our partners to evolve and close the biggest deals by identifying and providing access to new projects. If you can get to grips with the sales cycle in these kinds of deals, you’ll soon put your career on an upwards curve. Everyone sells at Veeam so everyone is rewarded, and you can expect the kind of package associated with serious enterprise-level business development.'
                },
                {
                    title: 'Territory Managers: Convince decision-makers to choose us for data protection',
                    body: 'Pace, volume and variety – your track record in enterprise software sales shows you can take them all comfortably in your stride. Now you’re ready to move onto major corporate accounts and the biggest deals. Veeam’s virtualisation and cloud technology products work, protecting data and keeping the world moving. Your job will be to follow up hot leads supplied by our inside sales teams and convince technical decision-makers to make us their preferred choice for data protection. It’s a role that will see you take full responsibility for developing, accelerating, up-selling and closing business across your geographical territory. We’re a 100% channel business, so you’ll work closely with our partner sales community. Big sales mean big rewards at Veeam – so you can look forward to a highly competitive salary, generous commission plan and plenty of other incentives.'
                },
                {
                    title: 'Systems Engineers: Answer product questions from our channel partners and end users',
                    body: 'You’re fully conversant with virtualization and cloud technology. But you’re more than a techie. You can think quickly. Hold an audience with your presence. Deliver presentations that make the complex simple. And influence decision-makers. Good, because this is more than the usual systems engineer job. It’s a chance to take your place among an elite group of smart and hungry but humble engineers who set new standards in pre-sales support and help grow our market share. Our channel partners and end users trust our data protection products because they work. But they always have questions. Travelling to their sites, you’ll answer them. So we’ll invest in making sure you can be best you can be – you’ll be the first to see our product upgrades but also get to know our competitors’ products inside out. You’ll be at the epicentre of how data is managed and enjoy a great package, but there’s also a clear track to move on and grow in other roles.'
                },
                {
                    title: 'Customer Success Representative',
                    body: 'As a Customer Success Representative, you’re on a mission to keep our huge sales momentum rolling. ' +
                        'You’ll keep in touch with customers and provide them with proposals that show them beyond all doubt, ' +
                        'the value of our continued solutions and support. This high-energy position is the first step ' +
                        'in the Customer Success Representative department at Veeam. ' +
                        'You’ll need to be a consultant by nature to get them to sign that all-important contract. You’ll also need ' +
                        'to be an excellent communicator, both written and verbal, and you should be fluent in English(and sometimes in other languages too). ' +
                        'Other than that, we’d like to see proven ability to use your initiative and thrive within a fast-paced environment. '
                },

            ],
            index: 0
        },
        {
            content: 'Our global marketing team is as smart as it is high tech. Our experts use cutting-edge marketing technology to generate continuous demand for our products and build a pipeline for Sales. How do you think we attracted 400,000+ customers worldwide? Make marketing your mission and you’ll be collaborating with international creative agencies one day, then driving campaigns in partnership with big-name technology brands the next.',
            contentAccordion: [
                {
                    title: 'Channel Marketing Managers: Drive joint marketing plans with our channel partners',
                    body: 'We are a 100% channel business. In this role, you’ll be responsible for creating joint multi-touch marketing plans with our strategic partners, focus partners, cloud service providers or distributors. It will mean getting right inside each partner’s business, understanding their capabilities, value proposition and customers. You’ll then evaluate how Veeam’s virtualization and cloud technology products can help them develop their revenue stream, flexing your plan according to the scale of the partner’s marketing organization. Everything’s in place for you to thrive. Since we do business exclusively through our partners, your channel experience will be at a premium. There’s a firm belief in the value of marketing – and investment to go with it – that comes from the top. And you’ll be among great people who will help you to capitalize on the outstanding career opportunities we offer in marketing.'
                },
                {
                    title: 'Field Marketing Managers: Provide the marketing vision to maximize sales in a prime territory',
                    body: 'Our channel partners trust Veeam’s virtualization and cloud technology products because they work, protecting data and keeping the world moving. Which means incredible opportunities for our sales teams. Working alongside these teams in the field, you’ll help to maximize sales. This will involve understanding specific sales goals, and then creating and executing multi-touch marketing campaigns from a digital and events perspective that focus on growing the pipeline and market share. If you’re looking to build on your channel or field experience at the intersection of marketing and sales, this is the role for you. At Veeam, there’s a firm belief in the value of marketing – and investment to go with it, that comes from the top. And you’ll be among great people who will help you to expand your knowledge and capitalize on the outstanding career opportunities we offer in marketing.'
                },
            ],
            index: 1
        },
        {
            content: 'The rate of our global growth is unprecedented – which means our hunt for talent allows HR professionals to overcome unique challenges that put them at the top of their game. Beyond recruitment, there are all kinds of specialisms in HR that help us deliver on our promise to our people in all types of ways. You might be developing our company brand to transform how people view us externally. Working on strategies to operationalize salaries and benefits. Developing innovative training and mentorship programmes that enable people to fully thrive in their careers. Evolving our core values and celebrated culture. Or using tools such as state-of-the-art people management software to help raise companywide productivity. Whatever area you focus on, you’ll be using your skills and knowledge to make a positive difference to thousands of fellow Veeamers. ',
            contentAccordion: [],
            index: 2
        },
        {
            content: 'Our business is thriving and always looking for fresh opportunities. We may be analysing the impact of launching a product, exploring new markets or putting together promotions to attract potential customers – whatever it is, we need the support of a dynamic finance team that’s always up for a challenge. At Veeam, you’ll be more than just a numbers guru. We need you to be a strategic adviser, forge strong ties with stakeholders and fill us with confidence that our next move is a profitable one.',
            contentAccordion: [
                {
                    title: 'Order Processing Specialists: Make sure that every order is ordered',
                    body: 'Take responsibility. Be fully supported in what you do. Take your place in a team that’s respected across Veeam. Our Modern Data Protection products are trusted by our channel distribution partners, as well as by end users. So there’s always a flood of orders coming in. You’ll be in front of a screen ready for them – not just to process them through our Salesforce software system but to analyse them, making sure they are valid and accurate, deciding whether they will go through our auditing processes and thinking about future renewals. Naturally you’ll be trained fully, but it’s an intensive job that calls for an incredible eye for detail. That’s why we’ll look after your wellbeing – if you want to get away from your desk and go for a walk, for example, it’s not a problem. And people look out for each other. We’re a team that comes from many different backgrounds. Many make the job their Veeam career, others use it as a springboard to do other things with us. We’re happy either way.'
                },
            ],
            index: 3
        },
        {
            content: 'We are a Corporate Technology team. We are responsible for the company IT Infrastructure and Business Applications, provide technical leadership in technology investment decisions, and technical support to Veeam employees around the world.' +
                ' Our Mission is enable the most effective business processes by providing employees with data-driven, automated, secure, and adaptive solutions that fuel Veeam business growth.',
            contentAccordion: [],
            index: 4
        },
        {
            content: 'In a truly exciting area of our business, you’ll create best-in-class software products supporting a wide range of custom configurations. It’s a rare opportunity to be at the forefront of the latest technologies and become a real expert in networks, infrastructure platforms and applications, including Microsoft Active Directory, Microsoft Exchange, VMware vSphere/ESX and Microsoft Hyper-V. Want to know more? Read about our <a class="btn btn-link" href="https://cz.careers.veeam.com/">R&D Hub in the Czech Republic</a>',
            index: 5,
            contentAccordion: [
                {
                    title: 'Technical Support Engineers: Keep our products up and running in end-user environments',
                    body: 'Our partners and customers trust Veeam’s virtualization and cloud technology products because they work. But our products sit alongside virtual and physical servers, ' +
                        'network infrastructures and software in the end-user environment. Our Technology Support Engineers make sure that everything works in this ' +
                        'synchronized environment – answering a call or email promptly and providing a solution to keep everything up and running. ' +
                        'Join this outstanding group of talents and you’ll keep our customers happy – and our three times the industry average net promoter score shows that we’re good at doing this. ' +
                        'That’s why you’ll be valued, rewarded and trained. ' +
                        'Fully. If it’s new, you’ll be the first to hear about it. We’ll marry your interests and skills, so you thrive at the epicentre of data management. ' +
                        '<a href="//tech.veeam.com/?utm_source=tcs&utm_medium=link&utm_campaign=areas+of+interest" target="_blank" class="btn btn-link">And there will be tremendous opportunities to advance as a tech expert or move into leadership roles.  Own your career. Join Veeam Team. </a>'
                },
                {
                    title: 'C# Developer',
                    body: 'When it comes to development, we take a highly innovative approach. That – combined with the professional expertise of our Research & Development (R&D) Team – has enabled us to become one of the most respected and in-demand data protection and recovery solution providers in the EMEA region. As one of our C# developers, you’ll get to know this better than anyone. After all, you’ll be at the forefront of developing new products and their functionality, using your genius for C# to shape and drive business logic, architecture and virtualization. And while you develop for us, we’ll be right by your side, developing you. Whether it’s learning additional skills, gaining a new experience or taking the next step in your career, you can be sure there’s plenty of room to grow with Veeam. '
                },
                {
                    title: 'C++ Developer',
                    body: 'Pushing forward our products is an exciting task, and one that offers stimulating challenges that make for varied and rewarding careers – especially for individuals well-versed in C++. If that sounds like you, then a role with Veeam could be exactly what you’ve always wanted. Join us, and you’ll find yourself working with native frameworks (mostly C) to write application business logic and system components. It’s an opportunity to develop and implement multithreaded and asynchronous data processing algorithms, at the same time as optimizing, enhancing and supporting existing functionality of products. While doing so, we’ll ensure you have the resources you need to optimize yourself, with learning and development that helps you to build knowledge and grow your career. '
                },
                {
                    title: 'Javascript Developer ',
                    body: 'JavaScript Developers are a crucial part of our business, enabling us to continually refine and evolve our product offering. As one of the team, you’ll discover stimulating work that tests and stretches your knowledge – something that will help you to grow your experience and keep moving upward in your career. You could be writing and debugging JavaScript code and tests. Working on application performance optimization. Or planning development for the technical part of products. Whatever it is that you’re doing, you’ll have the chance to interact with related project teams such as UI/UX, backend, or automated testing, and enjoy the unique, collaborative culture that makes Veeam so special. '
                },
                {
                    title: 'QA Engineer ',
                    body: 'Veeam products are large-scale client server systems, complex and highly appealing, which makes the task of ensuring their quality extremely important. Thankfully, that’s where the Veeam Quality Assurance (QA) department comes into the picture. By providing the highest level of quality for Veeam products, they ensure we are able to build strong and lasting relationships with our clients. As one of our QA Engineers, you’ll be on hand to manage complicated and important issues, such as studying the quality of software development and release process and understanding interactions between its participants. As you focus on software testing and quality assurance, we’ll provide the resources you need to grow as an IT professional – helping you to build solid technical, social and management skills.'
                },
            ],
        },
    ];

export {opportunityNav, opportunityContent};
